import {PatientItem} from "./Patient/PatientItem";
import {ConfigService} from "../services/ConfigService";
import {IFormSetting} from "./IFormSettings";
import {autoinject} from "aurelia-framework";
import {I18N} from "aurelia-i18n";
import {NitTools} from "./NursitTools";
import {HttpClient} from "aurelia-http-client";
import {AnalyzeService} from "../services/analyzeService";
import {IRiskFieldsSetting} from "../services/AnalyzerClass";

@autoinject
export class SpiSpiderSvg {
    private formSetting: IFormSetting;
    private riskFieldsSetting: IRiskFieldsSetting;
    private _svgImage: SVGImageElement;

    public pointsCurrent: ISvgPoint[];
    public pointsPrev: ISvgPoint[];

    public get svgImage(): SVGImageElement {
        return this._svgImage;
    }

    /***
     * Don't use this default constructor! Instead use the static .Create(i18n) function!
     * @param i18n the Translation module to use
     */
    constructor(protected i18n: I18N) {
        this.pointsCurrent = [
            {
                value: 0,
                text: this.i18n.tr("spi_web_activity"),
                friendlyName: "CSpfBewegung 01_01",
                x: 0,
                y: 0,
                field: "risk_activity"
            },
            {
                value: 0,
                text: this.i18n.tr("spi_web_eat"),
                friendlyName: "CSpfEssen 03_01",
                x: 0,
                y: 0,
                field: "risk_eat"
            },
            {
                value: 0,
                text: this.i18n.tr("spi_web_drink"),
                friendlyName: "CSpfTrinken 03_05",
                x: 0,
                y: 0,
                field: "risk_drink"
            },
            {
                value: 0,
                text: this.i18n.tr("spi_web_urine"),
                friendlyName: "CSpfUrinAusscheidung 04_01",
                x: 0,
                y: 0,
                field: "risk_urine"
            },
            {
                value: 0,
                text: this.i18n.tr("spi_web_stool"),
                friendlyName: "CSpfStuhlAusscheidung 04_03",
                x: 0,
                y: 0,
                field: "risk_stool"
            },
            {
                value: 0,
                text: this.i18n.tr("spi_web_bodycare_top"),
                friendlyName: "CSpfPflegeOberkoerper 05_01",
                x: 0,
                y: 0,
                field: "risk_upper_body_care"
            },
            {
                value: 0,
                text: this.i18n.tr("spi_web_bodycare_bottom"),
                friendlyName: "CSpfPflegeUnterkoerper 05_02",
                x: 0,
                y: 0,
                field: "risk_lower_body_care"
            },
            {
                value: 0,
                text: this.i18n.tr("spi_web_dress_top"),
                friendlyName: "CSpfAnAuskleidenOberkoerper 05_03",
                x: 0,
                y: 0,
                field: "risk_upper_body_dress"
            },
            {
                value: 0,
                text: this.i18n.tr("spi_web_dress_bottom"),
                friendlyName: "CSpfAnAuskleidenUnterkoerper 05_04",
                x: 0,
                y: 0,
                field: "risk_lower_body_dress"
            },
            {
                value: 0,
                text: this.i18n.tr("spi_web_knowledge"),
                friendlyName: "CSpfKenntnisse 06_03",
                x: 0,
                y: 0,
                field: "risk_knowledge"
            }
        ];

        this.pointsPrev = NitTools.Clone(this.pointsCurrent);
    }

    //#region SVG-Functions
    /** Converts from degrees to radians. */
    radians = function (degrees) {
        return degrees * Math.PI / 180;
    };

    /** Converts from radians to degrees. */
    degrees = function (radians) {
        return radians * 180 / Math.PI;
    };

    moveTo(x: number, y: number, path: string, absolute: boolean = true): string {
        return path + " " + (absolute ? "M" : "m") + x + " " + y;
    }

    lineTo(x: number, y: number, path: string, absolute: boolean = true): string {
        return path + " " + (absolute ? "L" : "l") + " " + x + " " + y;
    }

    closePoly(path: string): string {
        return path + " z";
    }

    //#endregion

    private resetData() {
        if (this.pointsCurrent) {
            this.pointsCurrent.forEach(p => {
                p.value = 0;
            });
        }

        if (this.pointsPrev) {
            this.pointsPrev.forEach(p => {
                p.value = 0;
            });
        }
    }

    private resetView() {
        this.resetData();
        this.update();
    }

    private getRiskValueInt(identifier: string, riskAssessment: any): number {
        let result: number = NaN;
        if (riskAssessment && riskAssessment.prediction) {
            const riskCodings = riskAssessment.prediction.filter(o => o.outcome).map(o => o.outcome).filter(o => o.coding).map(o => o.coding).filter(o => o.length >= 1).map(o => o[0])
            if (riskCodings) {
                const risk = riskCodings.find(o => o.system.endsWith('/' + NitTools.ExcludeTrailingSlash((identifier))));

                if (risk && risk.code) {
                    result = parseInt(risk.code);
                }
            }
        }

        return isNaN(result) ? 0 : result;
    }

    private async reloadSVGImage(svgFile: string) {
        const httpResponseMessage = await new HttpClient().get('images/' + svgFile);
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(httpResponseMessage.response, "image/svg+xml");
        this._svgImage = <SVGImageElement>(svgDoc.firstElementChild || svgDoc.documentElement); //  innerHTML = svgDoc.body.innerHTML;

        let pp = <SVGPathElement>this._svgImage.querySelector('#path_previous');
        pp.style.display = 'none';
        pp = <SVGPathElement>this._svgImage.querySelector('#path_current');
        pp.style.display = 'none';
    }

    private checkSvgTargetPoints() {
        for (let y = 1; y <= 5; y++) {
            for (let x = 0; x <= 9; x++) {
                let pId = `p_${y}_${x}`;
                let msg = `checke punkt ${pId}`;
                let ele = document.getElementById(pId);
                msg += (ele ? " gefunden" : " nicht gefunden");
            }
        }
    }

    private setSvgText(index: number, text: string) {
        let id = `#risk_text_${index}`;
        if (!this._svgImage) return;

        let ele = <HTMLElement>this._svgImage.querySelector(id);
        if (!ele) {
            console.warn(`Element with id "${id}" not found in setSvgText()`);
            return;
        }

        let target = ele;

        let child = <HTMLElement>ele.firstChild;
        if (child && child.tagName && child.tagName.toUpperCase() === "TSPAN") {
            target = child;
        }

        target.innerHTML = text;
        target.style.fontSize = "8px";
    }

    private updateSvgFromArray(points: any[], pathId: string): boolean {
        if (!points || points.length === 0 || !pathId) return;

        let pathData = "";
        if (!this._svgImage) {
            console.warn("Noch kein SVG Image");
            return;
        }

        let id = `#${pathId}`;
        let displayPath = <SVGPathElement>this._svgImage.querySelector(id);
        if (!displayPath) {
            console.warn("DisplayPath " + id + " nicht gefunden");
            return;
        }

        for (let i = 0; i < points.length; i++) {
            let val = points[i].value;
            if (isNaN(val)) val = 0;
            else if (val > 5) val = 5;
            else if (val < 0) val = 0;

            this.setSvgText(i, points[i].text);

            let pointId = "#" + (val === 0 ? "p_0" : `p_${val}_${i}`);
            let targetPoint = this._svgImage.querySelector(pointId);
            if (!targetPoint) {
                console.warn(`targetPoint "${pointId}" not found `);
                continue;
            }

            let x = parseInt(targetPoint.getAttribute("cx"));
            let y = parseInt(targetPoint.getAttribute("cy"));

            if (i === 0) {
                pathData = this.moveTo(x, y, pathData, false);
            } else {
                pathData = this.lineTo(x, y, pathData, true);
            }
        }

        //#region update the path
        pathData = this.closePoly(pathData);
        displayPath.setAttribute("d", pathData);
        //#endregion

        return true;
    }

    public update() {
        // if ($("svg").length === 0) return;
        if (!this._svgImage) return;

        this.checkSvgTargetPoints();
        let pp = <SVGPathElement>this._svgImage.querySelector('#path_previous');
        // $(pp).fadeIn(); // .style.display = 'block';
        pp.style.display = 'block';

        this.updateSvgFromArray(this.pointsCurrent, "path_current");
        pp = <SVGPathElement>this._svgImage.querySelector('#path_current');

        pp.style.display = 'block';

        this.updateSvgFromArray(this.pointsPrev, "path_previous");
    }

    public static async Create(patient: PatientItem, i18n) {
        if (ConfigService.Debug) window["SpiSpiderSvg"] = this;
        const result = new SpiSpiderSvg(i18n);
        if (!patient || !patient.currentRisks) {
            let imgName = 'risk_chart_2_2.svg';
            if (patient && patient.flags) {
                const version = await AnalyzeService.GetAnalyzerVersion(patient);
                if (version && version.indexOf("2.3") > -1) {
                    imgName = 'risk_chart_2_3.svg';
                }
            }

            await result.reloadSVGImage(imgName);

            return result;
        }

        if (patient?.ward) await ConfigService.LoadConfigOverride(patient.ward, patient);
        result.formSetting = ConfigService.GetFormSettings('analysis');

        const version = await AnalyzeService.GetAnalyzerVersion(patient);

        if (version) {
            const analyzer = AnalyzeService.GetAnalyzer(version);
            if (analyzer) {
                result._svgImage = await analyzer.GetSPISpider(patient);
                if (result._svgImage)
                    return result;
            }
        }

        if (result.formSetting.settings && result.formSetting.settings.riskFields) {
            result.riskFieldsSetting = result.formSetting.settings.riskFields.find(o => (<IRiskFieldsSetting>o).analyzers.indexOf(version) > -1);
        } else {
            let imgName = 'risk_chart_2_2.svg';
            try {
                if (patient && patient.flags) {
                    const version = await AnalyzeService.GetAnalyzerVersion(patient);

                    if (version && version.indexOf("2.3") > -1) {
                        imgName = 'risk_chart_2_3.svg';
                    }
                }
            } catch (e) {
                console.warn(e);
            }

            console.warn(`No "riskFields" property found in setting for form with route "analysis" (=> forms(route="analysis").settings.riskFields[] ).\n SPI-Image from "${imgName}" will be used.`)
        }

        if (result.riskFieldsSetting && result.riskFieldsSetting.svgChart) {
            await result.reloadSVGImage(result.riskFieldsSetting.svgChart)
        } else {
            let imgName = 'risk_chart_2_2.svg';
            await result.reloadSVGImage(imgName);
        }

        result.resetData();
        result.resetView();

        result.pointsCurrent.forEach(p => {
            let riskValue = result.getRiskValueInt(p.field, patient.currentRisks);
            if (riskValue && !isNaN(riskValue)) {
                p.value = riskValue;
            }
        });

        if (patient.riskAssessments.length > 1) {
            const filteredRisks = patient.riskAssessments.filter(o => o.status === 'registered' && o.prediction && o.prediction.length > 1); // registered indicates, that the RA is completed and a new one is created
            let previousRiskAssessment = filteredRisks[0];  // 0 is the latest
            if (previousRiskAssessment) {
                if (previousRiskAssessment.id === patient.currentRisks.id) previousRiskAssessment = filteredRisks[1]; // just to be sure to get another riskAssessment than the current. SHOULD never happen.

                if (previousRiskAssessment) {
                    result.pointsPrev.forEach(p => {
                        let previousValue = result.getRiskValueInt(p.field, previousRiskAssessment);
                        if (previousValue) {
                            p.value = previousValue;
                        }
                    });
                }
            }
        }

        result.update();

        return result;
    }
}

export interface ISvgPoint {
    value: number;
    text: string;
    friendlyName: string;
    x: number;
    y: number;
    field: string;
}
