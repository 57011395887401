import * as Fhir from "./FhirModules/Fhir";
import {QuestionnaireResponse} from "./FhirModules/Fhir";
import {NitTools} from "./NursitTools";
import {QuestionnaireService} from "../services/QuestionnaireService";

export class DelphiTFormBuilder {
    private _assessment = undefined;

    get Assessment() {
        return this._assessment;
    }

    set Assessment(value) {
        if (value) {
            let q = QuestionnaireService.GetQuestionnaireDirect(value.questionnaire);
            Fhir.Questionnaire.EnsureStructuredResponse(q, value);
        }

        this._assessment = value;
    }

    readonly: boolean;

    private static GetKeyValueAsNumber(item: any, defaultValue: number = 0): number {

        if (typeof item === "number") {
            return item;
        }

        if (!item || !item.answer || item.answer.length === 0) {
            if (!item) console.warn("item nicht gefunden");
            return defaultValue;
        }

        let value = Fhir.QuestionnaireResponse.GetResponseItemValueInt(item);
        return value || defaultValue;

    }

    /** creates a property in the dataSourceObject and iterates through the item.item to parse children
     * @param answerItem the any to parse
     * @param dataSourceObject the object which to set the properties from answers
     * @param skipIfKeyOrValueExists gets or sets a value indicating wheter an existing key should be overwritten from latest found item
     * @param prefix the prefix to use
     */
    private static makeFlatData(answerItem: any, dataSourceObject: object, skipIfKeyOrValueExists: boolean, prefix: string) {
        if (answerItem.item) {
            // this is a parent node, so iterate through it's children to add
            for (let i = 0; i < answerItem.item.length; i++) {
                this.makeFlatData(answerItem.item[i], dataSourceObject, skipIfKeyOrValueExists, prefix);
            }

            return;
        }

        let val = this.GetKeyValueAsNumber(answerItem);
        if (val && !isNaN(val)) {
            if (prefix + dataSourceObject[answerItem.linkId] && skipIfKeyOrValueExists) return;
            dataSourceObject[prefix + answerItem.linkId] = val;
        }
    }

    /** retrieves the value of a property generated by makeFlatData, maintains the type and button-values like x_y_01  -> 01, returns always a valid value */
    public static getFlatDataValue(key: string, dataSourceObject: object): number {
        try {
            let v = dataSourceObject[key];
            let typ = typeof v;
            if (typ === "undefined") return 0;
            else if (typ === "number") return v;
            else if (typ === "string") {
                if (v.indexOf('_') > -1) {
                    let vStr = v.substr(v.lastIndexOf('_') + 1);
                    let vInt = parseInt(vStr);
                    return isNaN(vInt) ? 0 : vInt;
                }
            }
        } catch (error) {
            console.warn(error);
        }

        return 0;
    }

    /** pushes the given questionnaireResponse.item answers into a PROPERTY of the given target. parses through the childnodes too
     * @param qr the QuestionnaireResponse to parse (full)
     * @param target the object which to set the properties from answers
     * @param skipIfKeyOrValueExists gets or sets a value indicating wheter an existing key should be overwritten from latest found item
     * @param prefix the prefix to use
     */
    private static qrToFlatData(qr, target: any, skipIfKeyOrValueExists: boolean = false, prefix: string = "") {
        if ((!qr || !qr.resource) && !qr.item) {
            return;
        }

        let items = [];
        if (qr.item) {
            items = qr.item;
        } else if (qr.resource && qr.resource.item) {
            items = qr.resource.item;
        }

        items = items.filter(o => typeof o !== "undefined");

        if (items.length > 0) {
            items.forEach(item => {
                this.makeFlatData(item, target, skipIfKeyOrValueExists, prefix);
            })
        }
    }

    constructor(assessment?: any, readonly?: boolean) {
        this.Assessment = assessment;
        this.readonly = typeof readonly === "boolean" ? readonly : false;
    }

    private dobj = undefined;

    public get dataObject() {
        if (typeof this.dobj !== "undefined") return this.dobj;

        this.dobj = {};
        DelphiTFormBuilder.qrToFlatData(this.Assessment, this.dobj, false, "VAR_");
        return this.dobj;
    }

    /*private static strToIntDef(stringValue: string, defaultValue: number) {
        if (!stringValue || stringValue === "") return defaultValue;
        let i = parseInt(stringValue);
        return isNaN(i) ? defaultValue : i;
    }*/

    /*
    public SetKeyValueAsInteger(key: string, value: number) {
        let item = QuestionnaireResponse.GetResponseItemByLinkId(this._assessment, key, true);
        item.answer = [{valueInteger: value}]
    }
*/
    public SetKeyValue(key: string, value: number, isPartValue: boolean = true) {
        if (this.readonly) {
            console.warn("READONLY!");
            return;
        }

        let stringValue: string = undefined;
        if (value && value < 0) {
            value = undefined;
        }

        let sValue = typeof value === "undefined" ? 'nil' : `${value}`;
        if (sValue.length === 1) {
            sValue = '0' + sValue;
        }

        if (key.indexOf('risk_') === 0 || isPartValue === false) stringValue = isNaN(parseInt(sValue)) ? "0" : sValue;
        else stringValue = key + '_' + sValue;

        let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.Assessment, key, true);

        if ((["EBI_07"].indexOf(item.linkId) > -1) && (stringValue.endsWith("_nil"))) {
            stringValue = "0";
        }

        DelphiTFormBuilder.SetResponseItemValue(item, stringValue);
    }

    private static SetResponseItemValue(item: any, value: string[] | string | number | boolean, display?: string, system?: string) {
        if (typeof value === "number" && value === 0) {
            value = "0";
        }
        if (typeof item === "undefined") return;

        item.answer = [];
        let textArray = [];

        if (NitTools.IsArray(value)) {
            (<string[]>value).forEach((val: string) => {
                textArray.push(display || val);
                item.answer.push(
                    {
                        valueCoding: {
                            code: val,
                            display: String(display || val)
                        }
                    });
            })
        } else {
            textArray.push(display || value);
            item.answer = [{valueString: String(value)}];
            /* item.answer.push({
                valueCoding: {
                    code: String(value),
                    display: String(display||value)
                }
            }); */
        }
    }

    public GetKeyValueAsInteger(key: string) {
        let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.Assessment, key);
        return DelphiTFormBuilder.GetKeyValueAsNumber(item);
    }

    public GetKeyValue(linkId: string) {
        let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.Assessment, linkId);
        return Fhir.QuestionnaireResponse.GetResponseItemValue(item);
    }
}
