import * as Fhir from "./FhirModules/Fhir";
import {PatientItem} from "./Patient/PatientItem";
import {IQuestionnaireList, QuestionnaireService} from "resources/services/QuestionnaireService";
import {NitTools} from "resources/classes/NursitTools";
import {fhirEnums} from "./fhir-enums";
import {ConfigItem} from "../../views/admin/config/config-item";
import {ConfigService} from "../services/ConfigService";
import {FhirService} from "../services/FhirService";

export class ICDCodeItem {
    private _condition: string;

    _icd: string;
    get icd(): string {
        if (!this._icd && this.id) return this.id;

        return this._icd;
    }

    set icd(value) {
        this._icd = value;
    }

    id: string;

    public toCondition(): any {
        let system = FhirService.FhirVersion === 3 ? 'http://hl7.org/fhir/sid/icd-10' : 'http://hl7.org/fhir/sid/icd-10-gm';
        const cfg = ConfigService.GetFormSettings('analysis');
        if (cfg?.settings["icdSystemUrl"] && cfg.settings["icdSystemUrl"].indexOf('http') === 0) {
            system = cfg?.settings["icdSystemUrl"];
        } else {
            if (FhirService.FhirVersion >= 4) {
                console.warn(`Misconfigured setup.json! route "analysis", property "settings" SHOULD contain a value for "icdSystemUrl". Falling back to ${system}!`);
            }
        }

        let result: any = {
            id: NitTools.Uid(),
            resourceType: "Condition",
            clinicalStatus: "active",
            verificationStatus: "unknown",
            code: {
                coding: [{
                    system: system,
                    code: this.icd,
                    display: this.text
                }],
                text: `${this.icd} - ${this.text}`
            },
            subject: undefined
        };

        if (FhirService.FhirVersion >= 4) {
            result.clinicalStatus = {
                text: "Active",
                coding: [
                    {
                        code: "active",
                        display: "Active",
                        system: "http://hl7.org/fhir/ValueSet/condition-clinical"
                    }
                ]
            };

            result.verificationStatus = {
                text: "unconfirmed",
                coding: [
                    {
                        code: "unconfirmed",
                        display: "Unconfirmed",
                        system: "http://hl7.org/fhir/ValueSet/condition-ver-status"
                    }
                ]
            }
        }

        return result;
    }

    set condition(value: string) {
        this._condition = value;
        this.conditionsList = [];
        if (typeof this._condition === "string") {
            this._condition.split(';').forEach(c => {
                let cc = new ICDCondition(c);
                this.conditionsList.push(cc);
            })
        } else {
            if (!ConfigService.IsTest)
                console.warn('Invalid Condition Type "' + (typeof this._condition) + '" for condition given', this);
        }
    }

    get condition(): string {
        return this._condition;
    }

    text: string;
    invalidReason: string;

    conditionsList: ICDCondition[] = undefined;

    get isValid() {
        this.invalidReason = "";
        if (!this.text) this.invalidReason += "No Text\n";
        if (!this.condition) this.invalidReason += "No Condition\n";
        if (!this.icd && !this.id) this.invalidReason += "No ID or ICD-Code";
        this.invalidReason = this.invalidReason.trim();

        const result = this.invalidReason === "";
        if (!result && ConfigService.Debug && !ConfigService.IsTest) {
            console.warn( `Invalid ICD-Item specified! Reasons:\n${this.invalidReason}`, this);
        }

        return result;
    }

    test() {
        let results: boolean[] = [];

        if (!this.isValid) {
            if (ConfigService.Debug && !ConfigService.IsTest) {
                console.warn("Invalid ICD Item", this);
            }

            return false;
        }

        for (let i = 0; i < this.conditionsList.length; i++) {
            let source: any = undefined;
            if ((/\.*ANAMNES\.*/i).test(this.conditionsList[i].source)) {
                source = this.anamnesis;
            } else if ((/\.*ASSESSMENT\.*/i).test(this.conditionsList[i].source)) {
                source = this.assessment;
            } else if ((/\.*ISOLATION\.*/i).test(this.conditionsList[i].source)) {
                source = this.isolation;
            }
            
            if (!source && this.conditionsList[i].source) {
                try {
                    let questionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(this.conditionsList[i].source);
                    if (questionnaire) {
                        let questionnaireId = questionnaire.id;
                        if (questionnaireId) {
                            source = QuestionnaireService.GetLatestResponseOfType(this.patient, questionnaireId, [fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.amended]);
                        }
                    }
                }
                catch (e) {
                    console.warn(e.message || e);
                }
            }

            if (source && this.conditionsList[i].field) {
                let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(source, this.conditionsList[i].field, false);
                if (item) {
                    const itmValue = Fhir.QuestionnaireResponse.GetResponseItemValue(item, '');

                    const result = this.conditionsList[i].test(itmValue);

                    results.push(result);
                }
            }
        }

        // nothing matched, so the condition does not match
        if (results.length === 0) return false;

        if (typeof results.find(o => o === false) !== "undefined" && typeof results.find(o => o === true) !== "undefined") {
            // so we are in doubt, because there is an item with false, and an item with true
            for (let i = 0; i < results.length; i++) {
                // there is an OR, and we have true and false, so return true||false => true
                if (this.conditionsList[i].isOr)
                    return true;
            }

            // so there is no item with isOr, true && false => false
            return false;
        }

        // either there is true + false => false or false => false or true => true or true + true => true, which can be found out by simply seeking for a false value
        const result = typeof results.find(o => o === false) === "undefined";

        return result;
    }

    anamnesis?: any;
    assessment?: any;
    isolation?: any;

    patient: PatientItem;

    constructor(data: any, patient: PatientItem, protected qList: IQuestionnaireList) {
        this.patient = patient;

        this.assessment = this.patient.latestAssessment || QuestionnaireService.GetLatestResponseOfType(patient, this.qList.QAssessmentId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
        this.anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, this.qList.QAnamnesisId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
        this.isolation = QuestionnaireService.GetLatestResponseOfType(patient, this.qList.QIsolationId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed])

        Object.assign(this, data);
    }
}

export class ICDCondition {
    condition: string;
    static reOr = new RegExp(/^\ *?(ODER|OR)\ /i);
    static reAnd = new RegExp(/^\ *?(UND|AND)\ /i);

    get isOr() {
        return ICDCondition.reOr.test(this.condition);
    }

    get isAnd() {
        return ICDCondition.reAnd.test(this.condition);
    }

    values: any[];
    field: string;
    operator: string;

    test(value: string): boolean {
        let result = false;
        for (let i = 0; i < this.values.length; i++) {
            if (this.operator === "===") {
                if (String(value).toUpperCase() === String(this.values[i]).toUpperCase()) {
                    result = true;
                }
            } else if (this.operator === "!==") {
                result = true;

                // if this was party invalid and has been appended with "!= _undefined_";
                if (this.values[i] === "_undefined_") {
                    if (typeof value !== "undefined" && String(value).trim() !== "") {
                        return true;
                    } else {
                        return false;
                    }
                }

                if (String(value).toUpperCase() === String(this.values[i]).toUpperCase()) {
                    // when the item equals the value, the condition is not met
                    result = false;
                }
            } else if (this.operator === ">") {
                let val = parseFloat(value);
                if (val > this.values[i]) {
                    result = true;
                }
            } else if (this.operator === "<") {
                let val = parseFloat(value);
                if (val < this.values[i]) {
                    result = true;
                }
            } else if (this.operator === "<=") {
                let val = parseFloat(value);
                if (val <= this.values[i]) {
                    result = true;
                }
            } else if (this.operator === ">=") {
                let val = parseFloat(value);
                if (val >= this.values[i]) {
                    result = true;
                }
            }
        }

        return result;
    }

    source?: string;

    constructor(conditionString: string) {
        if (typeof conditionString !== "string") {
            console.warn("Not a valid Condition String:", conditionString);
            return;
        }

        this.condition = conditionString.trim();

        if (this.condition) {
            let src_field = "";
            if (this.isAnd) {
                src_field = this.condition.replace(ICDCondition.reAnd, "");
            } else if (this.isOr) {
                src_field = this.condition.replace(ICDCondition.reOr, "");
            } else {
                src_field = this.condition;
            }

            let splitter = undefined;
            if (src_field) {
                // get the operator and the splitter
                if (src_field.indexOf('<>') > -1) {
                    splitter = "<>";
                    this.operator = '!==';
                } else if (src_field.indexOf('!=') > -1) {
                    splitter = "!=";
                    this.operator = "!==";
                } else if (src_field.indexOf('<=') > -1) splitter = this.operator = '<=';
                else if (src_field.indexOf('>=') > -1) splitter = this.operator = '>=';
                else if (src_field.indexOf('>') > -1) splitter = this.operator = '>';
                else if (src_field.indexOf('<') > -1) splitter = this.operator = '<';
                else if (src_field.indexOf('=') > -1) {
                    splitter = "=";
                    this.operator = '===';
                }


                if (!splitter) {
                    src_field += "!= ['_undefined_']";
                    this.operator = "!==";
                    splitter = "!=";
                }

                // should lead to sourceItem=sa[0], value(s)=sa[1]
                let sa = src_field.split(splitter);

                // extract the key, aka field
                let key = sa[0].trim();
                if (key.indexOf('.') > -1) {
                    let sa2 = key.split('.');
                    this.source = sa2[0];
                    this.field = sa2[1];
                } else {
                    this.source = key;
                    this.field = undefined;
                }

                // extract the value
                let value = sa[1].trim();
                if (value.indexOf('[') === 0 && value.endsWith(']')) {
                    value = value.substring(1, value.length - 1);
                }

                let stringValueArray: string[] = [];
                if (value.indexOf(',') > -1) {
                    this.values = [];
                    // do it this way, because there still could be leading/trailing spaces left
                    value.split(',').forEach(s => stringValueArray.push(s.trim()));
                } else {
                    stringValueArray.push(value);
                }

                // now do some magic on the values, to get the correct type for comparing
                this.values = [];
                stringValueArray.forEach(value => {
                    value = value.trim();
                    if (value.indexOf("'") === 0 || value.indexOf("\"") === 0) {
                        this.values.push(value.substring(1, value.length - 1));
                    } else {
                        if (value.toUpperCase() === "TRUE" || value.toUpperCase() === "FALSE") {
                            this.values.push(value.toUpperCase() === "TRUE");
                        } else {
                            this.values.push(parseFloat(value));
                        }
                    }
                });

                // if (ConfigService.Debug) console.debug("ICD Condition Key:" + key + " value:" + value, this);
            }
        }
    }
}
