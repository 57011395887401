export class translations {
    public static i18: any = undefined;
    public static language: string = undefined;

    public static translate(stringId: string, returnStringIdIfEmpty: boolean = true): string {
        if (!stringId) return '';
        if (translations.i18) {
            let tr = translations.i18.translator.translate(stringId);

            if (!tr) tr = translations.i18.translator.translate(stringId.toLocaleLowerCase());

            if (!tr) tr = translations.i18.translator.translate(stringId.toLowerCase());

            return tr || (returnStringIdIfEmpty ? stringId : '');
        } else {
            return returnStringIdIfEmpty ? stringId : '';
        }
    }

    public static translateTransparent(stringId: string) {
        try {
            return this.translate(stringId) || stringId;
        } catch (e) {
            console.warn("error in translateTransparent: " + e.message);
            return stringId;
        }
    }
}
