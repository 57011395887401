import {
    ValidationRenderer,
    RenderInstruction,
    ValidateResult
} from 'aurelia-validation';

export class BootstrapFormRenderer {
    render(instruction: RenderInstruction) {
        for (let {result, elements} of instruction.unrender) {
            for (let element of elements) {
                this.remove(element, result);
            }
        }

        for (let {result, elements} of instruction.render) {
            for (let element of elements) {
                this.add(element, result);
            }
        }
    }

    add(element: Element, result: ValidateResult) {
        if (result.valid) {
            return;
        }

        const formGroup = element.closest('.form-group');
        if (formGroup) {

            // add the has-error class to the enclosing form-group div
            formGroup.classList.add('has-error');

            // add help-block
            const message = document.createElement('span');
            message.className = 'help-block validation-message';
            message.textContent = result.message;
            message.id = `validation-message-${result.id}`;
            formGroup.appendChild(message);
        }

        const groupLabel: HTMLLabelElement = element.closest('label.control-label');
        if (groupLabel) {
            groupLabel.classList.add('has-error');
        }
    }

    remove(element: Element, result: ValidateResult) {
        /* if (result.valid) {
            return;
        } */

        const groupLabel: HTMLLabelElement = element.closest('label.control-label');
        if (groupLabel) {
            groupLabel.classList.remove('has-error');
        }

        const formGroup = element.closest('.form-group');
        if (!formGroup) return;

        // remove help-block
        const message = formGroup.querySelector(`#validation-message-${result.id}`);
        if (message) {
            formGroup.removeChild(message);

            // remove the has-error class from the enclosing form-group div
            if (formGroup.querySelectorAll('.help-block.validation-message').length === 0) {
                formGroup.classList.remove('has-error');
            }
        }
    }
}
