export const LOINC = {
    SYSTEM: 'http://loinc.org',
    CODES: {
        BODY_TEMPERATURE: {
            code: '8310-5',
            display: 'Body Temperature'
        },
        BLOOD_PRESSURE_SYSTOLIC: {
            code: '8480-6',
            display: 'Systolic Blood pressure'
        },
        BLOOD_PRESSURE_DIASTOLIC: {
            code: '8462-4',
            display: 'Diastolic Blood pressure'
        },
        HEART_RATE: {
            code: '8867-4',
            display: 'Heart rate'
        },
        OXYGEN_SATURATION: {
            code: '2708-6',
            display: 'Oxygen saturation in Arterial blood'
        },
        REPORT: {
            code: '67856-5',
            display: 'Nursing facility History and physical note'
        },
        BURN_FIRST_DEGREE: {
            code: '88097-1',
            display: 'First degree burn area/Body surface area'
        },
        BURN_SECOND_DEGREE: {
            code: '88098-9',
            display: 'Second degree burn area/Body surface area'
        },
        BURN_THIRD_DEGREE: {
            code: '88099-7',
            display: 'Third degree burn area/Body surface area'
        },
        BURN_FOURTH_DEGREE: {
            code: '88100-3',
            display: 'Fourth degree burn area/Body surface area'
        },
        RESPIRATORY_RATE: {
            "code": "9279-1",
            "display": "Respiratory rate"
        }
    }
};
