function createFeatures<T extends ReadonlyArray<string>>(keys: T) {
  const result = {} as { [K in T[number]]: K };
  keys.forEach((key) => {
    result[key as T[number]] = key as T[number];
  });
  return result;
}

export const FEATURES = createFeatures([
  'PLANNING_PROCEDURE_ADD',
] as const);