import {translations} from "./translations";
import {PatientItem} from "./Patient/PatientItem";
import * as Fhir from "./FhirModules/Fhir";
import * as environment from "../../../config/environment.json";
import {IQuestionnaireList, QuestionnaireService} from "resources/services/QuestionnaireService";
import {fhirEnums} from "./fhir-enums";
import {ConfigService} from "../services/ConfigService";

export class TModelRiskNRS {
    private readonly __patient: PatientItem;
    private get patient(): PatientItem {
        return this.__patient;
    }

    private get qList(): IQuestionnaireList {
        return QuestionnaireService.__listResult;
    }

    fAssessmentList: any[] = [];
    qrAnamnesis: any = undefined;
    riskCheckOutcome: number = 0;
    riskOutCome: number = 0;
    resultMessage: string = "";

    constructor(patient: PatientItem, qList: IQuestionnaireList) {
        this.__patient = patient;

        if (!patient) return;

        this.fAssessmentList = QuestionnaireService.GetResponsesOfType(this.patient, this.qList.QAssessmentId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
        this.qrAnamnesis = QuestionnaireService.GetLatestResponseOfType(this.patient, this.qList.QAnamnesisId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
    }

    warnResult(idx: number, value: number) {
        if (ConfigService.Debug && !environment.testing) {
            this.resultMessage = `[Debug] NRS Checksubprocess${idx} failed (result: ${value})`;
        } else {
            this.resultMessage = translations.translate("not_available");
        }

        console.warn(`[TModelRiskNRS] - index:${idx} : ${this.resultMessage}`);
    }

    public check(): boolean {
        let sub1 = this.checkSubprocess_1();
        let sub2 = this.checkSubprocess_2();
        let sub3 = this.checkSubprocess_3();
        let sub4 = this.checkSubprocess_4();
        let sub5 = this.checkSubprocess_5();

        if (sub1 <= -1) {
            this.warnResult(1, sub1);
            return false;
        }

        if (sub2 <= -1) {
            this.warnResult(2, sub2);
            return false;
        }

        if (sub3 <= -1) {
            this.warnResult(3, sub3);
            return false;
        }

        if (sub4 <= -1) {
            this.warnResult(4, sub4);
            return false;
        }

        if (sub5 <= -1) {
            this.warnResult(5, sub5);
            return false;
        }

        this.riskOutCome = sub1 + sub2 + sub3 + sub4 + sub5;
        if (this.riskOutCome >= 3) {
            this.riskCheckOutcome = 1;
            this.resultMessage = translations.translate("nrs_risk_yes");
        } else {
            this.riskCheckOutcome = 0;
            this.resultMessage = translations.translate("nrs_risk_unlikely");
        }

        return true;
    }

    isRisk: boolean = false;

    checkSubprocess_1(): number {
        try {
            if (!this.qrAnamnesis || this.fAssessmentList.length === 0) return -1;

            let value1: number = 0;
            let value2: number = 0;
            let LMSum: number = 0;
            let j = 0;
            let lTempValue: number = 0;
            let lTempItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.qrAnamnesis, "00_17", false);
            if (lTempItem) {
                lTempValue = Fhir.QuestionnaireResponse.GetResponseItemValueInt(lTempItem);
            }

            // enumerate from newest to oldest  (fAssessmentList is from oldest to newest)
            for (let i = this.fAssessmentList.length; i >= 0; i--) {
                let LCurrentData = this.fAssessmentList[i];
                let valueItem1 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(LCurrentData, "03_02", false);
                let valueItem2 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(LCurrentData, "03_13", false);
                value1 = valueItem1 ? Fhir.QuestionnaireResponse.GetResponseItemValueInt(valueItem1) : 0;
                value2 = valueItem2 ? Fhir.QuestionnaireResponse.GetResponseItemValueInt(valueItem2) : 0;
                LMSum = value1 + value2;
                if (LMSum > 4) LMSum = 4;
                lTempValue += LMSum;
                j++;

                if (j >= 3) break;
            }

            if (this.fAssessmentList.length > 3) {
                lTempValue = lTempValue / (3 /* assessments */ + 1 /* admission */);
            } else {
                lTempValue = lTempValue / (this.fAssessmentList.length + 1); // < 3 assessments + 1 admission
            }

            let result = 0;

            if (lTempValue <= 1.5) {
                result = 3;
            } else if (lTempValue >= 1.6 && lTempValue <= 2.4) {
                result = 2;
            } else if (lTempValue >= 2.5 && lTempValue <= 3.4) {
                result = 1;
            }

            return result;
        } catch (e) {
            console.warn(e);
            return -2;
        }
    }

    checkSubprocess_2(): number {
        let lbmi: number = -1;

        try {
            let bmiItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.qrAnamnesis, "00_05", false);
            if (bmiItem) {
                lbmi = Fhir.QuestionnaireResponse.GetKeyValueAsNumber(bmiItem);
                if (isNaN(lbmi)) {
                    lbmi = -1;
                }
            }

            if (lbmi === -1) return 0;

            if (lbmi >= 32) return 0;
            if (lbmi < 18.5) return 3;
            if (lbmi > 18.5 && lbmi <= 20.5) return 2;

            return 0;
        } catch (e) {
            console.warn(e);
            return -2;
        }
    }

    checkSubprocess_3(): number {
        try {
            let resultItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.qrAnamnesis, "00_04", false);
            if (resultItem) {
                return Fhir.QuestionnaireResponse.GetResponseItemValueInt(resultItem, -1);
            } else {
                return -1;
            }
        } catch (e) {
            console.warn(e);
            return -2;
        }
    }

    checkSubprocess_4(): number {
        try {
            let LCurrentData = QuestionnaireService.GetLatestResponseOfType(this.patient, this.qList.QAssessmentId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
            if (!LCurrentData) return -1;

            let lItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(LCurrentData, "03_12", false);
            if (!lItem) return -1;

            let value = Fhir.QuestionnaireResponse.GetResponseItemValueInt(lItem, -1);
            return 4 - value;
        } catch (e) {
            console.warn(e);
            return -2;
        }
    }

    checkSubprocess_5(): number {
        try {
            return this.patient.years >= 70 ? 1 : 0;
        } catch (e) {
            return -2;
        }
    }
}
