import {ReasonList} from "./reasonlistClass";
import {ReasonCondition} from "./reasonCondition";

export class CarePlanConfigData {
    reasonsList: Reason[] = [];

    constructor() {
        this.reasonsList = ReasonList.create();
    }
}

export class Reason {
    public groupName: string;
    public reasonName: string;
    public reasonDescription: string;
    public interventionList: string[] = [];
    public conditionList: ReasonCondition[] = [];

    constructor(aGroupName: string, aReasonName: string, aReasonDescription: string, aInterventionList?: string[]) // , aConditionList : ReasonCondition[]) {
    {
        this.groupName = aGroupName;
        this.reasonName = aReasonName;
        this.reasonDescription = aReasonDescription;
        this.interventionList = aInterventionList || [];
        this.conditionList = [];
    }
}
