export class ReasonCondition {
    values: string;
    operatorType: string;

    constructor(aValues: string, aOperatorType: string) {
        this.values = aValues;
        this.operatorType = aOperatorType;

        if (this.operatorType === "oder") {
            this.operatorType = "or";
        } else if (this.operatorType === "und") {
            this.operatorType = "and";
        }
    }
}
