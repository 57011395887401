import {NitTools} from "./NursitTools";
import {Reason} from "./reasonList";
import {ICDCondition} from "./IICDCode";
import {ConfigService} from "../services/ConfigService";

export namespace PKMS {
    export class PKMSReasonGroup {
        id: string;
        text: string;
        profiles: IPKMSReason[];
        items: PlanungReason[];
        hasSelectedReasons?: boolean;
        hasSelectedReasonsByAnalyze?: boolean;
        isValid: boolean;

        validate() {
            if (ConfigService.Debug)
                    console.debug("Validiere: ", this.profiles);
        }

        public static ToFhirItem(group: PKMSReasonGroup): any {
            let groupItem: any = {
                linkId: group.id,
                item: [],
                text: `${group.id} - ${group.text}`
            };

            group.profiles.filter(o => o.selected).forEach(profile => {
                let profileItem: any = {
                    text: profile.shortText,
                    linkId: profile.reasonCode
                };

                if (profile.reasonDetails) {
                    let details = profile.details; // .filter(o=>o.selected);
                    if (details.length > 0) {
                        profileItem.item = [];

                        details.forEach((detail: PKMSReasonDetail) => {
                            let detailItem: any = {
                                linkId: detail.id,
                                text: detail.text,
                                answer: [
                                    {valueBoolean: detail.selected}
                                ]
                            };

                            profileItem.item.push(detailItem);
                        })
                    }
                }

                groupItem.item.push(profileItem);
            });

            return groupItem;
        }

        constructor(data?: any) {
            this.items = [];
            this.profiles = [];

            if (data) {
                Object.assign(this, data);
            }

            if (this.profiles) {
                this.profiles.forEach(profile => profile.originalShortText = profile.shortText);
            }
        }
    }

    export interface IPKMSReason {
        originalShortText?: string;
        shortText?: string;
        reasonCode: string;
        selected: boolean;
        valid: boolean;
        interventionProfiles: string[];
        conditions?: any[];
        reasonDetails?: any[];
        details?: PKMSReasonDetail[];
        hasSelectedProfiles?: boolean;
        isSelectedByAnalyze?: boolean;
        interventionProfilesSelected?: boolean;
        id?: string;
        hint?: string;
        cssClass?: string;
    }

    export class PKMSReasonDetail {
        id: string;
        text: string;
        selected: boolean;
        reasonCode: string;
        conditions: ICDCondition[] = undefined;

        constructor(data?) {
            if (data) Object.assign(this, data);
            this.conditions = [];
            if (typeof data.condition === "string") {
                String(data.condition).split(';').forEach(s => {
                    s = String(s).trim();
                    if (s) this.conditions.push(new ICDCondition(s))
                });
            }
        }
    }

    export interface IPKMSIntervention {
        profileId: string;
        group: string;
        code: string;
        isChild: boolean;
        text: string;
        type: string;
        occurence: string;
        duration: string;
        required: boolean;
        info: string;
        checked?: boolean | string;
        checkboxId?: string;
        g1: string;
        g2: string;
        g3: string;
        kind?: string;
        documentation_planned?: boolean;
        documentation?: string;

        begin?: string | Date;
        end?: string | Date;

        inputs?: IInterventionInput[];
        items?: IPKMSIntervention[];
        parentCode?: string;
        invalidBecauseNoChildSelected?: boolean;
    }

    export interface IInterventionInput {
        id: string;
        type: string;
        label?: string;
        value?: string;
    }

    export interface IMappingAssessmentDiagnose {
        groupTitle: string;
        assessmentId: string;
        assessmentValueId: string;
        assessmentText: string;
        diagnoseID: string;
        diagnoseSelectionText: string;
        diagnoseText: string;
        diagnoseLinkId?: string;
        taskIds: string[];
        hasSelectedTasks?: boolean;
    }

    export interface _IPKMSProfile {
        id: string;
        selected: boolean;
        interventions?: IPKMSIntervention[];
        valid?: boolean;
        invalidReason?: string;
        invalidReasons?: string[];
    }

    export class PKMSProfile implements _IPKMSProfile {
        id: string;
        selected: boolean;
        interventions?: IPKMSIntervention[];
        valid?: boolean;

        invalidReasons?: string[] = [];
        invalidReason?: string = undefined;

        validate() {
            this.invalidReasons = [];

            let groups = [];
            if (!this.interventions || !this.selected) {
                this.valid = true;
                return;
            }

            this.interventions.filter(o => o.type !== "DIV" && o.type !== "Doku" && o.type !== "Pflicht").forEach(intervention => {
                let group = groups.find(o => o.id === intervention.group);
                if (!group) {
                    groups.push({id: intervention.group, items: [intervention], valid: false});
                } else {
                    group.items.push(intervention);
                }
            });

            if (groups.length === 0) {
                this.valid = true;
                // return;
            }

            groups.forEach(group => {
                let checkedItem = group.items.find(o => o.checked);
                if (checkedItem) {
                    group.valid = true;
                }
            });

            let invalidGroups = groups.find(o => o.valid === false);

            this.valid = typeof invalidGroups === "undefined";
            if (!this.valid) this.invalidReasons.push("Invalid groups found");

            // validate whether there is an input
            if (this.valid) {
                let checkedInterventions = this.interventions.filter(o => o.checked);
                if (checkedInterventions.length > 0) {
                    let checkedWithInput = checkedInterventions.filter(o => o.inputs && o.inputs.length > 0);
                    if (checkedWithInput.length > 0) {
                        for (let intI = 0; intI < checkedWithInput.length; intI++) {
                            let intervention = checkedWithInput[intI];
                            for (let inp = 0; inp < intervention.inputs.length; inp++) {
                                let input = intervention.inputs[inp];
                                if (!input.value) {
                                    this.valid = false;
                                    this.invalidReasons.push("Invalid Input found");
                                }
                            }
                        }
                    }
                }
            }

            // reset the indicator of all interventions thath no child is selected-error
            this.interventions.filter(intervention => intervention.items).forEach(intervention => intervention.invalidBecauseNoChildSelected = false);

            // if there are interventions with child-elements..
            this.interventions.filter(intervention => intervention.checked && intervention.items).forEach(intervention => {
                // .. check if any child item is checked ..
                if (intervention.items.filter(o => o.checked).length === 0) {
                    // .. if none is selected, this should be invalid
                    this.valid = false;
                    this.invalidReasons.push("No Child selected");
                    intervention.invalidBecauseNoChildSelected = true;
                }
            });

            this.invalidReason = this.invalidReasons.join(',\n');

            if (this.invalidReason) {
                console.warn(this.invalidReason, this);
            }

            return this;
        }

        constructor(data?: any) {
            this.selected = false;
            if (data) {
                Object.assign(this, data);
            }

            if (!this.id) {
                this.id = NitTools.UidName();
            }

            this.validate();
        }
    }


    export class PlanungReason {
        group: string;
        interventions: string[];
        reason: string;
        id: string;
        reasonNames: string[];

        constructor() {
            this.id = NitTools.UidName();
            this.reasonNames = [];
        }

        public static fromReason(r: Reason): PlanungReason {
            let result = new PlanungReason();

            result.group = r.groupName.split(':')[0].toUpperCase();
            result.reason = r.reasonName.split('.')[0];
            result.interventions = r.interventionList;

            result.reasonNames.push(r.reasonName);
            return result;
        }
    }

    export interface IMappingDisplay {
        internalId: string;
        title: string;
        items: IMappingAssessmentDiagnose[];
        hasSelectedItems?: boolean;
    }
}
