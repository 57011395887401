import {PatientService} from "../../services/PatientService";
import {AnalyzeService} from "../../services/analyzeService";
import {fhirEnums} from "../fhir-enums";
import {NitTools} from "../NursitTools";
import {LocationService} from "../../services/LocationService";
import {inject} from "aurelia-framework";
import {FhirService} from "../../services/FhirService";
import * as Fhir from "./Fhir";
import SystemHeaders from "../SystemHeaders";
import {PatientItem} from "../Patient/PatientItem";

export module FhirModules {
    import QuestionnaireResponse = Fhir.QuestionnaireResponse;
    import ResourceType = fhirEnums.ResourceType;

    @inject(FhirService, PatientService, LocationService)
    export class FhirCompatibilityService {
        fhirService: FhirService;
        patientService: PatientService;
        locationService: LocationService;

        constructor(fhirservice, patientservice, locationService) {
            this.fhirService = fhirservice;
            this.patientService = patientservice;
            this.locationService = locationService;
        }

        private _abortCalc: boolean = false;
        private _calcRunning: boolean = false;

        public abortRecalcAllEncounters() {
            if (this._calcRunning) this._abortCalc = true;
        }

        public async recalculateAllEncounters(wardId: string, analyseService: AnalyzeService, includeFinished: boolean, progressChanged?: Function) {
            //ToDo: Re-Enable recalculateAllEncounters in admin tools
            /*let url = `Encounter?location=${wardId}&_summary=true`;
            if (!includeFinished) url += "&status:not=finished";
            if (typeof progressChanged === "undefined") {
                progressChanged = console.log;
            }

            let status = {
                totalEncounters: 0,
                currentEncounter: 0,
                patientName: "",
                encounterTotalAssessments: 0,
                encounterCurrentAssessment: 0,
                status: "Fetching Encounters"
            };

            let result: any[] = <any[]>await this.fhirService.fetch(url, true);
            if (!includeFinished) {
                result = result.filter(o => o.status !== "finished");
            }

            let qList = await QuestionnaireService.GetQuestionnaireIds();
            let e = 0;
            this._calcRunning = true;

            for (const encounter of result) {
                if (this._abortCalc) {
                    status.status = "Aborting...";
                    progressChanged(status);
                    this._abortCalc = false;
                    this._calcRunning = false;
                    return;
                }

                e++;
                try {
                    let anamnese : any = undefined;
                    let patient = await this.patientService.load(encounter.id);
                    patient = this.patientService.validate(patient);

                    let allAssessments = patient.questionnaireResponses ? patient.questionnaireResponses.filter(o => o && o.questionnaire && o.questionnaire.reference.endsWith(qList.QAssessmentId)) : [];
                    if (allAssessments.length === 0) continue;
                    let latestAnamnesis = QuestionnaireService.GetLatestResponseOfType(patient, qList.QAnamnesisId, [fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.amended]);
                    let additionalInfoBackup = NitTools.Clone(patient.selectedAdditionalInfo);

                    let i = 0;
                    // do the analyse
                    for (const latestAssessment of allAssessments) {
                        i++;
                        status = {
                            totalEncounters: result.length,
                            currentEncounter: e,
                            patientName: patient.display,
                            encounterTotalAssessments: allAssessments.length,
                            encounterCurrentAssessment: i,
                            status: "Calculating"
                        };

                        if (this._abortCalc) {
                            this._abortCalc = false;
                            this._calcRunning = false;
                            status.status = "Aborting...";
                            progressChanged(status);
                            return;
                        }

                        if (typeof progressChanged === "function") {
                            progressChanged(status);
                        }
                        if (ConfigService.Debug) console.debug("[Recalcing Wards] : Analyzing Assessment " + i + " / " + allAssessments.length);

                        // analyse if anamnesis and assessment present
                        if (latestAnamnesis && latestAssessment) {
                            anamnese = latestAnamnesis;
                            let assessmentBackup = JSON.stringify( NitTools.Clone(latestAssessment.item) );

                            if (ConfigService.Debug) console.debug(`Starting Analyse for Encounter "${patient.display}": `, patient);
                            let analyzeResult = await analyseService.analyse(patient, latestAssessment, false);
                            if (ConfigService.Debug) console.debug(`Finished Analyzing Encounter "${patient.display}" resulted in: `, analyzeResult);

                            if (JSON.stringify(latestAssessment.item) !== assessmentBackup)
                                //(QuestionnaireResponse.ResponseValuesDiffer(assessmentBackup, latestAssessment)
                            {
                                status.status = "Updating Assessment";
                                if (typeof progressChanged === "function") {
                                    progressChanged(status);
                                }

                                if (ConfigService.Debug) console.debug("[Recalcing Wards] : Updating Assessment " + latestAssessment.id);
                                await Fhir.Rest.Update(latestAssessment);
                            }
                        }
                    }

                    // update if result differs
                    if (QuestionnaireResponse.ResponseValuesDiffer(additionalInfoBackup, patient.selectedAdditionalInfo)) {
                        status.status = "Updating additional Info";
                        if (typeof progressChanged === "function") {
                            progressChanged(status);
                        }
                        console.debug("[Recalcing Wards] : Additional Info needs to be updated " + patient.selectedAdditionalInfo.id);

                        await Fhir.Rest.Update(patient.selectedAdditionalInfo);
                    }

                    // create RA
                    if (patient.riskAssessment) {
                        try {
                            await this.fhirService.delete(patient.riskAssessment);
                            patient.riskAssessment = undefined;
                        }
                        catch (e) {
                            console.warn(e);
                        }
                    }

                    if (patient.questionnaireResponses) {
                        status.status = "Structuring QRs";
                        try {
                            let qrUpdateBundle = [];
                            patient.questionnaireResponses.forEach(qr => {
                                if (qr.questionnaire && qr.questionnaire.reference) {
                                    let q = QuestionnaireService.GetQuestionnaireByIdDirect(qr.questionnaire.reference);
                                    if (q) {
                                        Fhir.Questionnaire.EnsureStructuredResponse(q, qr);
                                        qrUpdateBundle.push(qr);
                                    }
                                }
                            });

                            status.status = `Pushing ${qrUpdateBundle.length} structured QRs`;
                            await Fhir.Rest.Bundle(qrUpdateBundle, fhirEnums.HTTPVerb.put);
                        }
                        catch (e) {
                            console.warn(e.message||e.response||JSON.stringify(e));
                        }
                    }

                    if (ConfigService.Debug) console.debug("Analyzing Patient:", patient);
                    await analyseService.analyse(patient, patient.latestAssessment, true, true);

                    //#region create flag
                    status.status = "Creating Flags";
                    progressChanged(status);

                    if (ConfigService.Debug) console.debug("Update Flags:", patient);
                    await this.createFlags(patient);
                    //#endregion

                    status.status = "Encounter processed";
                    progressChanged(status);
                } catch (ex) {
                    console.warn(ex);
                }
            }

            this._calcRunning = false;
            this._abortCalc = false;

            status = {
                totalEncounters: 0,
                currentEncounter: 0,
                patientName: "",
                encounterTotalAssessments: 0,
                encounterCurrentAssessment: 0,
                status: ""
            };

            progressChanged(status); */
        }

        private createFlags(patient: PatientItem): Promise<void> {
            return new Promise<void>(async (resolve, reject) => {
                try {
                    await this.fhirService.deleteUrl(`Flag?encounter=${patient.encounterId}`);

                    if (!patient.selectedAdditionalInfo) {
                        resolve();
                        return;
                    }

                    //#region create new flag resource
                    let flag: any = {
                        resourceType: ResourceType.flag,
                        id: NitTools.Uid(),
                        text: {
                            status: "generated",
                            div: "<div xmlns=\"http://www.w3.org/1999/xhtml\">Patient flags</div>"
                        },
                        identifier: [
                            {
                                system: SystemHeaders.vendorBase + "marks",
                                value: "marks"
                            }
                        ],
                        encounter: {reference: "Encounter/" + patient.encounterId},
                        subject: {reference: "Patient/" + patient.id},
                        code: {
                            coding: []
                        },
                        status: "active"
                    };
                    //#endregion

                    //#region move CareLevel-Stuff
                    patient.selectedAdditionalInfo.item.filter(o => o.linkId.toUpperCase().indexOf("CARELEVEL") > -1)
                        .forEach((item: any) => {
                            flag.code.coding.push({
                                code: QuestionnaireResponse.GetResponseItemValue(item),
                                system: SystemHeaders.systemMarks + "/" + item.linkId
                            });
                        });
                    //#endregion

                    //#region move marks1-10
                    for (let i = 1; i <= 10; i++) {
                        let idChecked = `mark_${i}`;
                        let idYellow = idChecked + "_yellow";
                        let idRed = idChecked + "_red";

                        let item = QuestionnaireResponse.GetResponseItemByLinkId(patient.selectedAdditionalInfo, idChecked, true);
                        let itemYellow = QuestionnaireResponse.GetResponseItemByLinkId(patient.selectedAdditionalInfo, idYellow, true);
                        let itemRed = QuestionnaireResponse.GetResponseItemByLinkId(patient.selectedAdditionalInfo, idRed, true);

                        let valItemChecked = QuestionnaireResponse.GetResponseItemValue(item, "false");
                        let valItemYellow = QuestionnaireResponse.GetResponseItemValue(itemYellow, "false");
                        let valItemRed = QuestionnaireResponse.GetResponseItemValue(itemRed, "false");

                        flag.code.coding.push({
                            code: valItemChecked,
                            system: SystemHeaders.systemMarks + "/" + idChecked
                        });

                        flag.code.coding.push({
                            code: valItemYellow,
                            system: SystemHeaders.systemMarks + "/" + idYellow
                        });

                        flag.code.coding.push({
                            code: valItemRed,
                            system: SystemHeaders.systemMarks + "/" + idRed
                        });
                    }
                    //#endregion

                    await this.fhirService.create(flag);
                    resolve();
                } catch (e) {
                    reject(e);
                }
            });
        }
    }
}
