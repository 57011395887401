export class pointArray {
    /*
     (c) 2017, Vladimir Agafonkin
     Simplify.js, a high-performance JS polyline simplification library
     mourner.github.io/pointArray-js
    */

// to suit your point format, run search/replace for '.x' and '.y';
// for 3D version, see 3d branch (configurability would draw significant performance overhead)

    static getDist(p1, p2) {
        let dx = Math.abs(p1.x - p2.x);
        let dy = Math.abs(p1.y - p2.y);
        return Math.abs(dx + dy);
    }

    static minX(points) {
        let x = points[0].x;
        points.forEach(point => {
            if (point.x < x) x = point.x;
        });

        return x;
    }

    static minY(points) {
        let y = points[0].y;
        points.forEach(point => {
            if (point.y < y) y = point.y;
        });

        return y;
    }

    static maxX(points) {
        let x = points[0].x;
        points.forEach(point => {
            if (point.x > x) x = point.x;
        });

        return x;
    }

    static maxY(points) {
        let y = points[0].y;
        points.forEach(point => {
            if (point.y > y) y = point.y;
        });

        return y;
    }

    static center(points) {
        let minX = this.minX(points);
        let minY = this.minY(points);
        let x = minX + (this.maxX(points) - minX) / 2;
        let y = minY + (this.maxY(points) - minY) / 2;

        return {x: x, y: y};
    }

    static boundingRect(points, offset: number) {
        let minX = this.minX(points) - offset;
        let minY = this.minY(points) - offset;
        let maxX = this.maxX(points) + offset;
        let maxY = this.maxY(points) + offset;

        return {left: minX, top: minY, right: maxX, bottom: maxY};
    }

// square distance between 2 points
    static getSqDist(p1, p2) {
        let dx = p1.x - p2.x, dy = p1.y - p2.y;
        return dx * dx + dy * dy;
    }

// square distance from a point to a segment
    static getSqSegDist(p, p1, p2) {
        let x = p1.x,
            y = p1.y,
            dx = p2.x - x,
            dy = p2.y - y;

        if (dx !== 0 || dy !== 0) {

            let t = ((p.x - x) * dx + (p.y - y) * dy) / (dx * dx + dy * dy);

            if (t > 1) {
                x = p2.x;
                y = p2.y;

            } else if (t > 0) {
                x += dx * t;
                y += dy * t;
            }
        }

        dx = p.x - x;
        dy = p.y - y;

        return dx * dx + dy * dy;
    }

// rest of the code doesn't care about point format

// basic distance-based simplification
    static simplifyRadialDist(points, sqTolerance) {

        let prevPoint = points[0],
            newPoints = [prevPoint],
            point;

        for (let i = 1, len = points.length; i < len; i++) {
            point = points[i];

            if (pointArray.getSqDist(point, prevPoint) > sqTolerance) {
                newPoints.push(point);
                prevPoint = point;
            }
        }

        if (prevPoint !== point) newPoints.push(point);

        return newPoints;
    }

    private static simplifyDPStep(points, first, last, sqTolerance, simplified) {
        let maxSqDist = sqTolerance,
            index;

        for (let i = first + 1; i < last; i++) {
            let sqDist = pointArray.getSqSegDist(points[i], points[first], points[last]);

            if (sqDist > maxSqDist) {
                index = i;
                maxSqDist = sqDist;
            }
        }

        if (maxSqDist > sqTolerance) {
            if (index - first > 1) this.simplifyDPStep(points, first, index, sqTolerance, simplified);
            simplified.push(points[index]);
            if (last - index > 1) this.simplifyDPStep(points, index, last, sqTolerance, simplified);
        }
    }

// simplification using Ramer-Douglas-Peucker algorithm
    static simplifyDouglasPeucker(points, sqTolerance) {
        let last = points.length - 1;

        let simplified = [points[0]];
        this.simplifyDPStep(points, 0, last, sqTolerance, simplified);
        simplified.push(points[last]);

        return simplified;
    }

    static calcPolygonArea(points): Promise<number> {
        let total = 0;

        for (let i = 0, l = points.length; i < l; i++) {
            let addX = points[i].x;
            let addY = points[i == points.length - 1 ? 0 : i + 1].y;
            let subX = points[i == points.length - 1 ? 0 : i + 1].x;
            let subY = points[i].y;

            total += (addX * addY * 0.5);
            total -= (subX * subY * 0.5);
        }

        return Promise.resolve(Math.abs(total));
    }

    static simplifyClosedArea(points, tolerance, highestQuality): Promise<any[]> {
        return new Promise<any[]>((resolve => {
            if (points.length <= 2) return resolve(points);

            let sqTolerance = tolerance !== undefined ? tolerance * tolerance : 1;

            points = highestQuality ? points : this.simplifyRadialDist(points, sqTolerance);
            points = this.simplifyDouglasPeucker(points, sqTolerance);

            points.push({x: points[0].x, y: points[0].y});

            return resolve(points);
        }))
    }

// both algorithms combined for awesome performance
    static simplify(points, tolerance, highestQuality): Promise<any[]> {
        return new Promise<any[]>((resolve => {
            if (points.length <= 2) return resolve(points);

            let sqTolerance = tolerance !== undefined ? tolerance * tolerance : 1;

            points = highestQuality ? points : this.simplifyRadialDist(points, sqTolerance);
            points = this.simplifyDouglasPeucker(points, sqTolerance);

            return resolve(points);
        }))
    }
}
