import {PatientItem} from "../Patient/PatientItem";
import {translations} from "../translations";
import {IAnswer} from "../IAnswer";
import {QuestionnaireResponse} from "./QuestionnaireResponse";
import {Tools} from "./Tools";
import {QuestionnaireService} from "../../services/QuestionnaireService";
import {Questionnaire} from "./Fhir";
import {fhirEnums} from "../fhir-enums";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import SystemHeaders from "../SystemHeaders";
import {NitTools} from "../NursitTools";
import {FhirService} from "../../services/FhirService";

export class Patient {
    public static GetDisplay(patient: PatientItem): string {
        if (!patient || !patient.name) return "";

        let n = patient.name.find(o => o.use === "official");
        if (!n) n = patient.name[0];
        let result = n.family;
        if (n.given && n.given.length > 0) {
            result += ", " + n.given?.join(' ');
        }

        result = result.trim();

        return result;
    }
}
