export namespace fhirEnums {
    export enum AddressType {
        postal = "postal",
        physical = "physical",
        both = "both"
    }

    export enum AddressUse {
        home = "home",
        work = "work",
        temp = "temp",
        old = "old"
    }

    export enum TaskListIntervall {
        days = "days",
        daily = "daily",
        whenNeeded = "whenNeeded",
        hourly = "hourly",
        minutely = "minutely",
        weekly = "weekly",
        everlasting = "everlasting",
        atAdmission = "atAdmission",
        atRelease = "atRelease",
        nTimes = "nTimes"
    }

    export enum AdministrativeGender {
        male = "male",
        female = "female",
        other = "other",
        unknown = "unknown"
    }

    export enum AdmitSource {
        /** Transferred from other hospital - The Patient has been transferred from another hospital for this encounter. */
        hospTrans = "hosp-trans",

        /** From accident/emergency department - The patient has been transferred from the emergency department within the hospital. This is typically used in the transition to an inpatient encounter */
        emd = "emd",

        /** From outpatient department    The patient has been transferred from an outpatient department within the hospital. */
        outp = "outp",

        /** Born in hospital - The patient is a newborn and the encounter will track the baby related activities (as opposed to the Mothers encounter - that may be associated using the newborn encounters partof property) */
        born = "born",

        /** General Practitioner referral - The patient has been admitted due to a referred from a General Practitioner. */
        gp = "gp",

        /** Medical Practitioner/physician referral    The patient has been admitted due to a referred from a Specialist (as opposed to a General Practitioner). */
        mp = "mp",

        /** From nursing home - The patient has been transferred from a nursing home. */
        nursing = "nursing",

        /** From psychiatric hospital - The patient has been transferred from a psychiatric facility. */
        psych = "psych",

        /** From rehabilitation facility - The patient has been transferred from a rehabilitiation facility or clinic. */
        rehab = "rehab",

        /** Other    The patient has been admitted from a source otherwise not specified here. */
        other = "other"
    }

    export enum BundleType {
        document = "document",
        message = "message",
        transaction = "transaction",
        transactionResponse = "transaction-response",
        batch = "batch",
        batchResponse = "batch-response",
        history = "history",
        searchset = "searchset",
        collection = "collection"
    }

    export enum CarePlanActivityStatus {
        /** Not Started    Activity is planned but no action has yet been taken. */
        notStarted = "not-started",

        /** Scheduled    Appointment or other booking has occurred but activity has not yet begun. */
        scheduled = "scheduled",

        /** In Progress    Activity has been started but is not yet complete. */
        inProgress = "in-progress",

        /** On Hold    Activity was started but has temporarily ceased with an expectation of resumption at a future time. */
        onHold = "on-hold",

        /** Completed    The activities have been completed (more or less) as planned. */
        completed = "completed",

        /** Cancelled    The activities have been ended prior to completion (perhaps even before they were started). */
        cancelled = "cancelled",

        /** Unknown - The authoring system doesn't know the current state of the activity. */
        unknown = "unknown"
    }

    export enum CarePlanIntent {
        /** Proposal - The care plan is a suggestion made by someone/something that doesn't have an intention to ensure it occurs and without providing an authorization to act */
        proposal = "proposal",

        /** Plan - The care plan represents an intention to ensure something occurs without providing an authorization for others to act */
        plan = "plan",

        /** Order - The care plan represents a request/demand and authorization for action */
        order = "order",

        /** Option - The care plan represents a component or option for a RequestGroup that establishes timing, conditionality and/or other constraints among a set of requests. Refer to [[[RequestGroup]]] for additional information on how this status is used */
        option = "option"
    }

    export enum CarePlanStatus {
        /** Pending - The plan is in development or awaiting use but is not yet intended to be acted upon. */
        draft = "draft",

        /** Active - The plan is intended to be followed and used as part of patient care. */
        active = "active",

        /** Suspended - The plan has been temporarily stopped but is expected to resume in the future. */
        suspended = "suspended",

        /** Completed - The plan is no longer in use and is not expected to be followed or used in patient care. */
        completed = "completed",

        /** Entered In Error - The plan was entered in error and voided. */
        enteredInError = "entered-in-error",

        /** Cancelled - The plan has been terminated prior to reaching completion (though it may have been replaced by a new plan). */
        cancelled = "cancelled",

        /** Unknown - The authoring system doesn't know the current state of the care plan. */
        unknown = "unknown"
    }

    export enum ContactPointSystem {
        phone = "phone",
        fax = "fax",
        email = "email",
        pager = "pager",
        url = "url",
        sms = "sms",
        other = "other"
    }

    export enum ContactPointUse {
        home = "home",
        work = "work",
        temp = "temp",
        old = "old",
        mobile = "mobile"
    }

    export enum DaysOfWeek {
        mon = "mon",
        tue = "tue",
        wed = "wed",
        thu = "thu",
        fri = "fri",
        sat = "sat",
        sun = "sun"
    }

    export enum DiagnosisRole {
        /** Admission diagnosis  */
        ad = "AD",
        /** Discharge diagnosis   */
        dd = "DD",
        /** Chief complaint   */
        cc = "CC",
        /** Comorbidity diagnosis   */
        cm = "CM",
        /** pre-op diagnosis */
        preOp = "pre-op",
        /** post-op diagnosis */
        postOp = "post-op",
        /** Billing */
        billing = "billing"
    }

    export enum Diet {
        /** Vegetarian - Food without meat, poultry or seafood. */
        vegetarian = "vegetarian",

        /** Dairy Free  Excludes dairy products. */
        dairyFree = "dairy-free",

        /** Nut Free - Excludes ingredients containing nuts. */
        nutFree = "nut-free",

        /** Gluten Free - Excludes ingredients containing gluten. */
        glutenFree = "gluten-free",

        /** Vegan - Food without meat, poultry, seafood, eggs, dairy products and other animal-derived substances. */
        vegan = "vegan",

        /** Halal - Foods that conform to Islamic law. */
        halal = "halal",

        /** Kosher - Foods that conform to Jewish dietary law. */
        kosher = "kosher"
    }

    export enum DigitalMediaType {
        photo = "photo",
        video = "video",
        audio = "audio"
    }

    /** the disposition of the patient when discharged */
    export enum DischargeDisposition {
        /** Home - The patient was dicharged and has indicated that they are going to return home afterwards. */
        home = "home",

        /** Other healthcare facility - The patient was transferred to another healthcare facility. */
        otherHcf = "other-hcf",

        /** Hospice - The patient has been discharged into palliative care. */
        hosp = "hosp",

        /** Long-term care - The patient has been discharged into long-term care where is likely to be monitored through an ongoing episode-of-care. */
        long = "long",

        /** Left against advice    The patient self discharged against medical advice. */
        aadvice = "aadvice",

        /** Expired    The patient has deceased during this encounter. */
        exp = "exp",

        /** Psychiatric hospital - The patient has been transferred to a psychiatric facility. */
        psy = "psy",

        /** Rehabilitation - The patient was discharged and is to receive post acute care rehabilitation services. */
        rehab = "rehab",

        /** Skilled nursing facility - The patient has been discharged to a skilled nursing facility for the patient to receive additional care. */
        snf = "snf",

        /** Other - The discharge disposition has not otherwise defined. */
        oth = "oth"
    }

    export enum EncounterLocationStatus {
        planned = "planned",
        active = "active",
        reserved = "reserved",
        completed = "completed"
    }

    export enum EncounterStatus {
        /** The Encounter has not yet started. */
        planned = "planned",
        /** Arrived    The Patient is present for the encounter, however is not currently meeting with a practitioner. */
        arrived = "arrived",
        /** Triaged    The patient has been assessed for the priority of their treatment based on the severity of their condition. */
        triaged = "triaged",
        /**    In Progress    The ncounter has begun and the patient is present / the practitioner and the patient are meeting. */
        inProgress = "in-progress",
        /** On Leave    The Encounter has begun, but the patient is temporarily on leave. */
        onLeave = "onleave",
        /** Finished    The Encounter has ended. */
        finished = "finished",
        /** Cancelled    The Encounter has ended before it has begun. */
        cancelled = "cancelled",
        /** Entered in Error    This instance should not have been part of this patient's medical record. */
        enteredInError = "entered-in-error",
        unknown = "unknown"
    }

    export enum EventStatus {
        /** Preparation - The core event has not started yet, but some staging activities have begun (e.g. surgical suite preparation). Preparation stages may be tracked for billing purposes. */
        preparation = "preparation",

        /** In Progress - The event is currently occurring */
        inProgress = "in-progress",

        /** Suspended - The event has been temporarily stopped but is expected to resume in the future */
        suspended = "suspended",

        /** Aborted - The event was prior to the full completion of the intended actions */
        aborted = "aborted",

        /** Completed - The event has now concluded */
        completed = "completed",

        /** Entered in Error - This electronic record should never have existed, though it is possible that real-world decisions were based on it.
         * (If real-world activity has occurred, the status should be "cancelled" rather than "entered-in-error".) */
        enteredInError = "entered-in-error",

        /** Unknown - The authoring system does not know which of the status values currently applies for this request. Note: This concept is not to be used for "other" - one of the listed statuses is presumed to apply, it's just not known which one. */
        unknown = "unknown"
    }

    export enum HTTPVerb {
        get = "GET",
        post = "POST",
        put = "PUT",
        delete = "DELETE"
    }

    export enum IncludeFilterOp {
        equals = "=",
        isA = "is-a",
        descendantOf = "descendent-of",
        isNotA = "is-not-a",
        regEx = "regex",
        "in" = "in",
        notIn = "not-in",
        generalizes = "generalizes",
        exists = "exists"
    }

    /** the meaning of a link */
    export enum LinkRelation {
        /** Refers to a resource containing the most recent item(s) in a collection of resources */
        current = "current",

        /** An IRI that refers to the furthest preceding resource in a series of resources */
        first = "first",

        /** An IRI that refers to the furthest following resource in a series of resources */
        last = "last",

        /** Indicates that the link's context is a part of a series, and that the next in the series is the link target */
        next = "next",

        /** Refers to the previous resource in an ordered series of resources. Synonym for "prev" */
        previous = "previous",

        /** Conveys an identifier for the link's context */
        self = "self"
    }

    export enum LinkType {
        replacedBy = "replaced-by",
        replaces = "replaces",
        refer = "refer",
        seealso = "seealso"
    }

    export enum ListCode {
        /**    Alerts    A list of alerts for the patient. */
        alerts = "alerts",

        /** Adverse Reactions    A list of part adverse reactions. */
        adverserxns = "adverserxns",

        /** Allergies    A list of Allergies for the patient. */
        allergies = "allergies",

        /** Medication List    A list of medication statements for the patient. */
        medications = "medications",

        /** Problem List    A list of problems that the patient is known of have (or have had in the past). */
        problems = "problems",

        /** Worklist    A list of items that constitute a set of work to be performed (typically this code would be specialized for more specific uses, such as a ward round list).  */
        worklist = "worklist",

        /** Waiting List    A list of items waiting for an event (perhaps a surgical patient waiting list). */
        waiting = "waiting",

        /** Protocols    A set of protocols to be followed. */
        protocols = "protocols",

        /** Care Plans    A set of care plans that apply in a particular context of care. */
        plans = "plans"
    }

    export enum ListEmptyReason {
        /** Nil Known    Clinical judgment that there are no known items for this list after reasonable investigation. Note that this a positive statement by a clinical user, and not a default position asserted by a computer system in the lack of other information. Example uses: * For allergies: the patient or patient's agent/guardian has asserted that he/she is not aware of any allergies (NKA - nil known allergies) * For medications: the patient or patient's agent/guardian has asserted that the patient is known to be taking no medications * For diagnoses, problems and procedures: the patient or patient's agent/guardian has asserted that there is no known event to record. */
        nilKnown = "nilknown",

        /** Not Asked    The investigation to find out whether there are items for this list has not occurred. */
        notAsked = "notasked",

        /** Information Withheld    The content of the list was not provided due to privacy or confidentiality concerns. Note that it should not be assumed that this means that the particular information in question was withheld due to its contents - it can also be a policy decision. */
        withHeld = "withheld",

        /** Unavailable    Information to populate this list cannot be obtained; e.g. unconscious patient. */
        unavailable = "unavailable",

        /** Not Started    The work to populate this list has not yet begun. */
        notStarted = "notstarted",

        /** Closed    This list has now closed or has ceased to be relevant or useful. */
        closed = "closed"
    }

    export enum ListMode {
        working = "working",
        snapshot = "snapshot",
        changes = "changes"
    }

    export enum ListOrderCode {
        /** Sorted by User    The list was sorted by a user. The criteria the user used are not specified. */
        user = "user",

        /** Sorted by System    The list was sorted by the system. The criteria the user used are not specified; define additional codes to specify a particular order (or use other defined codes). */
        system = "system",

        /** Sorted by Event Date    The list is sorted by the data of the event. This can be used when the list has items which are dates with past or future events. */
        eventDate = "event-date",

        /** Sorted by Item Date    The list is sorted by the date the item was added to the list. Note that the date added to the list is not explicit in the list itself. */
        entryDate = "entry-date",

        /** Sorted by Priority    The list is sorted by priority. The exact method in which priority has been determined is not specified. */
        priority = "priority",

        /** Sorted Alphabetically    The list is sorted alphabetically by an unspecified property of the items in the list. */
        alphabetic = "alphabetic",

        /** Sorted by Category    The list is sorted categorically by an unspecified property of the items in the list. */
        category = "category",

        /** Sorted by Patient    The list is sorted by patient, with items for each patient grouped together. */
        patient = "patient"
    }

    export enum ListStatus {
        code = "code",
        current = "current",
        retired = "retired",
        enteredInError = "entered-in-error"
    }

    export enum LocationMode {
        instance = "instance",
        kind = "kind"
    }

    export enum LocationStatus {
        active = "active",
        suspended = "suspended",
        inactive = "inactive"
    }

    /** Status of marriage, see http://hl7.org/fhir/v3/MaritalStatus */
    export enum MaritalStatus {
        /** Annulled    Marriage contract has been declared null and to not have existed */
        a = "A",
        /** Divorced    Marriage contract has been declared dissolved and inactive */
        d = "D",
        /** Interlocutory    Subject to an Interlocutory Decree. */
        i = "I",
        /** Legally Separated */
        l = "L",
        /** Married    A current marriage contract is active */
        m = "M",
        /** Polygamous    More than 1 current spouse */
        p = "P",
        /** Never Married    No marriage contract has ever been entered */
        s = "S",
        /** Domestic partner    Person declares that a domestic partner relationship exists. */
        t = "T",
        /** unmarried    Currently not in a marriage contract. */
        u = "U",
        /** Widowed    The spouse has died */
        w = "W",
        /** unknown    Description:A proper value is applicable, but not known. Usage Notes: This means the actual value is not known. If the only thing that is unknown is how to properly express the value in the necessary constraints (value set, datatype, etc.), then the OTH or UNC flavor should be used. No properties should be included for a datatype with this property unless: Those properties themselves directly translate to a semantic of "unknown". (E.g. a local code sent as a translation that conveys 'unknown') Those properties further qualify the nature of what is unknown. (E.g. specifying a use code of "H" and a URL prefix of "tel:" to convey that it is the home phone number that is unknown.) */
        unk = "UNK"
    }

    /** usage of a name - one of
     * + usual | official | temp | nickname | anonymous | old | maiden */
    export enum NameUse {
        usual = "usual",
        official = "official",
        temp = "temp",
        nickname = "nickname",
        anonymous = "anonymous",
        old = "old",
        maiden = "maiden"
    }

    export enum ObservationStatus {
        /** Registered    The existence of the observation is registered, but there is no result yet available. */
        registered = "registered",

        /** Preliminary    This is an initial or interim observation: data may be incomplete or unverified. */
        preliminary = "preliminary",

        /** Final    The observation is complete. */
        final = "final",

        /** Amended    Subsequent to being Final, the observation has been modified subsequent. This includes updates/new information and corrections */
        amended = "amended",

        /** Corrected    Subsequent to being Final, the observation has been modified to correct an error in the test result */
        corrected = "corrected",

        /** Cancelled    The observation is unavailable because the measurement was not started or not completed (also sometimes called "aborted"). */
        cancelled = "cancelled",

        /** Entered in Error    The observation has been withdrawn following previous final release. This electronic record should never have existed, though it is possible that real-world decisions were based on it. (If real-world activity has occurred, the status should be "cancelled" rather than "entered-in-error".) */
        enteredInError = "entered-in-error",

        /** Unknown    The authoring system does not know which of the status values currently applies for this request. Note: This concept is not to be used for "other" - one of the listed statuses is presumed to apply, but the authoring system does not know which. */
        unknown = "unknown"
    }

    export enum PublicationStatus {
        draft = "draft",
        active = "active",
        retired = "retired",
        unknown = "unknown"
    }

    export enum QuestionnaireItemType {
        /** Group    An item with no direct answer but should have at least one child item. */
        group = "group",
        /** Display    Text for display that will not capture an answer or have child items. */
        display = "display",
        /** Question    An item that defines a specific answer to be captured, and may have child items. (the answer provided in the QuestionnaireResponse should be of the defined datatype) */
        question = "question",
        /** Boolean    Question with a yes/no answer (valueBoolean) */
        boolean = "boolean",
        /** Decimal    Question with is a real number answer (valueDecimal) */
        decimal = "decimal",
        /** Integer    Question with an integer answer (valueInteger) */
        integer = "integer",
        /** Date    Question with a date answer (valueDate) */
        date = "date",
        /** Date Time    Question with a date and time answer (valueDateTime) */
        dateTime = "dateTime",
        /** Time    Question with a time (hour:minute:second) answer independent of date. (valueTime) */
        time = "time",
        /** String    Question with a short (few words to short sentence) free-text entry answer (valueString) */
        string = "string",
        /** Text    Question with a long (potentially multi-paragraph) free-text entry answer (valueString) */
        text = "text",
        /** Url    Question with a URL (website, FTP site, etc.) answer (valueUri) */
        url = "url",
        /** Choice    Question with a Coding drawn from a list of options (specified in either the option property, or via the valueset referenced in the options property) as an answer (valueCoding) */
        choice = "choice",
        /** Open Choice    Answer is a Coding drawn from a list of options (as with the choice type) or a free-text entry in a string (valueCoding or valueString) */
        openChoice = "open-choice",
        /** Attachment    Question with binary content such as a image, PDF, etc. as an answer (valueAttachment) */
        attachment = "attachment",
        /**    Reference    Question with a reference to another resource (practitioner, organization, etc.) as an answer (valueReference) */
        reference = "reference",
        /** Quantity    Question with a combination of a numeric value and unit, potentially with a comparator (<, >, etc.) as an answer. (valueQuantity) There is an extension 'http://hl7.org/fhir/StructureDefinition/questionnaire-unit' that can be used to define what unit whould be captured (or the a unit that has a ucum conversion from the provided unit) */
        quantity = "quantity"
    }

    export enum QuestionnaireResponseStatus {
        inProgress = "in-progress",
        completed = "completed",
        amended = "amended",
        enteredInError = "entered-in-error",
        stopped = "stopped"
    }

    export enum RiskAssessmentStatus {
        /** The existence of the observation is registered, but there is no result yet available. */
        registered = "registered",
        /** This is an initial or interim observation: data may be incomplete or unverified. */
        preliminary = "preliminary",
        /** The observation is complete. */
        final = "final",
        /** Subsequent to being Final, the observation has been modified subsequent. This includes updates/new information and corrections. */
        amended = "amended",
        /** Subsequent to being Final, the observation has been modified to correct an error in the test result. */
        corrected = "corrected",
        /** The observation is unavailable because the measurement was not started or not completed (also sometimes called "aborted"). */
        cancelled = "cancelled",
        /** The observation has been withdrawn following previous final release. This electronic record should never have existed, though it is possible that real-world decisions were based on it. (If real-world activity has occurred, the status should be "cancelled" rather than "entered-in-error".) */
        enteredInError = "entered-in-error",
        /** The authoring system does not know which of the status values currently applies for this request. Note: This concept is not to be used for "other" - one of the listed statuses is presumed to apply, but the authoring system does not know which. */
        unknown = "unknown"
    }

    export enum RequestIntent {
        /** Proposal - The request is a suggestion made by someone/something that doesn't have an intention to ensure it occurs and without providing an authorization to act */
        proposal = "proposal",

        /** Plan - The request represents an intension to ensure something occurs without providing an authorization for others to act */
        plan = "plan",

        /** Order - The request represents a request/demand and authorization for action */
        order = "order",

        /** Original Order - The request represents an original authorization for action */
        originalOrder = "original-order",

        /** Reflex Order - The request represents an automatically generated supplemental authorization for action based on a parent authorization together with initial results of the action taken against that parent authorization */
        reflexOrder = "reflex-order",

        /** Filler Order - The request represents the view of an authorization instantiated by a fulfilling system representing the details of the fulfiller's intention to act upon a submitted order */
        fillerOrder = "filler-order",

        /** Instance Order - An order created in fulfillment of a broader order that represents the authorization for a single activity occurrence. E.g. The administration of a single dose of a drug. */
        instanceOrder = "instance-order",

        /** Option - The request represents a component or option for a RequestGroup that establishes timing, conditionality and/or other constraints among a set of requests. Refer to [[[RequestGroup]]] for additional information on how this status is used */
        option = "option"
    }

    export enum RequestPriority {
        /** Routine - The request has normal priority */
        routine = "routine",

        /** Urgent - The request should be actioned promptly - higher priority than routine */
        urgent = "urgent",

        /** ASAP - The request should be actioned as soon as possible - higher priority than urgent */
        asap = "asap",

        /** STAT - The request should be actioned immediately - highest possible priority. E.g. an emergency */
        stat = "stat"
    }

    export enum RequestStatus {
        /** Draft - The request has been created but is not yet complete or ready for action */
        draft = "draft",

        /** Active - The request is ready to be acted upon */
        active = "active",

        /** Suspended - The authorization/request to act has been temporarily withdrawn but is expected to resume in the future */
        suspended = "suspended",

        /** Cancelled - The authorization/request to act has been terminated prior to the full completion of the intended actions. No further activity should occur. */
        cancelled = "cancelled",

        /** Completed - Activity against the request has been sufficiently completed to the satisfaction of the requester */
        completed = "completed",

        /** Entered in Error - This electronic record should never have existed, though it is possible that real-world decisions were based on it. (If real-world activity has occurred, the status should be "cancelled" rather than "entered-in-error".) */
        enteredInError = "entered-in-error",

        /** Unknown - The authoring system does not know which of the status values currently applies for this request. Note: This concept is not to be used for "other" . One of the listed statuses is presumed to apply, but the system creating the request doesn't know. */
        unknown = "unknown"
    }

    export enum ResourceType {
        account = "Account",
        activityDefinition = "ActivityDefinition",
        adverseEvent = "AdverseEvent",
        allergyIntolerance = "AllergyIntolerance",
        appointment = "Appointment",
        appointmentResponse = "AppointmentResponse",
        auditEvent = "AuditEvent",
        basic = "Basic",
        binary = "Binary",
        bodySite = "BodySite",
        bundle = "Bundle",
        capabilityStatement = "CapabilityStatement",
        carePlan = "CarePlan",
        careTeam = "CareTeam",
        chargeItem = "ChargeItem",
        claim = "Claim",
        claimResponse = "ClaimResponse",
        clinicalImpression = "ClinicalImpression",
        codeSystem = "CodeSystem",
        communication = "Communication",
        communicationRequest = "CommunicationRequest",
        compartmentDefinition = "CompartmentDefinition",
        composition = "Composition",
        conceptMap = "ConceptMap",
        condition = "Condition",
        consent = "Consent",
        contract = "Contract",
        coverage = "Coverage",
        dataElement = "DataElement",
        detectedIssue = "DetectedIssue",
        device = "Device",
        deviceComponent = "DeviceComponent",
        deviceMetric = "DeviceMetric",
        deviceRequest = "DeviceRequest",
        deviceUseStatement = "DeviceUseStatement",
        diagnosticReport = "DiagnosticReport",
        documentManifest = "DocumentManifest",
        documentReference = "DocumentReference",
        domainResource = "DomainResource",
        eligibilityRequest = "EligibilityRequest",
        eligibilityResponse = "EligibilityResponse",
        encounter = "Encounter",
        endpoint = "Endpoint",
        enrollmentRequest = "EnrollmentRequest",
        enrollmentResponse = "EnrollmentResponse",
        episodeOfCare = "EpisodeOfCare",
        expansionProfile = "ExpansionProfile",
        explanationOfBenefit = "ExplanationOfBenefit",
        familyMemberHistory = "FamilyMemberHistory",
        flag = "Flag",
        goal = "Goal",
        graphDefinition = "GraphDefinition",
        group = "Group",
        guidanceResponse = "GuidanceResponse",
        healthcareService = "HealthcareService",
        imagingManifest = "ImagingManifest",
        imagingStudy = "ImagingStudy",
        immunization = "Immunization",
        immunizationRecommendation = "ImmunizationRecommendation",
        implementationGuide = "ImplementationGuide",
        library = "Library",
        linkage = "Linkage",
        list = "List",
        location = "Location",
        measure = "Measure",
        measureReport = "MeasureReport",
        media = "Media",
        medication = "Medication",
        medicationAdministration = "MedicationAdministration",
        medicationDispense = "MedicationDispense",
        medicationRequest = "MedicationRequest",
        medicationStatement = "MedicationStatement",
        messageDefinition = "MessageDefinition",
        messageHeader = "MessageHeader",
        namingSystem = "NamingSystem",
        nutritionOrder = "NutritionOrder",
        observation = "Observation",
        operationDefinition = "OperationDefinition",
        operationOutcome = "OperationOutcome",
        organization = "Organization",
        parameters = "Parameters",
        /** Resource for the Patient */
        patient = "Patient",
        paymentNotice = "PaymentNotice",
        paymentReconciliation = "PaymentReconciliation",
        person = "Person",
        PlanDefinition = "PlanDefinition",
        practitioner = "Practitioner",
        practitionerRole = "PractitionerRole",
        procedure = "Procedure",
        procedureRequest = "ProcedureRequest",
        processRequest = "ProcessRequest",
        processResponse = "ProcessResponse",
        provenance = "Provenance",
        questionnaire = "Questionnaire",
        questionnaireResponse = "QuestionnaireResponse",
        referralRequest = "ReferralRequest",
        relatedPerson = "RelatedPerson",
        requestGroup = "RequestGroup",
        researchStudy = "ResearchStudy",
        researchSubject = "ResearchSubject",
        resource = "Resource",
        riskAssessment = "RiskAssessment",
        schedule = "Schedule",
        searchParameter = "SearchParameter",
        sequence = "Sequence",
        serviceDefinition = "ServiceDefinition",

        serviceRequest = "ServiceRequest",
        slot = "Slot",
        specimen = "Specimen",
        structureDefinition = "StructureDefinition",
        structureMap = "StructureMap",
        subscription = "Subscription",
        substance = "Substance",
        supplyDelivery = "SupplyDelivery",
        supplyRequest = "SupplyRequest",
        task = "Task",
        testReport = "TestReport",
        testScript = "TestScript",
        valueSet = "ValueSet",
        visionPrescription = "VisionPrescription"
    }

    /** Explanation why an entry is in the search result */
    export enum SearchEntryMode {

        /** Match - This resource matched the search specification */
        match = "match",

        /** Include - This resource is returned because it is referred to from another resource in the search set */
        include = "include",

        /** Outcome - An OperationOutcome that provides additional information about the processing of a search */
        outcome = "outcome"

    }

    export enum SignatureType {
        /** Author's Signature - the signature of the primary or sole author of a health information document. There can be only one primary author of a health information document. */
            "1.2.840.10065.1.12.1.1" = "1.2.840.10065.1.12.1.1",

        /** Coauthor's Signature - the signature of a health information document coauthor. There can be multiple coauthors of a health information document. */
            "1.2.840.10065.1.12.1.2" = "1.2.840.10065.1.12.1.2",

        /** Co-participant's Signature - the signature of an individual who is a participant in the health information document but is not an author or coauthor. (Example a surgeon who is required by institutional, regulatory, or legal rules to sign an operative report, but who was not involved in the authorship of that report.) */
            "1.2.840.10065.1.12.1.3" = "1.2.840.10065.1.12.1.3",

        /** Transcriptionist/Recorder Signature - the signature of an individual who has transcribed a dictated document or recorded written text into a digital machine readable format. */
            "1.2.840.10065.1.12.1.4" = "1.2.840.10065.1.12.1.4",

        /** Verification Signature - a signature verifying the information contained in a document. (Example a physician is required to countersign a verbal order that has previously been recorded in the medical record by a registered nurse who has carried out the verbal order.) */
            "1.2.840.10065.1.12.1.5" = "1.2.840.10065.1.12.1.5",

        /** Validation Signature - a signature validating a health information document for inclusion in the patient record. (Example a medical student or resident is credentialed to perform history or physical examinations and to write progress notes. The attending physician signs the history and physical examination to validate the entry for inclusion in the patient's medical record.) */
            "1.2.840.10065.1.12.1.6" = "1.2.840.10065.1.12.1.6",

        /** Consent Signature - the signature of an individual consenting to what is described in a health information document. */
            "1.2.840.10065.1.12.1.7" = "1.2.840.10065.1.12.1.7",

        /** Signature Witness Signature - the signature of a witness to any other signature. */
            "1.2.840.10065.1.12.1.8" = "1.2.840.10065.1.12.1.8",

        /** Event Witness Signature - the signature of a witness to an event. (Example the witness has observed a procedure and is attesting to this fact.) */
            "1.2.840.10065.1.12.1.9" = "1.2.840.10065.1.12.1.9",

        /** Identity Witness Signature - the signature of an individual who has witnessed another individual who is known to them signing a document. (Example the identity witness is a notary public.) */
            "1.2.840.10065.1.12.1.10" = "1.2.840.10065.1.12.1.10",

        /** Consent Witness Signature - the signature of an individual who has witnessed the health care provider counselling a patient. */
            "1.2.840.10065.1.12.1.11" = "1.2.840.10065.1.12.1.11",

        /** Interpreter Signature - the signature of an individual who has translated health care information during an event or the obtaining of consent to a treatment. */
            "1.2.840.10065.1.12.1.12" = "1.2.840.10065.1.12.1.12",

        /** Review Signature - the signature of a person, device, or algorithm that has reviewed or filtered data for inclusion into the patient record. ( Examples: (1) a medical records clerk who scans a document for inclusion in the medical record, enters header information, or catalogues and classifies the data, or a combination thereof; (2) a gateway that receives data from another computer system and interprets that data or changes its format, or both, before entering it into the patient record.) */
            "1.2.840.10065.1.12.1.13" = "1.2.840.10065.1.12.1.13",

        /** Source Signature - the signature of an automated data source. (Examples: (1) the signature for an image that is generated by a device for inclusion in the patient record; (2) the signature for an ECG derived by an ECG system for inclusion in the patient record; (3) the data from a biomedical monitoring device or system that is for inclusion in the patient record.) */
            "1.2.840.10065.1.12.1.14" = "1.2.840.10065.1.12.1.14",

        /** Addendum Signature - the signature on a new amended document of an individual who has corrected, edited, or amended an original health information document. An addendum signature can either be a signature type or a signature sub-type (see 8.1). Any document with an addendum signature shall have a companion document that is the original document with its original, unaltered content, and original signatures. The original document shall be referenced via an attribute in the new document, which contains, for example, the digest of the old document. Whether the original, unaltered, document is always displayed with the addended document is a local matter, but the original, unaltered, document must remain as part of the patient record and be retrievable on demand. */
            "1.2.840.10065.1.12.1.15" = "1.2.840.10065.1.12.1.15",

        /** Modification Signature - the signature on an original document of an individual who has generated a new amended document. This (original) document shall reference the new document via an additional signature purpose. This is the inverse of an addendum signature and provides a pointer from the original to the amended document. */
            "1.2.840.10065.1.12.1.16" = "1.2.840.10065.1.12.1.16",

        /** Administrative (Error/Edit) Signature - the signature of an individual who is certifying that the document is invalidated by an error(s), or is placed in the wrong chart. An administrative (error/edit) signature must include an addendum to the document and therefore shall have an addendum signature sub-type (see 8.1). This signature is reserved for the highest health information system administrative classification, since it is a statement that the entire document is invalidated by the error and that the document should no longer be used for patient care, although for legal reasons the document must remain part of the permanent patient record. */
            "1.2.840.10065.1.12.1.17" = "1.2.840.10065.1.12.1.17",

        /** Timestamp Signature - the signature by an entity or device trusted to provide accurate timestamps. This timestamp might be provided, for example, in the signature time attribute. */
            "1.2.840.10065.1.12.1.18" = "1.2.840.10065.1.12.1.18"
    }

    export enum SpecialArrangement {
        /** Wheelchair - The patient requires a wheelchair to be made available for the encounter. */
        wheel = "wheel",

        /** Additional bedding - An additional bed made available for a person accompanying the patient, for example a parent accompanying a child. */
        addBed = "add-bed",

        /** Interpreter    - The patient is not fluent in the local language and requires an interpreter to be available. Refer to the Patient.Language property for the type of interpreter required. */
        int = "int",

        /** Attendant - A person who accompanies a patient to provide assistive services necessary for the patient's care during the encounter. */
        att = "att",

        /** Guide dog - The patient has a guide-dog and the location used for the encounter should be able to support the presence of the service animal. */
        dog = "dog"
    }

    export enum SpecialCourtesy {
        /** extended courtesy */
        ext = "EXT",

        /** normal courtesy */
        nrm = "NRM",

        /** professional courtesy */
        prf = "PRF",

        /** Courtesies extended to the staff of the entity providing service. */
        stf = "STF",

        /** very important person */
        vip = "VIP",

        /** Not applicable */
        na = "NA",

        /** Other */
        oth = "OTH",

        /** Un-Encoded, the actual value has not yet been encoded within the approved value domain. */
        unc = "UNC",

        /** Unknown, A proper value is applicable, but not known.
         *  + Usage Notes:
         * + + This means the actual value is not known.
         * + + If the only thing that is unknown is how to properly express the value in the necessary constraints (value set, datatype, etc.), then the OTH or UNC flavor should be used.
         * + No properties should be included for a datatype with this property unless:
         * + + Those properties themselves directly translate to a semantic of "unknown". (E.g. a local code sent as a translation that conveys 'unknown')
         * + + Those properties further qualify the nature of what is unknown. (E.g. specifying a use code of "H" and a URL prefix of "tel:" to convey that it is the home phone number that is unknown.)
         */
        unk = "UNK"
    }

    export enum TimingAbbreviation {
        /** Three times a day at institution specified time */
        TID = "TID",

        /** Four times a day at institution specified time */
        QID = "QID",

        /** Every morning at institution specified times */
        AM = "AM",

        /** Every afternoon at institution specified times */
        PM = "PM",

        /** Every Day at institution specified times */
        QD = "QD",

        /** Every Other Day at institution specified times */
        QOD = "QOD",

        /** Every 4 hours at institution specified times */
        Q4H = "Q4H",

        /** Every 6 Hours at institution specified times */
        Q6H = "Q6H"

    }

    export enum UnitsOfTime {
        /** second */
        s = "s",
        /** minute */
        min = "min",
        /** hour */
        h = "h",
        /** day */
        d = "d",
        /** week */
        wk = "wk",
        /** month */
        mo = "mo",
        /** year */
        a = "a"
    }
}
