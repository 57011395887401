export class KeyValuePair {
    private readonly __key: string;
    private readonly __value: string;

    get key() {
        return this.__key;
    }

    get value() {
        return this.__value;
    }

    constructor(key?: string, value?: string) {
        this.__key = key;
        this.__value = value;
    }
}

export class KeyValueArrayPair {
    private readonly __key: string;
    private readonly __values: string[];

    get key() {
        return this.__key;
    }

    get values() {
        return this.__values;
    }

    constructor(key?: string, values?: string[]) {
        this.__key = key;
        this.__values = values;
    }
}
