const moment = require("moment");

export class MathHelper {
    static percentRange(start: number, end: number, current: number, invert: boolean = false) {
        const total = end - start;
        const elapsed = current - start;

        const percent = Math.max(0, Math.min(1, elapsed / total));

        return invert ? 1 - percent : percent;
    }

    static percentDateRange(a: Date, b: Date, n: Date) {
        const start = moment(a);
        const end = moment(b);
        const current = moment(n);
        const total = end.valueOf() - start.valueOf();
        const elapsed = current.valueOf() - start.valueOf();

        return Math.max(0, Math.min(1, elapsed / total));
    };
}
