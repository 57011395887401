import {ConfigService} from "../../services/ConfigService";
import * as environment from "../../../../config/environment.json";
import {QuestionnaireService} from "../../services/QuestionnaireService";
import {fhirEnums} from "../fhir-enums";
import {NitTools} from "../NursitTools";
import {translations} from "../translations";
import {PatientItem} from "../Patient/PatientItem";
import {QueryOptions} from "../../../../custom_typings/fhir-client";
import {QuestionnaireResponse} from "./QuestionnaireResponse";
import * as Fhir from "./Fhir";
import {Questionnaire} from "./Fhir";
import {FhirService} from "../../services/FhirService";
import {IQuestionnaireListItem} from "../IQuestionnaireListItem";
import {RuntimeInfo} from "../RuntimeInfo";
import {UserService} from "resources/services/UserService";
import SystemHeaders from "../SystemHeaders";

const expr = require("expr-eval");

const moment = require("moment");
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;
import ResourceType = fhirEnums.ResourceType;
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;

export class Tools {
    public static GetOfflineInfo(encounter) {
        const offlineInfo = {
            offline: false,
            since: undefined,
            due: undefined,
            by: {
                userId: undefined,
                name: undefined
            },
            expired: false,
            expireString: undefined
        }

        if (!encounter || !encounter.meta || !encounter.meta.tag) return offlineInfo;
        const tag = FhirService.Tags.get(encounter, '/offlinePatient');
        if (tag) {
            // tag has been found, so generally this is offline
            offlineInfo.offline = true;

            if (tag.code) { // in the code we get the locking timestamp
                const since = moment(tag.code);
                offlineInfo.since = since.toDate();

                const due = moment(tag.code).add(FhirService.OfflineClientSettings.maxLockDuration, 'minutes');
                offlineInfo.due = due.toDate();
                offlineInfo.offline = due.isAfter(new Date());

                if (!offlineInfo.offline) { // when this is no longer offline, it is expired
                    offlineInfo.expired = true;
                    offlineInfo.expireString = due.format(RuntimeInfo.DateTimeFormat);

                    // when this thing is expired > 24h, don't tell that it is expired

                    let dMaxExpireInfo = moment(due.toDate());
                    dMaxExpireInfo = dMaxExpireInfo.add(12, 'hours');
                    if (!dMaxExpireInfo.isAfter(new Date())) {
                        offlineInfo.expired = false;
                        offlineInfo.expireString = ''; // += " - info bis:" + dMaxExpireInfo.format(RuntimeInfo.DateTimeFormat);
                    }
                }


            }

            if (tag.display) {
                if (tag.display.indexOf('|') > -1) {
                    [offlineInfo.by.userId, offlineInfo.by.name] = tag.display.split('|');
                } else {
                    offlineInfo.by.name = tag.display;
                }
            }
        }

        // when this is LOCAL it is never ever offline  (or everything is offline, which makes it online again ..., however, offline=false is always false when local)
        if (FhirService.OfflineClientSettings.isOffline) offlineInfo.offline = false;

        return offlineInfo;
    }

    /**
     * Only for class internal use. Is called from CreateStructuredResponse
     */
    private static _clearNodeProperties(node) {
        let linkId = node.linkId;
        let itemArray: any[] = undefined;
        let text = node.text;

        if (node.item) {
            itemArray = [];

            node.item.forEach((item) => {
                itemArray.push(this._clearNodeProperties(item));
            });
        }

        let result = {linkId: linkId};
        if (itemArray) result["item"] = itemArray;
        if (text) result["text"] = text;

        return result;
    }

    /**
     * Creates a structured report containing all Groups as a valid QuestionnaireResponse
     * @param questionnaire The Questionnaire to create the response for
     * @param response The QuestionnaireResponse to create the items in
     * @return The edited QuestionnaireResponse
     */
    public static CreateStructuredResponse(questionnaire, response) {
        response.item = [];
        questionnaire?.item?.forEach((item) => {
            response.item.push(this._clearNodeProperties(item));
        });

        return response;
    }

    /**
     * only for class internal use. Is called from GetAllResponseLinkIds
     */
    private static _getAllResponseLinkIds(currentItem): string[] {
        let result = [currentItem.linkId];
        if (currentItem.item && currentItem.item.length > 0) {
            currentItem.item.forEach((item) => {
                let tmp = this._getAllResponseLinkIds(item);
                if (tmp) {
                    tmp.forEach((linkId) => result.push(linkId));
                }
            });
        }

        return result;
    }

    /**
     * Gets all LinkIds as a string[] existing in the given QuestionnaireResposne
     * @param response The response to read the item from
     * @return an array of strings, containing the existing linkIds
     */
    public static GetAllResponseLinkIds(response): string[] {
        let result = [];
        if (response.item && response.item.length > 0) {
            response.item.forEach((item) => {
                let tmp = this._getAllResponseLinkIds(item);
                if (tmp) {
                    tmp.forEach((linkId) => result.push(linkId));
                }
            });
        }

        return result;
    }

    public static GetExtensionValueFromUrl(element, url: string) {
        let ext = this.GetOrCreateExtension(element, url, false);
        if (!ext) return undefined;
        return this.GetExtensionValue(ext);
    }

    public static GetExtensionValue(extension) {
        if (!extension) return undefined;
        let key = Object.keys(extension).find((i) => i.indexOf("value") === 0);
        if (!key) return undefined;
        return extension[key];
    }

    public static DeleteFlag(flag, system: string) {
        if (!flag) return undefined;
        if (!flag.code) flag.code = {};
        if (!flag.code.coding) flag.code.coding = [];

        let result = flag.code.coding.find(o => o && o.system && o.system.toUpperCase().endsWith(system.toUpperCase()));
        if (result) {
            const idx = flag.code.coding.indexOf(result);
            if (idx > -1) flag.code.coding.splice(idx, 1);
        }
    }

    public static UpdateAuthor(response, practitioner?) {
        const prac = practitioner || UserService.Practitioner;
        if (!prac) {
            delete response.author;
            return;
        }

        response.author = {
            reference: `Practitioner/${prac.id}`
        };

        let display = undefined;
        if (prac.name) {
            let name = UserService.Practitioner.name.find(
                (o) => o.use === "official"
            );
            if (!name)
                name = UserService.Practitioner.name.find(
                    (o) => o.use === "usual"
                );
            if (!name) name = UserService.Practitioner.name[0];
            if (name) {
                display = name.family + ", " + name.given?.join(" ");
            }
        }

        if (display) {
            response.author.display = display;
        }
    }

    /**
     * Takes an Id or Reference-Url like "Patient/123/_history/2" or "Patient/123" and returns the Id (123)
     * @param idOrReference The Id or Url to return the Id from
     */
    public static StripId(idOrReference: any): string {
        if (!idOrReference || (typeof idOrReference==="string" && String(idOrReference).indexOf('/') === -1))
            return idOrReference;

        if (typeof idOrReference === 'object' && idOrReference.reference) // r3 resource link
            idOrReference = idOrReference.reference;

        idOrReference = idOrReference.split('/_history')[0].split('?')[0];
        if (idOrReference.indexOf('|') > -1) {
            idOrReference = idOrReference.split('|')[0]; // 123|1.0.0 -> 123
        }

        if (idOrReference.indexOf('/') > -1) {
            const arr = idOrReference.split('/');
            return arr[arr.length - 1];
        } else {
            return idOrReference;
        }
    }

    public static GetOrCreateFlag(patientFlags, system: string, create: boolean = true): any {
        if (!patientFlags) return undefined;
        if (!patientFlags.code) patientFlags.code = {};
        if (!patientFlags.code.coding) patientFlags.code.coding = [];

        let result = patientFlags.code.coding.find(o => o && o.system && o.system.toUpperCase().endsWith(system.toUpperCase()));
        if (!result && create) {
            if (system.indexOf('http') === -1)
                system = `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/${system}`;

            result = {
                system: system
            }

            patientFlags.code.coding.push(result);
        }

        return result;
    }

    public static GetOrCreateExtension(
        element: any,
        urlEndsWith: string,
        createIfNotExists: boolean
    ): any {
        if (!element) return undefined;
        if (!element.extension && !createIfNotExists) return undefined;

        if (!element.extension) element.extension = [];

        let extension = element.extension.find(
            // (o) => o.url && o.url.toUpperCase().endsWith(url.toUpperCase())
            (o) => o.url && o.url.toUpperCase().endsWith(urlEndsWith.toUpperCase())
        );

        if (!extension && createIfNotExists) {
            extension = {
                url:
                    (urlEndsWith.indexOf("://") === -1 ? NitTools.IncludeTrailingSlash(environment.nursItStructureDefinition) : "")
                    + NitTools.ExcludeLeadingSlash(urlEndsWith)
            };

            element.extension.push(extension);
        }

        return extension;
    }

    /**
     * Adds or updates a QuestionnaireItem.extension
     * @param item The QuestionnaireItem to set the extension to
     * @param url The Url-Value of the extension
     * @param value the stringValue to set the extension to
     */
    public static SetExtension(item, url: string,value: string) : any {
        if (!item.extension) item.extension = [];
        item.extension = item.extension.filter((o) => !o.url.endsWith(url));
        item.extension.push({
            url:
                (url.indexOf("://") === -1
                    ? NitTools.IncludeTrailingSlash(
                        environment.nursItStructureDefinition
                    )
                    : "") + url,
            valueString: value,
        });

        return item.extension.find(o => o.url && o.url === url && o.valueString && o.valueString === value);
    }

    public static async CopyResource(resourceType: string, sourceServer: string, targetServer: string, deleteExisting: boolean = false) {
        if (deleteExisting) {
            await this.DeleteResourceType(resourceType, targetServer);
        }

        let js: any = await Fhir.Rest.GetClient(btoa('root:id4admin')).createRequest(`${sourceServer}/${resourceType}`).asGet().send();
        js = JSON.parse(js.response);
        js = js.entry.map(o => o.resource);

        let bundle = Fhir.Rest.GetBundle(js, HTTPVerb.post);
        await Fhir.Rest.GetClient(btoa('root:id4admin')).createRequest(`${targetServer}`).asPost().withContent(bundle).send();
    }

    public static async DeleteResourceType(type: string, targetServer: string, expunge: boolean = false) {
        console.warn('Deleting existing ' + type + ' from ' + targetServer);

        let killObjects: any[] = await Fhir.Rest.Fetch(`${targetServer}/${type}?_summary=true&_count=500`);
        let killBundle = Fhir.Rest.GetBundle(killObjects, HTTPVerb.delete);
        await Fhir.Rest.GetClient(btoa('root:id4admin')).createRequest(`${targetServer}`).asPost().withContent(killBundle).send();

        if (expunge)
            await Fhir.Rest.$Expunge(type, "cm9vdDppZDRhZG1pbg==", targetServer);
    }

    /**
     * Removes an Extenstion from the given item if existent
     * @param item The item to remove the extension from
     * @param url The (part of the) url of the Extension to remove
     */
    public static RemoveExtension(item, url: string) {
        if (!item || !item.extension) return;
        item.extension = item.extension.filter((o) => !o.url.endsWith(url));
    }

    /**
     * Calculate a Field's value if it is a calculated field.
     * @param item The QuestionnaireItem to calc the value for
     * @param questionnaire The Questionnaire the other QuestionnaireItems are searched for
     * @param response The QuestionnaireResponse the values of the QuestionnaireItems are taken from
     * @return the calculated value as string|number|boolean or undefined
     */
    public static CalculateField(
        questionnaireItem,
        questionnaire,
        response
    ): string | number | boolean {
        window["parser"] = new expr.Parser();
        window["parse"] = window["parser"].parse;
        if (!questionnaireItem || !response || !response.item || !questionnaireItem.extension) {
            return;
        }

        let curErrorName : string = "unknown!";
        try {
            let calcObject: any = {};

            let extCalc = questionnaireItem.extension?.find(o =>
                o.url.endsWith("questionnaire-calculated-field")
            );

            let formula: string = undefined;
            if (extCalc && !!extCalc.valueString) {
                formula = extCalc.valueString;
            }

            if (
                !formula &&
                questionnaireItem.initialCoding &&
                questionnaireItem.initialCoding.code &&
                questionnaireItem.initialCoding.code.indexOf("=") === 0
            ) {
                formula = questionnaireItem.initialCoding.code.substr(1);
            }

            if (!formula) {
                return;
            }

            curErrorName = `"${questionnaireItem.text}" [${questionnaireItem.linkId}]`;
            let parser = new expr.Parser();
            let exp = parser.parse(formula);
            parser.functions.fpath = (path) => {
                let result = String(fhirpath.evaluate(response, path, null, fhirpath_stu3_model));
                return result;
            };

            parser.functions.sum = (arr : any) => {
                if (!arr) return '';
                if (typeof arr==="string") {
                    arr = arr.split(',');
                }

                let resultSum : number = 0;
                for (const a of arr) {
                    const parsed = parseFloat(String(a).trim());
                    if (isNaN(parsed)) continue;
                    resultSum += parsed;
                }

                return resultSum;
            };

            parser.functions.parseFloat = (value) => {
                return parseFloat(value);
            }

            parser.functions.parseInt = (value) => {
                return parseInt(value);
            }

            parser.functions.toString = (value) => {
                return String(value);
            }

            parser.functions.join = (sep, ...params) => {
                const arr = [];
                for (const p of params) {
                    const sP = String(p).trim();
                    if (sP != '')
                        arr.push(sP);
                }

                return arr.join(sep)
            }

            for (let name of exp.variables()) {
                if (name.indexOf("VAR_") === 0) {
                    name = name.substr(4);
                } else if (name.indexOf("CODE_") === 0) {
                    name = name.substr(5);
                } if (name.indexOf("DISPLAY_") === 0) {
                    name = name.substr(8);
                }

                let val: any = undefined;
                let questionnaireItem = Questionnaire.GetQuestionnaireItemByLinkId(questionnaire,name);

                if (questionnaireItem) {
                    const vars = [];
                    const displays = [];
                    const codes = [];

                    let responseItem = QuestionnaireResponse.GetResponseItemByLinkId(response,questionnaireItem.linkId,false);

                    if (NitTools.IsArray(responseItem?.answer)) {
                        for (const answer of responseItem?.answer) {
                            val = QuestionnaireResponse.GetResponseAnswerValue(answer);

                            // if this is an open-choice, get the value from the extension.ordinalValue.valueDecimal if existent
                            if ((questionnaireItem.type === "open-choice" || questionnaireItem.type === "choice") && val) {
                                if (!questionnaireItem.option && questionnaireItem["answerOption"]) // R4 fix
                                    questionnaireItem.option = questionnaireItem["answerOption"];

                                let option = (questionnaireItem.option || questionnaire.answerOption)?.find((o) => o.valueCoding && o.valueCoding.code === val);
                                if (option?.extension) {
                                    let ordValueExtension = option.extension.find((o) => o.url?.endsWith("questionnaire-ordinalValue"));
                                    if (typeof ordValueExtension?.valueDecimal !== "undefined" || typeof ordValueExtension?.valueInteger !== "undefined") {
                                        val = ordValueExtension.valueDecimal || ordValueExtension.valueInteger;
                                    }
                                }
                            }

                            // when the resulting value is a string its most likely something like "Link_00_01". So get the 01 as value
                            if (typeof val === "string" && val.indexOf("_") > -1) {
                                let sa = val.split("_");
                                let s = sa[sa.length - 1];
                                val = parseInt(s);
                            }

                            vars.push(String(val || 0));

                            // const display = answer.valueCoding?.display || answer.valueString || answer.valueInteger||answer.valueDecimal||answer.valueDate||answer.valueTime||answer.valueDateTime || answer.valueUri||answer.valueReference||(typeof answer.valueBoolean == "boolean" ? answer.valueBoolean : undefined);
                            const display = QuestionnaireResponse.GetResponseAnswerDisplay(answer);
                            if (display) {
                                displays.push(display);
                            }

                            const code = QuestionnaireResponse.GetResponseAnswerValue(answer);
                            if (code) {
                                codes.push(code);
                            }
                        }
                    }

                    // add a VAR_xx too, because old calculation relies on it
                    calcObject[name] = calcObject["VAR_" + name] = vars.join(',');
                    calcObject["CODE_" + name] = codes.join(',');
                    calcObject["DISPLAY_" + name] = displays.join(', ');
                }
            }

            try {
                let val = exp.evaluate(calcObject);

                // let targetItem = this.response.item.find(o => o.linkId === item.linkId);
                let targetItem = QuestionnaireResponse.GetResponseItemByLinkId(response,questionnaireItem.linkId,true);
                if (targetItem) {
                    // targetItem.text = String(val);
                    targetItem.answer = [
                        {valueCoding: {code: val, display: String(val)}},
                    ];
                } else {
                    targetItem = {
                        linkId: questionnaireItem.linkId,
                        text: questionnaireItem.text,
                        answer: []
                    }

                    response.item.push(targetItem);
                    Questionnaire.EnsureStructuredResponse(questionnaire, response);
                }

                if (questionnaireItem.type === "string") {
                    let sVal = String(val);
                    if (sVal === "NaN" || sVal === "undefined") val = undefined;
                    else val = String(val);

                    if (targetItem) {
                        targetItem.answer = [{ valueString: val}];
                    }
                } else if (questionnaireItem.type === "integer") {
                    let int = parseInt(String(val));
                    if (isNaN(int)) val = undefined;
                    else val = int;
                    if (targetItem) {
                        targetItem.answer = [{ valueInteger: val}];
                    }
                } else if (questionnaireItem.type === "decimal") {
                    let dec = parseFloat(String(val));
                    if (isNaN(dec)) val = undefined;
                    else val = dec;
                    if (targetItem) {
                        targetItem.answer = [{ valueDecimal: val}];
                    }
                } else if (questionnaireItem.type === "boolean") {
                    val = NitTools.ParseBool(String(val));
                    if (targetItem) {
                        targetItem.answer = [{ valueBoolean: val}];
                    }
                } else {
                    val = String(val) === "NaN" ? undefined : val;
                }

                return val;
            } catch (e) {
                console.warn(e);
                return `[error: ${e.message}]`;
            }
        } catch (e) {
            console.warn(`in ${curErrorName}`, e.message || JSON.stringify(e));
            return;
        }
    }

    public static GetMark(
        patient: PatientItem,
        index: number,
        dry: string = "",
        redAfter: number = 23,
        yellowAfter: number = 12,
        debug?: boolean,
        overrideDate?: string
    ): boolean {
        if (["", "red", "yellow"].indexOf(dry) === -1) {
            console.warn(`Illegal 'dry'-Property "${dry} given. Aborting.`);
            return false;
        }

        if (!patient.selectedAdditionalInfo && index !== 10 && index !== 1)
            return false;

        let result = false;
        if (index === 10 && ConfigService.UseAssessmentForSignal10) {
            // Assessment handling
            let ageHours: number = 0;
            if (patient.latestAssessment || overrideDate) {
                let dAuthored = new Date(overrideDate || patient.latestAssessment.authored);
                let now = new Date();
                ageHours = moment(now).diff(dAuthored, "h");
                if (dry === "red") {
                    result = ageHours >= redAfter;
                } else if (dry === "yellow") {
                    result = ageHours >= yellowAfter;
                }
            } else {
                // no assessment, so this has to be red
                result = dry === "red";
            }

            return result;
        } else if (index === 1 && ConfigService.UseIsolationForFlag1) {
            // isolation handling

            if (!patient.mark_1) return false;
            /**************

             ******/

            if (dry === "red") {
                let latestIso = QuestionnaireService.GetLatestResponseOfType(
                    patient,
                    QuestionnaireService.__listResult.QIsolationId,
                    [
                        QuestionnaireResponseStatus.amended,
                        QuestionnaireResponseStatus.completed,
                    ]
                );

                let isRed = false;
                try {
                    if (!overrideDate && (!latestIso || !latestIso.authored)) return true;
                    const dateString = overrideDate ? overrideDate : latestIso ? latestIso.authored : undefined;
                    if (!dateString) return true;

                    let dAuthored = new Date(dateString);
                    let now = new Date();
                    let ageHours = moment(now).diff(dAuthored, "h");
                    let result = ageHours >= redAfter;
                    //*********************************************************************************
                    //#region get the reset Time from config|default
                    let h = 0;
                    let m = 0;
                    let config = ConfigService.GetFormSettings("isolation");
                    let resetConfigTime =
                        config && config.settings && config.settings["resetTime"]
                            ? config.settings["resetTime"]
                            : "00:00";
                    if (resetConfigTime.indexOf(":") > -1) {
                        h = resetConfigTime.split(":")[0];
                        m = resetConfigTime.split(":")[1];
                    }
                    //#endregion

                    // the expiration date of the iso-document.authored is the next day @ the configured time
                    // so when the document is created @ 01.01.2020 the expiration should be the 02.01.2020 + resetTime
                    let expirationDate = new Date(overrideDate || latestIso.authored);
                    expirationDate.setMilliseconds(0);
                    expirationDate.setSeconds(0);
                    expirationDate.setMinutes(m);
                    expirationDate.setHours(h);
                    expirationDate.setDate(expirationDate.getDate() + 1);

                    isRed = moment(now).isAfter(expirationDate);
                } catch (error) {
                    console.warn(error.message || error);
                }

                return isRed;
            } else {
                return dry !== "yellow";
            }
        }

        let id = "mark_" + index;
        if (dry) {
            id += "_" + dry;
        }

        let bResult = false;
        let fromFlag = false;

        if (patient.flags) {
            bResult = false;

            let coding = patient.flags.code.coding.find((o) =>
                o.system.endsWith(id)
            );
            bResult = NitTools.ParseBool(coding.code);
            if (ConfigService.Debug) console.debug(id + " FROM FLAG:", coding);
            fromFlag = true;
        }

        if (!fromFlag) {
            let markItem = QuestionnaireResponse.GetResponseItemByLinkId(
                patient.selectedAdditionalInfo,
                id,
                false
            );
            if (markItem)
                bResult = NitTools.ParseBool(
                    QuestionnaireResponse.GetResponseItemValue(markItem)
                );
        }

        return bResult;
    }

    public static ResponseItemValuesDiffer(itemA, itemB): boolean {
        let valA = "";
        if (itemA && itemA.answer) {
            itemA.answer.forEach((a) => {
                valA +=
                    String(QuestionnaireResponse.GetResponseAnswerValue(a)) +
                    ",";
            });
        }

        if (valA.endsWith(",")) valA = valA.substr(0, valA.length - 1);
        if (valA === "NaN" || valA === "0") valA = "";

        let valB = "";
        if (itemB && itemB.answer) {
            itemB.answer.forEach((a) => {
                valB +=
                    String(QuestionnaireResponse.GetResponseAnswerValue(a)) +
                    ",";
            });
        }
        if (valB.endsWith(",")) valB = valB.substr(0, valB.length - 1);
        if (valB === "NaN" || valB === "0") valB = "";

        if (valA != valB) {
            if (ConfigService.Debug && !ConfigService.IsTest) {
                console.debug(
                    `[FHIR.QuestionnaireResponse.ItemValuesDiffer]\nItemValue changed from "${valA}" => "${valB}"`
                );
            }

            return true;
        }

        return false;
    }

    /** Check if the Item-values between the QuestionnaireResponses responseA and responseB differ.
     * @param  responseA the first QuestionnaireResponse for compare
     * @param  responseB the second QuestionnaireResponse for compare
     * @return a boolean indicating whether the item values differ
     **/
    public static ResponseValuesDiffer(responseA, responseB): boolean {
        // only one given?
        if ((responseA && !responseB) || (!responseA && responseB)) return true;

        // none given?
        if (!responseA && !responseB) return false;

        // different items property?
        if (
            (!responseA.item && responseB.item) ||
            (responseA.item && !responseB.item)
        )
            return true;

        let questionnaire = QuestionnaireService.GetQuestionnaireDirect(responseA.questionnaire);
        let linkIds = Fhir.Questionnaire.GetAllQuestionnaireItemLinkIds(questionnaire);

        for (let i = 0; i < linkIds.length; i++) {
            let linkId = linkIds[i];
            let item1 = QuestionnaireResponse.GetResponseItemByLinkId(
                responseA,
                linkId,
                false
            );
            let item2 = QuestionnaireResponse.GetResponseItemByLinkId(
                responseB,
                linkId,
                false
            );

            if (this.ResponseItemValuesDiffer(item1, item2)) {
                return true;
            }
        }

        return false;
    }

    public static GetBundle(resources: any[],method: HTTPVerb, bundleType: BundleType = BundleType.transaction) {
        let bundle = {
            entry: [],
            type: bundleType,
            resourceType: ResourceType.bundle,
            id: NitTools.Uid(),
        };

        resources = resources.filter((o) => o && o.id);
        resources.forEach((qr: any) => {
            let url;

            if (qr._url) {
                url = qr._url;
            } else {
                url = `${FhirService.Endpoint}/${qr.resourceType}`;

                if (method !== HTTPVerb.post) {
                    url = `${qr.resourceType}/${qr.id}`;
                }
            }

            let e;
            if (method === HTTPVerb.delete) {
                e = {
                    request: {
                        method: method,
                        url: url,
                    },
                };
            } else {
                e = {
                    resource: qr,
                    request: {
                        method,
                        url,
                    },
                };
            }

            try {
                if (e.request.method !== HTTPVerb.delete) {
                    if (qr._url || !bundle.entry.find((o) => o.resource.id === e.resource.id)) {
                        bundle.entry.push(e);
                    }
                } else {
                    bundle.entry.push(e);
                }
            } catch (error) {
                console.warn("Error bundling Resource", e);
                throw error.message || error;
            }
        });

        return bundle;
    }

    /**
     * Generates a Patient.Text with the correct Div-Tag in the given patient
     * @param patient the patient to generate the text property for
     * @constructor
     */
    public static GeneratePatientTextObject(patient): {
        familyName: string;
        givenName: string;
        prefix: string;
        suffix: string;
        born: string;
        age: number;
        display: string;
    } {
        if (!patient) return;

        patient.text = {
            div: '<div xmlns="http://www.w3.org/1999/xhtml"></div>',
            status: "generated",
        };

        let bestMatchingFamilyName: string = undefined;
        let bestMatchingGivenName: string = undefined;
        let prefix = "";
        let suffix = "";
        if (patient.name) {
            patient.name.forEach((n) => {
                if (!bestMatchingFamilyName) {
                    bestMatchingFamilyName = n.family;
                }

                if (n.given && !bestMatchingGivenName && n.given.length > 0) {
                    bestMatchingGivenName = n.given[0];
                }

                if (n.use === "official") {
                    bestMatchingFamilyName = n.family;
                    prefix = n.prefix ? n.prefix.join(" ") : "";
                    suffix = n.suffix ? n.suffix.join(" ") : "";
                    if (n.given) {
                        bestMatchingGivenName = (<string[]>n.given).join(" ");
                    }
                }
            });
        }

        let display = [bestMatchingFamilyName || "", bestMatchingGivenName || ""]
            .join(", ")
            .replace("  ", " ");

        let familyName = bestMatchingFamilyName || "";
        let givenName = bestMatchingGivenName || "";
        //#endregion

        //#region setup birthdate
        let dateFormat = translations.translate("date_format");
        if (patient.birthDate) {
            patient.birthDate = new Date(patient.birthDate).toJSON();
        } else {
            patient.birthDate = new Date("1901-01-01").toJSON();
        }

        let birthDateDisplay = moment(patient.birthDate).format(dateFormat);
        let years = moment().diff(patient.birthDate, "years", false);
        //#endregion

        patient.text.div = '<div xmlns="http://www.w3.org/1999/xhtml"><div>';

        //#region first line, prefix, lastname, suffix
        patient.text.div += "<div class='patient-display-line-1'>";
        if (suffix.trim()) {
            patient.text.div += `<span class="patient-name-prefix">${prefix}</span>&nbsp;`;
        }

        patient.text.div += `<span class='patient-name-family'">${familyName}</span>`;
        if (givenName.trim()) {
            patient.text.div += `, <span class="patient-name-given">${givenName}</span>`;
        }

        if (suffix.trim()) {
            patient.text.div += `, <span class="patient-name-suffix">${suffix}</span>`;
        }

        if (patient?.identifier) {
            let patientNumber : string = undefined;
            if (NitTools.IsArray(patient.identifier)) {
                // search for ISIK Patient Number and the more native method
                let numberIdentifier = patient.identifier.find(o=>(o.type?.coding && o.type.coding.find(c => c.code == "MR")) || o.system.indexOf('/patientNumber') > -1);

                if (numberIdentifier?.value) {
                    patientNumber = numberIdentifier.value;
                }
            }

            if (patientNumber) {
                patient.text.div += `<span class="patient-number">${patientNumber}</span>`;
                display += ` (${patientNumber})`;
            }
        }

        patient.text.div += "</div>";
        //#endregion

        //#region line 2 - gender
        patient.text.div += "<div class='patient-display-line-2'>";
        patient.text.div += `    <span class='patient-name-gender'>${translations.translate(
            "gender_" + String(patient.gender)
        )}</span>`;
        patient.text.div += "</div>";
        //#endregion

        //#region line 3 - birthdate
        patient.text.div += "<div class='patient-display-line-3'>";
        patient.text.div += `    <span class='patient-name-born'>*${birthDateDisplay}</span> <span class="patient-name-years">${years}</span>`;
        patient.text.div += "</div>";
        //#endregion

        patient.text.div += "</div></div>";

        return {
            familyName: familyName,
            givenName: givenName,
            prefix: prefix,
            suffix: suffix,
            born: birthDateDisplay,
            age: years,
            display: display,
        };
    }

    public static GetQuestionnaireListForCombo(questionnaireId: string,patient: PatientItem,appendStatus?: boolean): IQuestionnaireListItem[] {
        if (patient) {
            let qrs = patient.questionnaireResponses.filter(
                (o) =>
                    //o.resourceType === fhirEnums.ResourceType.questionnaireResponse
                    o.questionnaire &&
                    o.questionnaire.reference &&
                    o.status &&
                    ["completed", "amended", "inProgress"].indexOf(o.status) >
                    -1 &&
                    o.questionnaire.reference ===
                    `${fhirEnums.ResourceType.questionnaire}/${questionnaireId}`
            );

            if (qrs && qrs.length > 0) {
                let tmpArray = [];
                let format = RuntimeInfo.DateTimeFormat; // translations.translate("date_time_format_short");
                qrs.forEach((q) => {
                    let txt = moment(q.authored).format(format);
                    if (appendStatus) {
                        txt += " (" + q.status.toString()[0] + ")";
                    }

                    tmpArray.push({
                        id: q.id,
                        text: txt,
                        date: moment(q.authored).toDate(),
                    });
                });

                tmpArray.sort((a, b) => {
                    return a.date.valueOf() - b.date.valueOf();
                });

                return tmpArray;
            }
        }

        return [];
    }

    public static GetMark10Color(patient: PatientItem): string {
        if (!patient) {
            console.warn("No Patient given");
            return "";
        }

        const m10 = patient.mark(10);
        if (m10.red) {
            return "active-red";
        }

        if (m10.yellow) {
            return "active-yellow";
        }

        if (m10.checked) {
            return "active";
        }

        return undefined;
    }

    /**
     * Check if the responseItem has the provided search value in any answer
     * @param responseItem the responseItem containing the answers to check for the search
     * @param search the value to search for in the responseItem.answer array
     * @private
     */
    private static responseContainsValue(responseItem, search: any) {
        if (!NitTools.IsArray(responseItem?.answer)) return false;

        for (let i = 0; i < responseItem.answer.length; i++) {
            let answerValue = QuestionnaireResponse.GetResponseAnswerValue(responseItem.answer[i]);

            if (answerValue && String(answerValue) === String(search)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Check if any enableWhen in the given QuestionnaireItem matches the values from the response.<br />Returns true if the enableWhen matches a value in the response.
     * @param item the QuestionnaireItem to check for
     * @param response the QuestionnaireResponse that contains the answers to match agains the enableWhen
     */
    private static enableWhenMatches(item, response) {
        // when no item given or item does not have an enableWhen Property bail out
        if (!item?.enableWhen || item.enableWhen.length === 0)
            return true;

        // we currently only support answerCoding or hasAnswer
        // ToDo: add other possibilites
        let validEnableWhens = item.enableWhen.filter(
            o => o.question && (o.answerCoding?.code || o.hasAnswer === true)
        );

        // when no valid (in out specification) enableWhens has been found bail out
        if (validEnableWhens.length === 0)
            return true;

        for (const enableWhen of validEnableWhens) {
            let responseItem = QuestionnaireResponse.GetResponseItemByLinkId(response,enableWhen.question);

            // first check for the hasAnswer property
            if (enableWhen.hasAnswer === true) {
                const val = QuestionnaireResponse.GetResponseItemValue(responseItem, undefined);
                return typeof val !== "undefined" && !String(val).endsWith('_nil');
            } else { // more complex, compare the current response-value to the enableWhen-value
                // no answer? then enableWhen is not fulfilled
                if (!responseItem?.answer || responseItem.answer.length === 0) {
                    return false;
                }

                // test if the answer array contains the coding
                if (this.responseContainsValue(responseItem, enableWhen.answerCoding.code)) {
                    return true;
                }
            }
        }

        // finally don't match
        return false;
    }

    /**
     * Checks if the QuestoinnaireItem item matches a value in the QuestionnaireResponse response
     * @param item the QuestionnaireItem to return whether a matching enableWhen has been found
     * @param response the QuestionnaireResponse that contains the answers to check for value(s) of the enableWhen
     * @param hiddenIsValid respects whether the item has the extension "questionnaire-hidden" set to "true"
     * @constructor
     */
    public static IsEnableWhenSatisfied(item, response, hiddenIsValid: boolean = false) {
        if (!item || !response) return false;

        //#region always hide groups which name starts with "hidden"
        const qItem = (<any>item);
        if (qItem && qItem.type === "group") {
            if (qItem.text && qItem.text.toUpperCase().indexOf('HIDDEN') === 0) {
                qItem.enableWhenValue = false;
                return false;
            }
        }
        //#endregion

        // check for questionnaire-hidden extension
        if (item.extension) {
            let extHidden = item.extension.find(
                (o) => o.url && o.url.endsWith("questionnaire-hidden")
            );

            if (extHidden) {
                if (!hiddenIsValid && extHidden.valueBoolean === true) {
                    qItem.enableWhenValue = false;
                    return false;
                }
            }
        }

        // check if any of the enableWhens match the current values in the response
        const enableWhenMatch = this.enableWhenMatches(item, response);

        // respect when the user has been hiding the group using the checkboxes in the group list
        if (enableWhenMatch) { // when the group would have been displayed because of the enableWhen..
            if (qItem.type == "group") {
                if (response.extension) { // .. check if this one is hidden ..
                    const visibleExtension = response.extension.find(o => o.url.endsWith(`/questionnaire-group-visible/${qItem.linkId}`));
                    if (visibleExtension?.valueBoolean === false) { // false means not visible aka visible = false
                        qItem.enableWhenValue = false;
                        return false
                    }
                }

                /* if (qItem.item) {
                    for (const qChildItem of qItem.item) {
                        if (!NitTools.IsArray(qItem.initial) || !Tools.IsEnableWhenSatisfied(qChildItem, response)) continue; // should call this stuff recursively
                        const responseItem = QuestionnaireResponse.GetResponseItemByLinkId(response, qItem.linkId);
                        if (responseItem) {
                            if (NitTools.IsArray(responseItem.answer) && responseItem.answer.length > 0) continue;
                            console.warn('Updated to default values', qChildItem, responseItem);
                            responseItem.answer = NitTools.Clone(qChildItem.initial);
                        }
                    }
                } */
            }
        }

        // return just if any value in the response matches a value from the enableWhen[]
        qItem.enableWhenValue = enableWhenMatch;
        return enableWhenMatch;
    }

    public static Skeletons;

    /**
     * gets the default QuestionnaireResponse-Skeleton from Fhir.Tools.Skeletons.default_response and returns it subsituted for the given Patient and Q.-Id
     * @param patient the PatientItem to create the new QuestionnaireResponse for
     * @param questionnaireId the id of the Questionnaire to create for
     * @param status the status to set
     */
    public static SubstituteDefaultQRSkeleton(
        patient: PatientItem,
        questionnaireId: string,
        status?: ('in-progress'|'completed'|'amended'|'entered-in-error'|'stopped')
    ) {
        let result: any = NitTools.Clone(
            Fhir.Tools.Skeletons.default_response
        );

        let questionnaire = QuestionnaireService.GetQuestionnaireDirect(questionnaireId);
        if (typeof questionnaire === "string") {
            let search = questionnaire.toUpperCase();
            const tmp = QuestionnaireService.__questionnaires.find(o=> String(o.id).toUpperCase() === search || String(o.name).toUpperCase() === search);
            if (tmp) {
                questionnaire = tmp;
            }
        }

        if (FhirService.FhirVersion <= 3) { // R3 handling:
            if (typeof questionnaire === "string") {
                result.questionnaire = {reference: `Questionnaire/${questionnaire}`};
            } else if (questionnaire?.id) {
                result.questionnaire = {reference: `Questionnaire/${questionnaire.id}`};
            } else {
                throw "Questionnaire-Id could not be determined";
            }

            if (QuestionnaireService.UseFhirVersionSystem && questionnaire?.meta?.versionId) {
                result.questionnaire.reference += "/_history/" + questionnaire.meta.versionId;
            }
        } else { // R4 handling:
            if (typeof questionnaire == "string") {
                questionnaire = QuestionnaireService.GetQuestionnaireDirect(questionnaire);
            }

            let r4Questionnaire = questionnaire.url || `${QuestionnaireService.NitQuestionnairesUrl}/${questionnaire.name}`;
            if (QuestionnaireService.UseFhirVersionSystem && questionnaire.version && r4Questionnaire && r4Questionnaire.indexOf('|') === -1) {
                r4Questionnaire += `|${questionnaire.version}`;
            }

            result["questionnaire"] = r4Questionnaire;
        }

        if (questionnaire) {
            if (questionnaire.meta && questionnaire.meta.versionId) {
                let extension = Fhir.Tools.GetOrCreateExtension(
                    result,
                    "questionnaire-version",
                    true
                );

                extension.valueId = [
                    questionnaire.version,
                    questionnaire.meta.versionId,
                ]
                    .filter((o) => o)
                    .join(", versionId:");
            }
        }

        if (ConfigService.Debug && !questionnaire) {
            throw "No Questionnaire found for Skeleton!";
        }

        result.subject = {
            reference: `${fhirEnums.ResourceType.patient}/${patient.id}`,
        };

        result.source = {
            reference: `${fhirEnums.ResourceType.patient}/${patient.id}`,
        };

        if (typeof status === "undefined") {
            // default state
            status = fhirEnums.QuestionnaireResponseStatus.inProgress;
        }

        if (typeof status !== "undefined") {
            result.status = status;
        }

        result.authored = new Date().toJSON();

        if (UserService.Practitioner) {
            result.author = {
                reference: `Practitioner/${UserService.Practitioner.id}`,
                display: [UserService.UserLastName, UserService.UserFirstName].join(', '),
            };
        } else {
            console.error('No Practitioner currently present. This should not happen!');
        }

        //ensure valid encounter
        if (FhirService.FhirVersion > 3) {
            result["encounter"] = {
                reference: `${fhirEnums.ResourceType.encounter}/${patient.encounterId}`,
            };
        } else {
            result.context = {
                reference: `${fhirEnums.ResourceType.encounter}/${patient.encounterId}`,
            };
        }

        if (result.questionnaire && (FhirService.FhirVersion > 3 ? true : result.questionnaire.reference) && questionnaire) {
            QuestionnaireResponse.SetDefaultValues(result, undefined);
            Fhir.Questionnaire.EnsureStructuredResponse(questionnaire, result);
        }

        if (!result.id) {
            result.id = NitTools.Uid();
        }

        return result;
    }

    public static GetTimeStamp(date?: Date): string {
        if (typeof date === "undefined") date = new Date();
        let s = `${date.getFullYear()}-${NitTools.ToString(
            date.getMonth() + 1,
            2
        )}-${NitTools.ToString(date.getDate(), 2)}`;
        s += "T";
        s += `${NitTools.ToString(date.getHours(), 2)}:${NitTools.ToString(
            date.getMinutes(),
            2
        )}:${NitTools.ToString(date.getSeconds(), 2)}`;

        return s;
    }

    public static CareLevelToText(level: number): string {
        if (level < 0) return "";
        return translations.translate(`carelevel${level}`);
    }

    public static UseEncounterPeriod: boolean = false;

    public static GetEncounterListUrl(
        locationId: string,
        includeLocations: boolean = true,
        includeResponses: boolean = true,
        summary: boolean = false
    ): string {
        let url =
            `Encounter?_include=Encounter:patient&location=${locationId}&_format=json` +
            "&_revinclude=Flag:encounter" +
            "&_revinclude=RiskAssessment:encounter";

        if (includeLocations) {
            url += `&_include=Encounter:location`;
        }

        if (includeResponses) {
            url += `&_revinclude=QuestionnaireResponse:${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}`;
        }

        if (FhirService.EncounterAdditionalFilters) {
            url += "&" + FhirService.EncounterAdditionalFilters;
        }

        if (Tools.UseEncounterPeriod) {
            let dateStr = moment(new Date())
                .add(-1, "day")
                .format("YYYY-MM-DD");

            if (ConfigService.Debug) {
                dateStr = moment(new Date())
                    .add(-1, "month")
                    .format("YYYY-MM-DD");
            }

            url += `&location-period=>${dateStr}`;
        }

        if (summary) {
            url += "&_summary=true";
        }

        if (RuntimeInfo.EncounterDateFormat) {
            url = url.replace(
                "{encounterDateFormat}",
                moment(new Date()).format(RuntimeInfo.EncounterDateFormat)
            );
        }


        return url;
    }

    public static GetEncounterListObject(
        locationId: string,
        includeLocations: boolean = true
    ): QueryOptions {
        let result: QueryOptions = {
            $include: {Encounter: ["patient", "location"]},
            _revinclude: FhirService.FhirVersion > 3 ? "QuestionnaireResponse:encounter" : "QuestionnaireResponse:context",
            location: locationId,
            _format: "json",
        };

        let sa = FhirService.EncounterAdditionalFilters.split("&");
        sa.forEach((s: string) => {
            let kvp = s.split("=");
            result[kvp[0]] = kvp[1];
        });

        if (Tools.UseEncounterPeriod) {
            let dateStr = moment(new Date())
                .add(-1, "day")
                .format("YYYY-MM-DD");

            if (ConfigService.Debug) {
                dateStr = moment(new Date())
                    .add(-1, "month")
                    .format("YYYY-MM-DD");
            }

            result["location-period"] = "=>" + dateStr;
        }

        return result;
    }

    /** converts the careLevel to a textcolor */
    public static CareLevelToTextColor(level: number): string {
        if (level >= 0) {
            let col = this.CareLevelToColor(level);
            if (col === "#fff908") col = "#a7a72a";
            return col;
        }

        return "black";
    }

    public static CareLevelToTextColorInvert(level: number): string {
        switch (level) {
            case -1:
            case 1:
            case 2:
            default:
                return 'black';
                break;
            case 3:
            case 0:
                return 'white';
        }
    }

    public static SyncFlagsFromPatient(patient: PatientItem) {
        let flag = patient.flags;
        if (!flag) return;

        flag.code.coding = [];
        for (let i = 1; i <= 10; i++) {
            const mark = patient.mark(i);

            flag.code.coding.push({
                system: `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/mark_${i}`,
                code: String(mark.checked),
            });
            flag.code.coding.push({
                system: `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/mark_${i}_yellow`,
                code: String(mark.yellow),
            });
            flag.code.coding.push({
                system: `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/mark_${i}_red`,
                code: String(mark.red),
            });
        }

        flag.code.coding.push({
            system: `${NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase)}/CareLevel`,
            code: String(patient.careLevel),
        });
        flag.code.coding.push({
            system: `${NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase)}/CareLevelColor`,
            code: String(patient.careLevelColor),
        });
        flag.code.coding.push({
            system: `${NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase)}/CareLevelString`,
            code: String(patient.careLevelString),
        });
    }

    /** converts a Patients' CareLevel to the color used as marker in the patientlist */
    public static CareLevelToColor(level: number): string {
        /***********
         * Pflegeintensität, leicht 40-37 Grün Care intensity, light
         Pflegeintensität, erhöht 36-30 Gelb Care intensity, increased
         Pflegeintensität, hoch 29-20 Orange Care intensity, high
         Pflegeintensität, sehr hoch 19-10 Rot Care intensity, very high
         */
        switch (level) {
            default:
                return "#ffffff";   // white
            case 0:
                return "#008000";   // green
            case 1:
                return "#fff908";   // yellow
            case 2:
                return "#ffa500";    // orange
            case 3:
                return "#ff0000";       // red
        }
    }

    public static SpiToString(spi_sum: number) {
        return translations.translate(
            `carelevel${this.SpiToCareLevel(spi_sum)}`
        );
    }

    public static SpiToCareLevel(spi_sum: number): number {
        /***********
         * Pflegeintensität, leicht 40-37 Grün Care intensity, light
         Pflegeintensität, erhöht 36-30 Gelb Care intensity, increased
         Pflegeintensität, hoch 29-20 Orange Care intensity, high
         Pflegeintensität, sehr hoch 19-10 Rot Care intensity, very high
         */

        if (spi_sum >= 37) {
            // && spi_sum <= 40) {
            return 0;
        } else if (spi_sum >= 30 && spi_sum <= 36) {
            return 1;
        } else if (spi_sum >= 20 && spi_sum <= 29) {
            return 2;
        } else if (spi_sum >= 10 && spi_sum <= 19) {
            return 3;
        } else {
            return undefined;
        }
    }

    /***
     * Generates a shortened location string. iE from [ward,room,bed]: "ward 1, ward 1 room 2, ward 1 room 2 bed 3" to "ward 1, room 2, bed 3"
     * @param patient the patient to get the locations from
     * @constructor
     */
    public static ShortenLocations(patient: PatientItem): string {
        const ward = patient.ward || '';
        let room = patient.room || '';

        if (ward && room) {
            if (room.startsWith(ward))
                room = room.substr(ward.length).trim();

            let bed = patient.bed || '';
            if (bed) {
                if (bed.startsWith(ward))
                    bed = bed.substr(ward.length).trim();
                if (bed.startsWith(room))
                    bed = bed.substr(room.length);

                return [ward, room, bed].join(', ');
            } else {
                return [ward, room].join(', ');
            }
        } else {
            return ward || room;
        }
    }
}

export class Token {
    type: TokenType = TokenType.unknown;
    value: string = "";
    start: number = -1;
    tokenStartChar: string = undefined;

    constructor(startIndex: number) {
        this.start = startIndex;
    }

    public static ParseString(s: string): Token[] {
        let result: Token[] = [];
        let curIdx = 0;
        let inToken = false;
        let curToken = new Token(0);

        let newToken = function () {
            result.push(NitTools.Clone(curToken));

            curIdx++;
            curToken = new Token(curIdx);
            curToken.tokenStartChar = s[curIdx];
        };

        let findMatch = function (chr) {
            let sRest = s.substr(curIdx + 1);
            let ende = curIdx + sRest.indexOf(chr) + 1;
            curToken.type = TokenType.string;
            curToken.value = s.substr(curIdx, ende + 1);
            curIdx = ende;
        };

        while (curIdx < s.length) {
            let char = s[curIdx === 0 ? 0 : curIdx + 1];
            switch (char) {
                case "'":
                case '"':
                    findMatch(char);
                    newToken();
                    break;
                case "+":
                case "-":
                case "*":
                case "/":
                    curToken.type = TokenType.operator;
                    curToken.value = s[curIdx + 1];
                    newToken();
                    break;
                case " ":
                    curToken.type = TokenType.whitespace;
                    curToken.value = s[curIdx + 1];
                    newToken();
                    break;
                case ".":
                    curToken.type = TokenType.divider;
                    curToken.value = s[curIdx + 1];
                    newToken();
                    break;
                case "(":
                case "[":
                case "{":
                    curToken.type = TokenType.bracket;
                    let matchingBracket = ")";
                    if (char === "[") matchingBracket = "]";
                    else if (char === "{") matchingBracket = "}";
                    findMatch(matchingBracket);
                    newToken();
                    break;
                default:
                    curToken.tokenStartChar = s[curIdx + 1];
                    curToken.value += s[curIdx];
                    curIdx++;
                    break;
            }
        }

        result.push(curToken);
        return result;
    }
}

export enum TokenType {
    divider = "divider",
    string = "string", // when in " or '
    number = "number", // when in 0..9
    operator = "operator", // +-*/
    bracket = "bracket", // when in [{(
    other = "other",
    whitespace = "whitespace",
    unknown = "unknown", // not yet determined
}
