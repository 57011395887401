import { NitTools } from "./NursitTools";

export class HumanName implements fhir3.HumanName {

    public static GetText(name): string {
        if (NitTools.IsArray(name)) {
            name = name.find(o=>o.use === "official") || name.find(o=>o.use === "usual") || name[0];
        }

        if (!name) return '';

        if (name.text && name.text.length > 3) return name.text;

        let result = name.family||'';
        if (name.given) {
            let given = name.given?.join(' ');

            result += ', ' + given;
        }

        if (name.prefix) {
            result = name.prefix.join(' ') + " " + result;
        }

        if (name.suffix) {
            result += ", " + name.suffix.join(' ');
        }

        return result ? result.trim() : '';
    }

    public static GetDiv(names: any[]): string {
        if (!names || names.length === 0) return '';
        let resultArray: string[] = [];

        // name to string into resultArray
        names.forEach(name => {
            let s = HumanName.GetText(name);
            if (s && s.length > 3) {
                resultArray.push(s);
            }
        });

        let result = '<div xmlns="http://www.w3.org/1999/xhtml">'; // '<div xmlns="http://www.w3.org/1999/xhtml">';
        resultArray.forEach(name => {
            // result += `<div>${name}</div>\n`;
            if (name) {
                result += `${name}\n`;
            }
        });

        result += '</div>';

        return result ? result.trim() : '';
    }
}
