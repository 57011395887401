import {Reason} from "./reasonList";
import {ReasonCondition} from "./reasonCondition";

export class ReasonList {
    static create() {
        let FReasonsList: Reason[] = [];

        let desc = 'Abwehrverhalten/Widerstände bei der Körperpflege ';
        desc = desc + 'Kennzeichen: Setzt (Mobilisierungs-)Maßnahmen bei der Körperpflege Widerstände entgegen; ' +
            'schreit, schlägt, beschimpft das Pflegepersonal bei der Ganzkörperwaschung, lehnt die Körperpflege verbal/nonverbal ab ';
        let reason = new Reason('A: Körperpflege', 'G1.1', desc, ['A1', 'A3']);
        reason.conditionList.push(new ReasonCondition('07_06=12;07_06=14', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Ablauf der Körperpflege ist dem Patienten nicht bekannt ' +
            'Kennzeichen: Unfähigkeit, die Körperpflege selbstständig und strukturiert durchzuführen; ' +
            'Trugwahrnehmungen, Gebrauchsgegenstände der Körperpflege können nicht adäquat eingesetzt werden, ' +
            'fehlende Eigeninitiative, die Körperpflege durchzuführen ';
        reason = new Reason('A: Körperpflege', 'G1.2', desc, ['A1', 'A3']);
        reason.conditionList.push(new ReasonCondition('06_05=01;06_05=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Schwere kognitive Funktionseinschränkung (ICD-10-GM-Kode aus U51.2-) ' +
            'Erweiterter Barthel-Index 0-15 Punkte oder kognitiver FIM 5-10 Punkte oder MMSE 0-16 Punkte liegt vor ';
        reason = new Reason('A: Körperpflege', 'G1.3', desc, ['A1', 'A3']);
        reason.conditionList.push(new ReasonCondition('00_90_01_30=02', ''));
        FReasonsList.push(reason);

        desc = 'Extreme Schmerzzustände, die sich auf die Körperpflegeaktivitäten auswirken ' +
            'Kennzeichen: Stöhnt, weint, jammert, grimassiert, wehrt ab bei der Körperpflege, äußert verbal stärkste Schmerzen ';
        reason = new Reason('A: Körperpflege', 'G4', desc, ['A1', 'A3']);
        reason.conditionList.push(new ReasonCondition('10_04=11;10_04=41', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. ' +
            'Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbstständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- mindestens 3 unterschiedliche Zu- und/oder Ableitungssysteme und (inkl. Beatmung) ';
        reason = new Reason('A: Körperpflege', 'G5.1', desc, ['A1', 'A4']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_14=02', '')); /* Frage an Paul: Wie setzen wir das um? oder reason.conditionList.push(new ReasonCondition('03_18=01;04_09=01;04_10=01','und'));*/
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. ' +
            'Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbstständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- BMI mind. 35 kg/qm ';
        reason = new Reason('A: Körperpflege', 'G5.2', desc, ['A1', 'A4']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_05=>35', ''));
        reason.conditionList.push(new ReasonCondition('00_06=04', ''));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. ' +
            'Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbstständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- krankheitsbedingte Risiken wie Wirbelsäuleninstabilität ';
        reason = new Reason('A: Körperpflege', 'G5.3', desc, ['A1', 'A4']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_07=02', ''));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. ' +
            'Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbstständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- Extensionsbehandlung und/oder Behandlung mit Körpergipsschale, die eine extreme Bewegungseinschränkung mit sich bringen';
        reason = new Reason('A: Körperpflege', 'G5.4', desc, ['A1', 'A4']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_02=02', ''));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. ' +
            'Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbstständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- Rumpforthese/ Fixateur/ Armabduktionsschiene bei Querschnittlähmung';
        reason = new Reason('A: Körperpflege', 'G5.5', desc, ['A1', 'A4']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_15=02', ''));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. ' +
            'Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbstständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- Prothese/Orthese der unteren Extremität(en)/Stützkorsett/Rumpfwickel';
        reason = new Reason('A: Körperpflege', 'G5.6', desc, ['A1', 'A4']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_01=02', ''));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. ' +
            'Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbstständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- Ruhigstellung oder Fehlen von mindestens zwei Extremitäten';
        reason = new Reason('A: Körperpflege', 'G5.7', desc, ['A1', 'A4']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_03=02', ''));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbstständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor:'
            + ' - ausgeprägte Spastik/ Kontrakturen/ Rumpfataxie';
        reason = new Reason('A: Körperpflege', 'G5.8', desc, ['A1', 'A4']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_05=02', ''));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbstständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor:'
            + ' - ausgeprägte Lähmung (Hemiplegie - Unfähigkeit, die Extremitäten einer Körperseite selbständig zu bewegen, Paraplegie/-parese, Tetraplegie/-parese ';
        reason = new Reason('A: Körperpflege', 'G5.9', desc, ['A1', 'A4']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_04=02', ''));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. Kennzeichen: Fehlende Fähigkeitoder Verbot aus medizinischen Gründen, sich selbstständig im Bett zu drehen, (aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor:'
            + ' - fehlende Kraft zur Eigenbewegung im Rumpf und Beckenbereich';
        reason = new Reason('A: Körperpflege', 'G5.10', desc, ['A1', 'A4']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('01_09=01', ''));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbstständig im Bett zu drehen, (aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor:'
            + ' - mindestens 3 sekundär heilende Wunden (z.B. Dekubitus Grad/Kategorie 3) und/oder großflächige Wunde(n) (> 40 cm2)';
        reason = new Reason('A: Körperpflege', 'G5.11', desc);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_55=02', ''));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Volle Abhängigkeit bei der Körperpflege bei bestehender erheblicher Beeinträchtigung der Atemsituation und/ oder Herz-Kreislauf-Stiuation: ' +
            'Kann/darf sich bei verminderter/instabiler Herz-/Kreislauf- und/oder Atemsituation bei der Körperpflege nicht anstrengen, Belastungsintoleranz bei der Körperpflege';
        reason = new Reason('A: Körperpflege', 'G7', desc, ['A4']);
        reason.conditionList.push(new ReasonCondition('00_90_01_33=02', ''));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Starkes Schwitzen und/oder Erbrechen und/oder Einnässen/-stuhlen und/oder Kotschmieren/-essen, das eine(n) Wäschewechsel/Körperpflege erfordert';
        reason = new Reason('A: Körperpflege', 'G9', desc, ['A2']);
        reason.conditionList.push(new ReasonCondition('04_08=01;00_90_01_19=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Anlässe für therapeutische Ganzkörperwaschung bei einem Selbstfürsorgedefizit Körperpflege in Verbindung mit einem der aufgeführten Punkte:'
            + '- Beeinträchtigte Orientierung';
        reason = new Reason('A: Körperpflege', 'G10.1', desc, ['A3']);
        reason.conditionList.push(new ReasonCondition('06_02=01;06_02=02;06_02=03', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Anlässe für therapeutische Ganzkörperwaschung bei einem Selbstfürsorgedefizit Körperpflege in Verbindung mit einem der aufgeführten Punkte:'
            + '- Beeinträchtigte Wahrnehmung';
        reason = new Reason('A: Körperpflege', 'G10.5', desc, ['A3']);
        reason.conditionList.push(new ReasonCondition('10_01=01;10_01=02;10_01=03', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Anlässe für therapeutische Ganzkörperwaschung bei einem Selbstfürsorgedefizit Körperpflege in Verbindung mit einem der aufgeführten Punkte:'
            + ' -pathologische Bewegungsabläufe, Freezing (motorische Blockade)';
        reason = new Reason('A: Körperpflege', 'G10.2', desc, ['A3']);
        reason.conditionList.push(new ReasonCondition('00_90_01_06=02', ''));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Anlässe für therapeutische Ganzkörperwaschung bei einem Selbstfürsorgedefizit Körperpflege in Verbindung mit einem der aufgeführten Punkte:'
            + ' - vorhandene Spastik, Rumpfataxien';
        reason = new Reason('A: Körperpflege', 'G10.3', desc, ['A3']);
        reason.conditionList.push(new ReasonCondition('00_90_01_05=02', ''));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Anlässe für therapeutische Ganzkörperwaschung bei einem Selbstfürsorgedefizit Körperpflege in Verbindung mit einem der aufgeführten Punkte:'
            + ' - ausgeprägte Fatigue in der schwersten Ausprägung entsprechend Assessment';
        reason = new Reason('A: Körperpflege', 'G10.4', desc, ['A3']);
        reason.conditionList.push(new ReasonCondition('01_09=01', ''));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('05_02=01;05_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Volle Abhängigkeit bei der Körperpflege. Kennzeichen: Fehlende Fähigkeit, den Körper selbstständig zu waschen, abzutrocknen und die Mund-, Haar-, Hautpflege durchzuführen UND ein Grund für hohen pflegerischen Aufwand:'
            + '- Pflegemaßnahmen im Rahmen der (Umkehr-)Isolation';
        reason = new Reason('A: Körperpflege', 'G11.1', desc, ['A5', 'A6', 'A7']);
        reason.conditionList.push(new ReasonCondition('05_01=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_11=02', ''));
        FReasonsList.push(reason);

        desc = 'Volle Abhängigkeit bei der Körperpflege. Kennzeichen: Fehlende Fähigkeit, den Körper selbstständig zu waschen, abzutrocknen und die Mund-, Haar-, Hautpflege durchzuführen UND ein Grund für hohen pflegerischen Aufwand:'
            + '- Massive Veränderungen der Mundschleimhaut ';
        reason = new Reason('A: Körperpflege', 'G11.2', desc, ['A5', 'A6', 'A7']);
        reason.conditionList.push(new ReasonCondition('05_01=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_16=02', ''));
        FReasonsList.push(reason);

        desc = 'Volle Abhängigkeit bei der Körperpflege. Kennzeichen: Fehlende Fähigkeit, den Körper selbstständig zu waschen, abzutrocknen und die Mund-, Haar-, Hautpflege durchzuführen UND ein Grund für hohen pflegerischen Aufwand:'
            + '- hohes Pneumonierisiko ermittelt durch systematische Einschätzung';
        reason = new Reason('A: Körperpflege', 'G11.3', desc, ['A5', 'A6', 'A7']);
        reason.conditionList.push(new ReasonCondition('05_01=01', ''));
        reason.conditionList.push(new ReasonCondition('risk_pneu_sum>=2', ''));
        FReasonsList.push(reason);

        desc = 'Volle Abhängigkeit bei der Körperpflege. Kennzeichen: Fehlende Fähigkeit, den Körper selbstständig zu waschen, abzutrocknen und die Mund-, Haar-, Hautpflege durchzuführen UND ein Grund für hohen pflegerischen Aufwand:'
            + '- aufwendiges Tracheostoma';
        reason = new Reason('A: Körperpflege', 'G11.4', desc);
        reason.conditionList.push(new ReasonCondition('05_01=01', ''));
        reason.conditionList.push(new ReasonCondition('09_06=01', ''));
        FReasonsList.push(reason);

        desc = 'Tetraplegie mit fehlender Körperbalance/ fehlender Rumpfstabilitat Kennzeichen: kippt beim Sitzen zur Seite/nach vorne';
        reason = new Reason('A: Körperpflege', 'G12', desc, ['A8']);
        reason.conditionList.push(new ReasonCondition('00_90_01_17=02', ''));
        reason.conditionList.push(new ReasonCondition('05_01=01;05_01=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Kontinuierliche/massive Nahrungsverweigerung, Risiko der Mangelernährung Kennzeichen: Schiebt angebotene Nahrung weg, '
            + 'lehnt Nahrung verbal/nonverbal ab, fehlende(r) Wille/Einsicht, Nahrung zu sich zu nehmen, Mundschluss, Abwenden des Kopfes, '
            + 'Wegschlagen der Nahrung beim Versuch der Nahrungsverabreichung, extrem langsames Essen als Strategie der verminderten Nahrungsaufnahme, schluckt den Nahrungsbrei nicht selbstständig, Ausspucken von Nahrung';
        reason = new Reason('B: Ernährung', 'G1.1', desc, ['B1', 'B4']);
        reason.conditionList.push(new ReasonCondition('07_06=11;07_06=12;07_06=14', 'oder'));
        reason.conditionList.push(new ReasonCondition('risk_nrs=1', ''));
        reason.conditionList.push(new ReasonCondition('03_01=01;03_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('03_02=01;03_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Massives Verkennen der Nahrungssituation, Risiko der Mangelernährung. Kennzeichen: Fehlender Impuls zur '
            + 'Nahrungsaufnahme, kann Aufforderungen/Erklärungen im Zusammenhang mit der Nahrungsaufnahme nicht verstehen,Trugwahrnehmungen, schluckt den Nahrungsbrei nicht selbstständig';
        reason = new Reason('B: Ernährung', 'G1.2', desc, ['B1', 'B4']);
        reason.conditionList.push(new ReasonCondition('06_03=01;06_03=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('06_05=01;06_05=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('risk_nrs=1', ''));
        reason.conditionList.push(new ReasonCondition('03_01=01;03_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('03_02=01;03_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Schwere kognitive Funktionseinschränkung (ICD-10-GM-Kode aus U51.2-) '
            + 'Erweiterter Barthel-Index 0-15 Punkte oder kognitiver FIM 5-10 Punkte oder MMSE 0-16 Punkte liegt vor. ';
        reason = new Reason('B: Ernährung', 'G1.3', desc, ['B1', 'B4']);
        reason.conditionList.push(new ReasonCondition('00_90_01_30=02', ''));
        FReasonsList.push(reason);

        desc = 'Massiv verlangsamte/erschwerte Nahrungsaufnahme bei quantitativen Bewusstseinsveränderungen. '
            + 'Kennzeichen: Zeitverzögerte Reaktion auf Ansprache, schläft während der Nahrungsverabreichung immer wieder ein, Verlust der Fähigkeit, Nahrung selbstständig aufzunehmen';

        reason = new Reason('B: Ernährung', 'G2', desc, ['B1', 'B5']);
        reason.conditionList.push(new ReasonCondition('03_01=01;03_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('06_01=02;06_01=03', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, eine Sitzposition bei der Nahrungsaufnahme einzunehmen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, selbstständig in die Sitzposition zu gelangen, und rutscht ' +
            +'im Bett/Rollstuhl nach unten, asymmetrische Sitzhaltung, kippt beim Sitzen nach vorne (instabile Sitzhaltung) UND ein vorliegender Erschwernisfaktor: '
            + '- mindestens 3 unterschiedliche Zu- und/oder Ableitungssysteme (inkl. Beatmung)';

        reason = new Reason('B: Ernährung', 'G5.1', desc, ['B3']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_14=02', '')); /* Frage an Paul: Wie programmatisch umsetzen? oder reason.conditionList.push(new ReasonCondition('03_18=01;04_09=01;04_10=01','und'));*/
        reason.conditionList.push(new ReasonCondition('01_03=01;01_03=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('03_01=01;03_01=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, eine Sitzposition bei der Nahrungsaufnahme einzunehmen. '
            + 'Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, selbstständig in die Sitzposition zu gelangen, und rutscht '
            + 'im Bett/Rollstuhl nach unten, asymmetrische Sitzhaltung, kippt beim Sitzen nach vorne (instabile Sitzhaltung) UND ein vorliegender Erschwernisfaktor: '
            + '- BMI von mindestens 35 kg/m2';
        reason = new Reason('B: Ernährung', 'G5.2', desc, ['B3']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_05=>35', ''));
        reason.conditionList.push(new ReasonCondition('00_06=04', ''));
        reason.conditionList.push(new ReasonCondition('01_03=01;01_03=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('03_01=01;03_01=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, eine Sitzposition bei der Nahrungsaufnahme einzunehmen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, selbstständig in die Sitzposition zu gelangen, und rutscht ' +
            'im Bett/Rollstuhl nach unten, asymmetrische Sitzhaltung, kippt beim Sitzen nach vorne (instabile Sitzhaltung) UND ein vorliegender Erschwernisfaktor: ' +
            '- krankheitsbedingte Risiken wie Wirbelsäuleninstabilität';
        reason = new Reason('B: Ernährung', 'G5.3', desc, ['B3']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_07=02', ''));
        reason.conditionList.push(new ReasonCondition('01_03=01;01_03=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('03_01=01;03_01=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, eine Sitzposition bei der Nahrungsaufnahme einzunehmen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, selbstständig in die Sitzposition zu gelangen, und rutscht ' +
            'im Bett/Rollstuhl nach unten, asymmetrische Sitzhaltung, kippt beim Sitzen nach vorne (instabile Sitzhaltung) UND ein vorliegender Erschwernisfaktor: ' +
            '- Extensionsbehandlung und/oder Behandlung mit Körpergipsschale, die eine extreme Bewegungseinschränkung mit sich bringen ';
        reason = new Reason('B: Ernährung', 'G5.4', desc, ['B3']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_02=02', ''));
        reason.conditionList.push(new ReasonCondition('01_03=01;01_03=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('03_01=01;03_01=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, eine Sitzposition bei der Nahrungsaufnahme einzunehmen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, selbstständig in die Sitzposition zu gelangen, und rutscht ' +
            'im Bett/Rollstuhl nach unten, asymmetrische Sitzhaltung, kippt beim Sitzen nach vorne (instabile Sitzhaltung) UND ein vorliegender Erschwernisfaktor: ' +
            '- Rumpforthese/ Fixateur/ Armabduktionsschiene bei Querschnittlähmung';
        reason = new Reason('B: Ernährung', 'G5.5', desc, ['B3']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_15=02', ''));
        reason.conditionList.push(new ReasonCondition('01_03=01;01_03=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('03_01=01;03_01=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, eine Sitzposition bei der Nahrungsaufnahme einzunehmen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, selbstständig in die Sitzposition zu gelangen, und rutscht ' +
            'im Bett/Rollstuhl nach unten, asymmetrische Sitzhaltung, kippt beim Sitzen nach vorne (instabile Sitzhaltung) UND ein vorliegender Erschwernisfaktor: ' +
            '- Ruhigstellung/ Fehlen von mindestens zwei Extremitäten';
        reason = new Reason('B: Ernährung', 'G5.6', desc, ['B3']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_03=02', ''));
        reason.conditionList.push(new ReasonCondition('01_03=01;01_03=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('03_01=01;03_01=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, eine Sitzposition bei der Nahrungsaufnahme einzunehmen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, selbstständig in die Sitzposition zu gelangen, und rutscht ' +
            'im Bett/Rollstuhl nach unten, asymmetrische Sitzhaltung, kippt beim Sitzen nach vorne (instabile Sitzhaltung) UND ein vorliegender Erschwernisfaktor: ' +
            '- ausgeprägte Spastik/ Kontrakturen/ Rumpfataxien';
        reason = new Reason('B: Ernährung', 'G5.7', desc, ['B3']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_05=02', ''));
        reason.conditionList.push(new ReasonCondition('01_03=01;01_03=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('03_01=01;03_01=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, eine Sitzposition bei der Nahrungsaufnahme einzunehmen. Kennzeichen: Fehlende Fähigkeit, selbstständig in die Sitzposition zu gelangen, und rutscht ' +
            'im Bett/Rollstuhl nach unten, asymmetrische Sitzhaltung, kippt beim Sitzen nach vorne (instabile Sitzhaltung) UND ein vorliegender Erschwernisfaktor: ' +
            '- ausgeprägte Lähmung (Hemiplegie - Unfähigkeit, die Extremitäten einer Körperseite selbstständig zu bewegen, Paraplegie/-parese, Tetraplegie/-parese)';
        reason = new Reason('B: Ernährung', 'G5.8', desc, ['B3']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_04=02', ''));
        reason.conditionList.push(new ReasonCondition('01_03=01;01_03=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('03_01=01;03_01=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, eine Sitzposition bei der Nahrungsaufnahme einzunehmen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, selbstständig in die Sitzposition zu gelangen, und rutscht ' +
            'im Bett/Rollstuhl nach unten, asymmetrische Sitzhaltung, kippt beim Sitzen nach vorne (instabile Sitzhaltung) UND ein vorliegender Erschwernisfaktor: ' +
            '- fehlende Kraft zur Eigenbewegung im Rumpf und Beckenbereich';
        reason = new Reason('B: Ernährung', 'G5.9', desc, ['B3']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('01_09=01', ''));
        reason.conditionList.push(new ReasonCondition('01_03=01;01_03=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('03_01=01;03_01=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, eine Sitzposition bei der Nahrungsaufnahme einzunehmen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, selbstständig in die Sitzposition zu gelangen, und rutscht ' +
            'im Bett/Rollstuhl nach unten, asymmetrische Sitzhaltung, kippt beim Sitzen nach vorne (instabile Sitzhaltung) UND ein vorliegender Erschwernisfaktor: ' +
            '- mindestens 3 sekundär heilende Wunden (z.B. Dekubitus Grad/Kategorie 3) und/oder großflächige Wunde(n) (> 40 cm2)';
        reason = new Reason('B: Ernährung', 'G5.10', desc, ['B3']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('0_90_01_55=02', ''));
        reason.conditionList.push(new ReasonCondition('01_03=01;01_03=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('03_01=01;03_01=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, eine Sitzposition bei der Nahrungsaufnahme einzunehmen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, selbstständig in die Sitzposition zu gelangen, und rutscht ' +
            'im Bett/Rollstuhl nach unten, asymmetrische Sitzhaltung, kippt beim Sitzen nach vorne (instabile Sitzhaltung) UND ein vorliegender Erschwernisfaktor: ' +
            '- Prothese/ Ortheseder unteren Extremität(en)/ Stützkorsett/ Rumpfwickel';
        reason = new Reason('B: Ernährung', 'G5.11', desc, ['B3']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_01=02', ''));
        reason.conditionList.push(new ReasonCondition('01_03=01;01_03=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('03_01=01;03_01=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Fehlende Fähigkeit sich zur Nahrungsaufnahme an den Tisch zu setzen. Kennzeichen: Schwere Beeinträchtigung '
            + 'von liegender Körperposition zum Sitzen zu gelangen UND vom Sitzen zum Stand zu gelangen UND erhebliche ' +
            'Beeinträchtigung des Gehens auf ebener Fläche wie Unfähigkeit/ Unsicherheit das Körpergewicht im Stand selbstständig zu tragen, Veränderungen des Gangbildes';
        reason = new Reason('B: Ernährung', 'G6', desc, ['B3']);
        reason.conditionList.push(new ReasonCondition('01_02=02', ''));
        reason.conditionList.push(new ReasonCondition('01_06=01', ''));
        reason.conditionList.push(new ReasonCondition('03_01=01;03_01=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Kau-/ Schluckstörungen mit starken Auswirkungen auf die Nahrungsaufnahme. Kennzeichen: Hustet nach dem Schlucken, Nahrungsreste verbleiben nach dem Schlucken in der Wangentasche, '
            + 'Zungenstoß, Gefühl, dass Nahrung im Schlund hängen bleibt, Regurgitation von Speisenbrei, veränderte Schluckphasen, inkompletter/ '
            + 'fehlender Lippen-/ Mundschluss, pathologische Kau-/ Kieferbewegung, herabgesetzte Sensibilitat im Mund- und Rachenbereich, beeintra¨chtigter Schluckreflex, Funktionssto¨rung der Kehlkopfhebung, Nahrungsreste dringen aus dem Tracheostoma';
        reason = new Reason('B: Ernährung', 'G8', desc, ['B5', 'B4']);
        reason.conditionList.push(new ReasonCondition('03_01=01;03_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('03_10=01', ''));
        FReasonsList.push(reason);

        desc = 'Vorliegende schwere Mangelernährung.'
            + 'Kennzeichen:  Gewichtsverlust größer 5% innerhalb von 1 Monaten, BMI kleiner 18,5 kg/m²  bei Erwachsenen bis 65 Jahren und kleiner 20 kg/m²  bei Erw. größer 65 Jahren, Sakropenie, hervortretende Knochen';
        reason = new Reason('B: Ernährung', 'G10', desc, ['B5', 'B1']);
        reason.conditionList.push(new ReasonCondition('risk_nrs=1', ''));
        FReasonsList.push(reason);

        desc = 'Fehlende Fähigkeit, selbstständig Nahrung/Flüssigkeit aufzunehmen, da die Abläufe der Nahrungsaufnahme nicht bekannt sind. Kennzeichen: Kann die Gebrauchsgegenstände zur Nahrungsaufnahme nicht nutzen';
        reason = new Reason('B: Ernährung', 'G11.1', desc, ['B4']);
        reason.conditionList.push(new ReasonCondition('03_01=01', ''));
        reason.conditionList.push(new ReasonCondition('06_05=01;06_05=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Massive  Bewegungsstörung, welche die selbstständige Nahrungs-/Flüssigkeits- aufnahme  verhindert ' +
            'Kennzeichen: ausgeprägter Tremor, Zahnradphänomen, Freezing, supranukleare Blickparese';
        reason = new Reason('B: Ernährung', 'G11.2', desc, ['B4']);
        reason.conditionList.push(new ReasonCondition('00_09_01_50=02', ''));
        FReasonsList.push(reason);

        desc = 'Massive Bewegungseinschränkung beider Arme, welche eine volle Abhängigkeit bei der Nahrungs-/Flüssigkeitsaufnahme zur Folge hat'
            + 'Kennzeichen: (Gips-)Verbände und/oder Schienungen beider Arme mit Händen,'
            + '(Gips-)Verbände und/oder Schienungen der Arme über die Ellenbogengelenke und/oder'
            + 'Schultergelenke hinweg';
        reason = new Reason('B: Ernährung', 'G11.3', desc, ['B4']);
        reason.conditionList.push(new ReasonCondition('00_09_01_51=02', ''));
        FReasonsList.push(reason);

        desc = 'Volle Abhängigkeit bei der Nahrungs- und Flüssigkeitsaufnahme bei Tetraplegie, die ein häufiges ' +
            'Angebot von Nahrung und Flüssigkeit erfordert. Kennzeichen: Kann keine Nahrung selbstständig in den Mund nehmen, zum Mund führen UND kann die Flüssigkeit nicht mit dem Trinkhalm/anderen Hilfsmitteln selbstständig aufnehmen';
        reason = new Reason('B: Ernährung', 'G12', desc, ['B1', 'B5', 'B4']);
        reason.conditionList.push(new ReasonCondition('00_09_01_17=02', ''));
        reason.conditionList.push(new ReasonCondition('03_01=01', ''));
        reason.conditionList.push(new ReasonCondition('03_05=01', ''));
        FReasonsList.push(reason);

        desc = 'Verkennt die Ausscheidungssituation in Folge massiver kognitiver Beeinträchtigungen. Kennzeichen: ins Zimmer urinieren, versteckt Ausscheidungen, kennt die normalen Abläufe nicht, die zur Ausscheidung auf der Toilette erforderlich sind';
        reason = new Reason('C: Ausscheidung', 'G1.1', desc, ['C1']);
        reason.conditionList.push(new ReasonCondition('06_03=01;06_03=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('06_05=01;06_05=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('04_01=01;04_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('04_02=01;04_02=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('06_02=01;06_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Verkennt die Ausscheidungssituation in Folge massiver kognitiver Beeinträchtigungen. Kennzeichen:  Trugwahrnehmungen, ' +
            'versteckt Ausscheidungen, kennt die normalen Abläufe, die zur Ausscheidung auf der Toilette erforderlich sind, nicht';
        reason = new Reason('C: Ausscheidung', 'G1.2', desc, ['C1']);
        reason.conditionList.push(new ReasonCondition('06_03=01;06_03=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('06_05=01;06_05=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('04_03=01;04_03=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('04_04=01;04_04=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('06_02=01;06_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Verkennt die Ausscheidungssituation in Folge massiver kognitiver Beeinträchtigungen. Kennzeichen: Kot schmieren/- essen';
        reason = new Reason('C: Ausscheidung', 'G1.3', desc, ['C1']);
        reason.conditionList.push(new ReasonCondition('00_09_01_19=02', ''));
        FReasonsList.push(reason);

        desc = 'Schwere kognitive Funktionseinschränkung (ICD-10-GM-Kode aus U51.2-) ' +
            'Erweiterter Barthel-Index 0-15 Punkte oder kognitiver FIM 5-10 Punkte oder MMSE 0-16 Punkte liegt vor ';
        reason = new Reason('C: Ausscheidung', 'G1.4', desc, ['C1']);
        reason.conditionList.push(new ReasonCondition('00_09_01_30=02', ''));
        FReasonsList.push(reason);

        desc = 'Extreme Schmerzzustände bei voller Unterstützung im Rahmen der Ausscheidungsaktivitäten. ' +
            'Kennzeichen: Stöhnt, weint, jammert, grimmasiert, wehrt ab beim Umlagern/Mobilisieren, äußert verbal stärkste Schmerzen.';
        reason = new Reason('C: Ausscheidung', 'G4', desc, ['C1', 'C3']);
        reason.conditionList.push(new ReasonCondition('10_04=11;10_04=41', 'oder'));
        reason.conditionList.push(new ReasonCondition('04_01=01', ''));
        reason.conditionList.push(new ReasonCondition('04_03=01', ''));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, das Gesäß zum Unterschieben der/des Bettschüssel/Steckbeckens anzuheben. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, eine Brücke zu machen oder sich auf die Seite zu drehen UND ein vorliegender Erschwernisfaktor: ' +
            '- mindestens 3 unterschiedliche Zu- und/oder Ableitungssysteme (inkl. Beatmung)';
        reason = new Reason('C: Ausscheidung', 'G5.1', desc);
        reason.conditionList.push(new ReasonCondition('01_02=01;01_02=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('00_09_01_14=02', '')); /* Frage an Paul: Wie umsetzen?  oder reason.conditionList.push(new ReasonCondition('03_18=01;04_09=01;04_10=01','und'));*/
        reason.conditionList.push(new ReasonCondition('04_01=01;04_01=02;04_03=01;04_03=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, das Gesäß zum Unterschieben der/des Bettschüssel/Steckbeckens anzuheben. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, eine Brücke zu machen oder sich auf die Seite zu drehen UND ein vorliegender Erschwernisfaktor: ' +
            '- BMI von 35 kg/qm ';
        reason = new Reason('C: Ausscheidung', 'G5.2', desc, ['C3']);
        reason.conditionList.push(new ReasonCondition('01_02=01;01_02=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('00_05=>35', ''));
        reason.conditionList.push(new ReasonCondition('00_06=04', ''));
        reason.conditionList.push(new ReasonCondition('04_01=01;04_01=02;04_03=01;04_03=02', 'oder'));
        FReasonsList.push(reason);


        desc = 'Unfähigkeit, das Gesäß zum Unterschieben der/des Bettschüssel/Steckbeckens anzuheben. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, eine Brücke zu machen oder sich auf die Seite zu drehen UND ein vorliegender Erschwernisfaktor: ' +
            '- krankheitsbedingte Risiken wie Wirbelsäuleninstabilität';
        reason = new Reason('C: Ausscheidung', 'G5.3', desc, ['C3']);
        reason.conditionList.push(new ReasonCondition('01_02=01;01_02=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('00_09_01_07=02', ''));
        reason.conditionList.push(new ReasonCondition('04_01=01;04_01=02;04_03=01;04_03=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, das Gesäß zum Unterschieben der/des Bettschüssel/Steckbeckens anzuheben. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen,, eine Brücke zu machen oder sich auf die Seite zu drehen UND ein vorliegender Erschwernisfaktor: ' +
            '- Extensionsbehandlung und/oder Behandlung mit Körpergipsschale, die eine extreme Bewegungseinschränkung mit sich bringen';
        reason = new Reason('C: Ausscheidung', 'G5.4', desc, ['C3']);
        reason.conditionList.push(new ReasonCondition('01_02=01;01_02=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('00_09_01_02=02', ''));
        reason.conditionList.push(new ReasonCondition('04_01=01;04_01=02;04_03=01;04_03=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, das Gesäß zum Unterschieben der/des Bettschüssel/Steckbeckens anzuheben. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, eine Brücke zu machen oder sich auf die Seite zu drehen UND ein vorliegender Erschwernisfaktor: ' +
            '- Rumpforthese/ Fixateur/ Armabduktionsschiene bei Querschnittlähmung';
        reason = new Reason('C: Ausscheidung', 'G5.5', desc, ['C3']);
        reason.conditionList.push(new ReasonCondition('01_02=01;01_02=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('00_09_01_15=02', ''));
        reason.conditionList.push(new ReasonCondition('04_01=01;04_01=02;04_03=01;04_03=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, das Gesäß zum Unterschieben der/des Bettschüssel/Steckbeckens anzuheben. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen,, eine Brücke zu machen oder sich auf die Seite zu drehen UND ein vorliegender Erschwernisfaktor: ' +
            '- Ruhigstellung/ Fehlen von mindestens zwei Extremitäten';
        reason = new Reason('C: Ausscheidung', 'G5.6', desc, ['C3']);
        reason.conditionList.push(new ReasonCondition('01_02=01;01_02=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('00_09_01_03=02', ''));
        reason.conditionList.push(new ReasonCondition('04_01=01;04_01=02;04_03=01;04_03=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, das Gesäß zum Unterschieben der/des Bettschüssel/Steckbeckens anzuheben. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, eine Brücke zu machen oder sich auf die Seite zu drehen UND ein vorliegender Erschwernisfaktor: ' +
            '- ausgeprägte Spastik/ Kontrakturen/ Rumpfataxien';
        reason = new Reason('C: Ausscheidung', 'G5.7', desc, ['C3']);
        reason.conditionList.push(new ReasonCondition('01_02=01;01_02=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('00_09_01_05=02', ''));
        reason.conditionList.push(new ReasonCondition('04_01=01;04_01=02;04_03=01;04_03=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, das Gesäß zum Unterschieben der/des Bettschüssel/Steckbeckens anzuheben. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, eine Brücke zu machen oder sich auf die Seite zu drehen UND ein vorliegender Erschwernisfaktor: ' +
            '- ausgeprägte Lähmung (Hemiplegie/- Unfähigkeit, die Extremitäten einer Körperseite selbstständig zu bewegen, Paraplegie/-parese, Tetraplegie/-parese)';
        reason = new Reason('C: Ausscheidung', 'G5.8', desc, ['C3']);
        reason.conditionList.push(new ReasonCondition('01_02=01;01_02=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('00_09_01_04=02', ''));
        reason.conditionList.push(new ReasonCondition('04_01=01;04_01=02;04_03=01;04_03=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, das Gesäß zum Unterschieben der/des Bettschüssel/Steckbeckens anzuheben. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, eine Brücke zu machen oder sich auf die Seite zu drehen UND ein vorliegender Erschwernisfaktor: ' +
            '- fehlende Kraft zur Eigenbewegung im Rumpf und Beckenbereich';
        reason = new Reason('C: Ausscheidung', 'G5.9', desc, ['C3']);
        reason.conditionList.push(new ReasonCondition('01_02=01;01_02=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('01_09=01', ''));
        reason.conditionList.push(new ReasonCondition('04_01=01;04_01=02;04_03=01;04_03=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, das Gesäß zum Unterschieben der/des Bettschüssel/Steckbeckens anzuheben. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, eine Brücke zu machen oder sich auf die Seite zu drehen UND ein vorliegender Erschwernisfaktor: ' +
            '- mindestens 3 sekundär heilende Wunden (z.B. Dekubitus Grad/ Kategorie 3)  und/oder großflächige  Wunde(n) (> 40 cm2)';
        reason = new Reason('C: Ausscheidung', 'G5.10', desc, ['C3']);
        reason.conditionList.push(new ReasonCondition('01_02=01;01_02=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('00_09_01_55=02', ''));
        reason.conditionList.push(new ReasonCondition('04_01=01;04_01=02;04_03=01;04_03=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Unfähigkeit, das Gesäß zum Unterschieben der/des Bettschüssel/Steckbeckens anzuheben. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, eine Brücke zu machen oder sich auf die Seite zu drehen UND ein vorliegender Erschwernisfaktor: ' +
            '- Prothese/ Orthese der unteren Extremitäten/ Stützkorsett/ Rumpfwickel';
        reason = new Reason('C: Ausscheidung', 'G5.11', desc, ['C3']);
        reason.conditionList.push(new ReasonCondition('01_02=01;01_02=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('00_09_01_01=02', ''));
        reason.conditionList.push(new ReasonCondition('04_01=01;04_01=02;04_03=01;04_03=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Fehlende Fähigkeit selbständig auf die Toilette zu gehen oder mit dem Rollstuhl zur Toilette zu fahren. ' +
            'Kennzeichen: Schwere Beeinträchtigung von liegender Körperposition zum Sitzen zu gelangen und vom Sitzen zum Stand zu ' +
            'gelangen und erhebliche Beeinträchtigung des Gehens auf ebener Fläche wie Unfähigkeit/Unsicherheit das Körpergewicht im Stand selbständig zu tragen, Veränderungen des Gangbildes';
        reason = new Reason('C: Ausscheidung', 'G6', desc, ['C3', 'C1']);
        reason.conditionList.push(new ReasonCondition('01_02=02', ''));
        reason.conditionList.push(new ReasonCondition('01_06=01', ''));
        reason.conditionList.push(new ReasonCondition('04_01=01;04_01=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('04_03=01;04_03=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Vorliegen einer Harninkontinenz Kennzeichen: für die verschiedenen Inkontinenzformen sind dem Expertenstandard - DNQP aktuelle Ausgabe- zu entnehmen und zu dokumentieren.';
        reason = new Reason('C: Ausscheidung', 'G9', desc, ['']);
        reason.conditionList.push(new ReasonCondition('04_02=01;04_02=02;04_02=03', 'oder'));
        FReasonsList.push(reason);

        desc = 'Veränderte Miktionsfrequenz und volle Beeinträchtigung in der Selbstständigkeit der Miktion '
            + 'Kennzeichen: Fehlende Fähigkeit, selbstständig zur Toilette zu gehen, den Toilettenstuhl zu benutzen, die Bettschüssel/Steckbecken/Urinflasche selbstständig zu benutzen';
        reason = new Reason('C: Ausscheidung', 'G10.1', desc, ['C3']);
        reason.conditionList.push(new ReasonCondition('04_01=01', ''));
        reason.conditionList.push(new ReasonCondition('04_05=01', ''));
        FReasonsList.push(reason);

        desc = 'Veränderte Däfekationsfrequenz und volle Beeinträchtigung in der Selbstständigkeit der Defäkation. Kennzeichen: Fehlende Fähigkeit, selbstständig zur Toilette zu gehen, ' +
            ' den Toilettenstuhl zu benutzen, die Bettschüssel/Steckbecken/Urinflasche selbstständig zu benutzen';
        reason = new Reason('C: Ausscheidung', 'G10.2', desc, ['C3']);
        reason.conditionList.push(new ReasonCondition('04_03=01', ''));
        reason.conditionList.push(new ReasonCondition('04_05=01', ''));
        FReasonsList.push(reason);

        desc = 'Volle Abhängigkeit bei der Ausscheidung UND Ausgeprägte Obstipation oder andere Gründe die einen tägl. Einlauf UND/ODER rektales Ausräumen erfordern';
        reason = new Reason('C: Ausscheidung', 'G11.1', desc, ['C3']);
        reason.conditionList.push(new ReasonCondition('04_03=01', ''));
        reason.conditionList.push(new ReasonCondition('00_09_01_12=02', ''));
        FReasonsList.push(reason);

        desc = 'Volle Abhängigkeit bei der Ausscheidung UND Pflegemaßnahmen im Rahmen der (Umkehr-)Isolation';
        reason = new Reason('C: Ausscheidung', 'G11.2', desc, ['C3']);
        reason.conditionList.push(new ReasonCondition('04_01=01;04_03=01', ''));
        reason.conditionList.push(new ReasonCondition('00_09_01_11=02', ''));
        FReasonsList.push(reason);

        desc = 'Neurogene Darmfunktionsstärungen, die bei Paraplegie/-parese oder Tetraplegie/-parese ein spezielles Darmmanagement erfordern';
        reason = new Reason('C: Ausscheidung', 'G12', desc, ['C4']);
        reason.conditionList.push(new ReasonCondition('00_09_01_20=02', ''));
        FReasonsList.push(reason);

        desc = 'Abwehrverhalten/Widerstände beim Umlagern/Mobilisieren. Kennzeichen: Setzt (Mobilisierungs-) Maßnahmen Widerstände entgegen; schreit, schlägt, beschimpft das Personal bei der Umlagerung, lehnt die Lagerungs-/Mobilisierungsmaßnahmen verbal/nonverbal ab ';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G1.1', desc, ['D2', 'D4']);
        reason.conditionList.push(new ReasonCondition('07_06=12;07_06=14', 'oder'));
        reason.conditionList.push(new ReasonCondition('01_02=01;01_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Schwere kognitive Funktionseinschränkung (ICD-10-GM-Kode aus U51.2-) ' +
            'Erweiterter Barthel-Index 0-15 Punkte oder kognitiver FIM 5-10 Punkte oder MMSE 0-16 Punkte liegt vor ';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G1.3', desc, ['D2', 'D4']);
        reason.conditionList.push(new ReasonCondition('00_90_01_30=02', ''));
        FReasonsList.push(reason);

        desc = 'Extreme Schmerzzustände beim Lagern/Mobilisieren. Kennzeichen: Stöhnt, weint, jammert, grimmasiert, wehrt ab beim Lagern/Mobilisieren, äußert verbal stärkste Schmerzen';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G4', desc, ['D2']);
        reason.conditionList.push(new ReasonCondition('10_04=11;10_04=41', 'oder'));
        reason.conditionList.push(new ReasonCondition('01_02=01;01_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- mindestens 3 unterschiedliche Zu- und/oder Ableitungssysteme und (inkl. Beatmung)';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G5.1', desc, ['D2', 'D4', 'D5']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_14=02', '')); /* Frage an Paul: Wie umsetzen?  oder reason.conditionList.push(new ReasonCondition('03_18=01;04_09=01;04_10=01','und'));*/
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- BMI von 35 kg/qm ';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G5.2', desc, ['D2', 'D4', 'D5']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_06=04', ''));
        reason.conditionList.push(new ReasonCondition('00_05=>35', ''));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- krankheitsbedingte Risiken wie Wirbelsäuleninstabilität';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G5.3', desc, ['D2', 'D4', 'D5']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_07=02', ''));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- Extensionsbehandlung und/oder Behandlung mit Körpergipsschale, die eine extreme Bewegungseinschränkung mit sich bringen';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G5.4', desc, ['D2', 'D4', 'D5']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_02=02', ''));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- Rumpforthese/ Fixateur/ Armabduktionsschiene bei Querschnittlähmung';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G5.5', desc, ['D2', 'D4', 'D5']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_15=02', ''));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- Prothesen/ Orthesenversorgung der unteren Extremitäten/ Stützkorsett/ Rumpfwickel';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G5.6', desc, ['']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_01=02', ''));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- Ruhigstellung/ Fehlen von mindestens zwei Extremitäten';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G5.13', desc, ['']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_03=02', ''));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- ausgeprägte Spastik/ Kontraktur/ Rumpfataxie';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G5.7', desc, ['D2', 'D4', 'D5']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_05=02', ''));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- ausgeprägte Lähmung (Hemiplegie/ - Unfähigkeit, die Extremitäten einer Körperseite selbständig zu bewegen, Paraplegie/-parese, Tetraplegie/-parese)';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G5.8', desc, ['D2', 'D4', 'D5']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_04=02', ''));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- fehlende Kraft zur Eigenbewegung im Rumpf und Beckenbereich';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G5.9', desc, ['D2', 'D4', 'D5']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('01_09=01', ''));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- mindestens 3 sekundär heilende Wunden (z.B. Dekubitus Grad 3) und/oder großflächige Wunde(n) (>= 40cm2)';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G5.10', desc, ['']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_09_01_55=02', ''));
        FReasonsList.push(reason);

        desc = 'Verlust der Fähigkeit, den Positionswechsel im Bett durchzuführen. Kennzeichen: Fehlende Fähigkeit oder Verbot aus medizinischen Gründen, sich selbständig im Bett zu drehen, aktiv zu verrutschen, aufzusetzen UND ein vorliegender Erschwernisfaktor: ' +
            '- Pflegemaßnahmen im Rahmen der (Umkehr-)Isolation';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G5.12', desc, ['']);
        reason.conditionList.push(new ReasonCondition('01_02=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_11=02', ''));
        FReasonsList.push(reason);

        desc = 'Fehlende Fähigkeit einen Transfer durchzuführen und/oder selbstständig zu Gehen. ' +
            'Kennzeichen: Schwere Beeinträchtigung von liegender Körperposition zum Sitzen zu gelangen und vom Sitzen zum Stand zu ' +
            'gelangen UND erhebliche Beeinträchtigung des Gehens auf ebener Fläche wie Unfähigkeit/ Unsicherheit, das Körpergewicht im Stand selbstständig zu tragen, Veränderungen des Gangbildes';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G6', desc, ['D4', 'D5']);
        reason.conditionList.push(new ReasonCondition('01_02=01;01_02=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('01_06=01', ''));
        FReasonsList.push(reason);

        desc = 'Hohes Dekubitusrisiko'
            + 'Kennzeichen: hohes Dekubitusrisiko durch systematische Einschätzung nach aktuellem nationalen Expertenstandard (DNQP 2010)';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G10', desc, ['D1', 'D5']);
        reason.conditionList.push(new ReasonCondition('risk_deku_sum<=12;02_03=01', 'oder'));
        FReasonsList.push(reason);

        desc = 'Weglaufverhalten/ Hinlauftendenz.'
            + 'Kennzeichen: Verlässt die Station/das Zimmer ständig; findet nicht mehr in das Zimmer zurück, Umtriebigkeit und psychomotorische Unruhe';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G11.1', desc, ['D6']);
        reason.conditionList.push(new ReasonCondition('06_03=01;06_03=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('07_06=11;07_06=14', 'oder'));
        reason.conditionList.push(new ReasonCondition('01_01=03;01_01=04', 'oder'));
        reason.conditionList.push(new ReasonCondition('06_02=01;06_02=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Hohes Selbstgefährdungs-/-verletzungsrisiko. Kennzeichen: Trugwahrnehmung, erkennt Gefahren nicht, kann selbstgefährdende Situationen nicht einschätzen';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G11.2', desc, ['D6']);
        reason.conditionList.push(new ReasonCondition('06_05=01;06_05=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Hohes Selbstgefährdungs-/-verletzungsrisiko. Kennzeichen: steht trotz hoher Sturzgefährdung ohne Unterstützung selbstständig auf';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G11.3', desc, ['D6']);
        reason.conditionList.push(new ReasonCondition('01_02=03;01_02=04', 'oder'));
        reason.conditionList.push(new ReasonCondition('risk_sturz=01', ''));
        reason.conditionList.push(new ReasonCondition('06_05=01;06_05=02', 'oder'));

        desc = 'Hohes Selbstgefährdungs-/-verletzungsrisiko. Kennzeichen: Selbstverletzungsrisiko durch fehlende Lebensperspektive';
        reason = new Reason('D: Bewegen/Lagern/Mobilisation/Sicherheit ', 'G11.4', desc, ['D6']);
        reason.conditionList.push(new ReasonCondition('00_90_01_18=02', ''));
        FReasonsList.push(reason);

        desc = 'Massive Beeinträchtigung der Informationsverarbeitung. Kennzeichen: Neue Informationen werden wieder vergessen, Konzentrations-/ Wahrnehmungsschwierigkeiten, reduzierte Aufmerksamkeitsspanne, Überforderung';
        reason = new Reason('E: Kommunizieren/Beschäftigen  ', 'G1.1', desc, ['E1', 'E3']);
        reason.conditionList.push(new ReasonCondition('06_03=01;06_03=02', 'oder'));
        FReasonsList.push(reason);

        desc = 'Schwere kognitive Funktionseinschränkung (ICD-10-GM-Kode aus U51.2-) ' +
            'Erweiterter Barthel-Index 0-15 Punkte oder kognitiver FIM 5-10 Punkte oder MMSE 0-16 Punkte liegt vor ';
        reason = new Reason('E: Kommunizieren/Beschäftigen  ', 'G1.2', desc, ['']);
        reason.conditionList.push(new ReasonCondition('00_90_01_30=02', ''));
        FReasonsList.push(reason);

        desc = 'Beeinträchtigte Anpassungsfähigkeit von Patient und/oder Angehörigen. Kennzeichen: Verleugnet den veränderten '
            + 'Gesundheitszustand und Notwendigkeit der Anpassung, verschiebt Entscheidungen, Unzureichende Problem-/Zielerfassung, äußert Ängste, bagatellisiert, fehlende Krankheitseinsicht, Körperbildstörung, fehlende Compliance, fehlende Zukunftsperspektive';
        reason = new Reason('E: Kommunizieren/Beschäftigen  ', 'G3', desc, ['E1', 'E2']);
        reason.conditionList.push(new ReasonCondition('07_10=01', ''));
        FReasonsList.push(reason);

        desc = 'Aus dem Gleichgewicht geratenes Selbstkonzept durch Sinn-/Lebenskrisen. Kennzeichen: Äußert Hoffnungslosigkeit, fehlende Zukunftsperspektive, fehlenden Lebensmut, zeigt Gefühle wie Trauer, Zorn, Wut, Bitterkeit';
        reason = new Reason('E: Kommunizieren/Beschäftigen  ', 'G4', desc, ['E1', 'E2']);
        reason.conditionList.push(new ReasonCondition('00_90_01_08=02', ''));
        FReasonsList.push(reason);

        desc = 'Beeinträchtigte Fähigkeit, Kompetenzen im Rahmen der Selbstpflegefähigkeit zu erwerben. Kennzeichen: Fehlende Fingerfertigkeit, eingeschränkte Sehfähigkeit';
        reason = new Reason('E: Kommunizieren/Beschäftigen  ', 'G7.1', desc, ['E4', 'E3']);
        reason.conditionList.push(new ReasonCondition('07_08=01;07_08=02', 'oder'));
        reason.conditionList.push(new ReasonCondition('risk_spi_sum<=32', ''));
        FReasonsList.push(reason);

        desc = 'Beeinträchtigte Fähigkeit, Kompetenzen im Rahmen der Selbstpflegefähigkeit zu erwerben. Kennzeichen: ausgeprägte sensomotorische Einschrankungen infolge von Hemi-, Para- oder Tetraplegie';
        reason = new Reason('E: Kommunizieren/Beschäftigen  ', 'G7.2', desc, ['E4', 'E3']);
        reason.conditionList.push(new ReasonCondition('00_90_01_04=02', ''));
        FReasonsList.push(reason);

        desc = 'Beeinträchtigte Kommunikation durch Sprach-/Kommunikationsbarrieren. Kennzeichen: Kann sich nicht verständlich machen, kann verbal nicht antworten';
        reason = new Reason('E: Kommunizieren/Beschäftigen  ', 'G10.1', desc, ['E4', 'E2']);
        reason.conditionList.push(new ReasonCondition('07_09=01', ''));
        FReasonsList.push(reason);

        desc = 'Beeinträchtigte Kommunikation durch Sprach-/Kommunikationsbarrieren. Kennzeichen:reagiert trotz normaler Vigilanz nicht';
        reason = new Reason('E: Kommunizieren/Beschäftigen  ', 'G10.2', desc, ['E4', 'E2']);
        reason.conditionList.push(new ReasonCondition('06_01=04', ''));
        reason.conditionList.push(new ReasonCondition('07_09=01', ''));
        FReasonsList.push(reason);

        desc = 'Beeinträchtigte Kommunikation durch Sprach-/Kommunikationsbarrieren. Kennzeichen:versteht die Landessprache nicht';
        reason = new Reason('E: Kommunizieren/Beschäftigen  ', 'G10.3', desc, ['E4', 'E2']);
        reason.conditionList.push(new ReasonCondition('00_03=01', ''));
        reason.conditionList.push(new ReasonCondition('07_09=02', ''));
        FReasonsList.push(reason);

        desc = 'Beeinträchtigte Kommunikation durch Sprach-/Kommunikationsbarrieren. Kennzeichen:kann nichts hören';
        reason = new Reason('E: Kommunizieren/Beschäftigen  ', 'G10.4', desc, ['E4', 'E2']);
        reason.conditionList.push(new ReasonCondition('06_01=04', ''));
        reason.conditionList.push(new ReasonCondition('07_07=01', ''));
        FReasonsList.push(reason);

        desc = 'Bluthochdruckkrise infolge einer autonomen Dysreflexie, mind. 1 x tägl., Risiko von Komplikationen  ' +
            'Kennzeichen: Kopfschmerzen, heiße Ohren, Schwitzen, Pupillenerweiterung, Gänsehaut, Blässe und danach Gesichtsrötung, Bradykardie';
        reason = new Reason('F: Kreislauf (NUR -plegien!)', 'G10', desc, ['F1']);
        reason.conditionList.push(new ReasonCondition('00_90_01_09=02', ''));
        FReasonsList.push(reason);

        desc = 'Orthostatische Hypotonie, Risiko von Komplikationen ' +
            'Kennzeichen: Schwindel, Augenflimmern, Bewusstlosigkeit etc., tritt im Zusammenhang mit Lagewechsel und/oder Mobilisation auf, Angstzustände';
        reason = new Reason('F: Kreislauf (NUR -plegien!) ', 'G11', desc, ['F2']);
        reason.conditionList.push(new ReasonCondition('00_90_01_10=02', ''));
        FReasonsList.push(reason);

        /* Für G10 sind die Bedingungen vorerst gleich. Zukünftig müssen die Anzahl Wunden/Dekubitus und die Wunddoku mit ausgewertet werden. Datenfelder sind uns nicht bekannt. */
        desc = 'Hochaufwendiges Wundmanagement ' +
            'mindestens 3 sekundär heilende Wunden (z.B. Dekubitus Grad 3) UND/ ODER großflächige Wunden (> 40 cm²)';
        reason = new Reason('G: Wundmanagement ', 'G10.1', desc, ['']);
        reason.conditionList.push(new ReasonCondition('00_90_01_55=02', ''));
        FReasonsList.push(reason);

        desc = 'Hochaufwendiges Wundmanagement ' +
            'Wunde mit großer Taschenbildung/ Unterminierung >= 4 cm² oder einem Raum > 1 cm³, durchblutende oder exulzerierende Wunde(n), oder offene Wunddrainagen; Wunde(n) bei beeinträchtigter Compliance, welche zu einem häufigen Wundverbandswechsel (mindestens 5 x tägl.) führen';
        reason = new Reason('G: Wundmanagement ', 'G10.2', desc, ['']);
        reason.conditionList.push(new ReasonCondition('00_90_01_55=02', ''));
        FReasonsList.push(reason);

        desc = 'Hochaufwendiges Wundmanagement ' +
            'Verbände an komplizierten Hautarealen (Kopf, Hand, Fuß, Intimbereich)';
        reason = new Reason('G: Wundmanagement ', 'G10.3', desc, ['']);
        reason.conditionList.push(new ReasonCondition('00_90_01_55=02', ''));
        FReasonsList.push(reason);

        desc = 'Großflächige und schwerwiegende Hauterkrankungen ' +
            'Kennzeichen: Großflächige Hauterkrankungen von mindestens 36% der Körperoberfläche bei z.B. Infektionen der Haut/Unterhaut, Dermatitis, Ekzemen, papulosquamösen Hautkrankheiten, Urtikaria, Erythem oder sonstigen Hauterkrankungen';
        reason = new Reason('G: Wundmanagement ', 'G11', desc, ['']);
        reason.conditionList.push(new ReasonCondition('00_90_01_47=02', ''));
        FReasonsList.push(reason);

        desc = 'Stoma-Anlage mit Komplikationen, bei vorliegendem Selbstfürsorgedefizit ' +
            'Kennzeichen: Stoma-Prolaps, Retraktion, Nekrosen, Hernie, erschwerte Versorgung bei massiver Ausscheidung, Hautschädigungen im Bereich der Stoma-Anlage, Wundheilungsstörungen im Bereich des Stomas (z.B. Stomaausriss, Nahtdehiszenz, Nekrose) ' +
            '- beeinträchtigte Compliance, welche zu einer häufigeren Stomaversorgung (mindestens 5 x tägl.) führt';
        reason = new Reason('G: Wundmanagement ', 'G12', desc, ['']);
        reason.conditionList.push(new ReasonCondition('04_10=01', ''));
        reason.conditionList.push(new ReasonCondition('00_90_01_48=02', ''));
        reason.conditionList.push(new ReasonCondition('risk_spi_sum<=32', ''));
        FReasonsList.push(reason);

        desc = 'Aufwendige Versorgung/Kontrolle nach Lappenplastik und/oder Replantation, postoperativ ' +
            'Kennzeichen: - Risiko einer Durchblutungsstörung des transplantierten Lappens - Risiko einer Abstoßungsreaktion - Risiko einer Wundheilungsstörung im Bereich der Lappenplastik';
        reason = new Reason('G: Wundmanagement ', 'G13', desc, ['']);
        reason.conditionList.push(new ReasonCondition('00_90_01_59=02', ''));

        desc = 'Zeichen einer respiratorischen Beeinträchtigung  ' +
            'Kennzeichen: erschwerte Atemtätigkeit wie Einsatz der Atemhilfsmuskulatur, veränderter Hustenmechanismus, kann nicht selbstständig abhusten, vermehrtes Bronchialsekret, zähes Bronchialsekret, pathologische(r) Atemfrequenz/-rhythmus';
        reason = new Reason('H: Atmung', 'G10.1', desc, ['H1']);
        reason.conditionList.push(new ReasonCondition('09_01=01', ''));
        FReasonsList.push(reason);

        desc = 'Risiko einer Pneumonie ' +
            'Kennzeichen: Veränderte Blutgaswerte, veränderter Hustenmechanismus, veränderte Inspirations- und Exspirationsatemdrücke, ' +
            'chonische Bronchitis, kann nicht selbstständig abhusten, vermehrtes Bronchialsekret, minderbelüftete Lunge, hohes Pneumonierisiko lt. Assessment, Schmerzen bei der Atmung, BMI von mind. 35 kg/m², mechanische Beeinträchtigung der Atmung';
        reason = new Reason('H: Atmung', 'G10.2', desc, ['H1']);
        reason.conditionList.push(new ReasonCondition('00_90_01_52=02', ''));
        FReasonsList.push(reason);

        desc = 'Vorhandensein einer Thoraxdrainage ' +
            'Kennzeichen: Vorhandene Pleuradrainage, Mediastinaldrainage oder Perikarddrainage, welche zur Ableitung von Flüssigkeiten ' +
            'und/oder Luft in den Thoraxraum gelegt wurde';
        reason = new Reason('H: Atmung', 'G11', desc, ['H1']);
        reason.conditionList.push(new ReasonCondition('00_90_01_53=02', ''));
        FReasonsList.push(reason);
        return FReasonsList;
    }
}
