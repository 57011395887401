import SystemHeaders from "../SystemHeaders";
import {QuestionnaireService} from "../../services/QuestionnaireService";
import {PatientItem} from "../Patient/PatientItem";
import {fhirEnums} from "../fhir-enums";
import {QuestionnaireResponse} from "./QuestionnaireResponse";
import {NitTools} from "../NursitTools";
import {FhirService} from "../../services/FhirService";
import {UserService} from "../../services/UserService";
import ResourceType = fhirEnums.ResourceType;
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import {ConfigService} from "../../services/ConfigService";
import {RuntimeInfo} from "../RuntimeInfo";

export class CIRiskAssessment {
    private static generateBasisItem(response, name: string) {
        let questionnaire: any = QuestionnaireService.GetQuestionnaireDirect(response.questionnaire);
        let result = {
            id: response.id,
            reference: "QuestionnaireResponse/" + response.id,
            identifier: {
                system: SystemHeaders.vendorBase + name,
                value: questionnaire ? questionnaire.name : name
            },
            display: questionnaire ? questionnaire.title : name
        };

        return result;
    }

    /**
     * Creates a new RiskAssessment.Prediction
     * @param system - the system to use for the prediction
     * @param value - the value to set in the created prediction
     * @param display - the display value to set
     * @return a new instance of type any
     */
    public static CreateRiskAssessmentPrediction(system: string, value: any, display: string): any {
        let pred: any = {
            outcome: {
                coding: [
                    {
                        system: SystemHeaders.vendorBase + system,
                        version: "1",
                        code: value
                    }
                ]
            },
            whenPeriod: {
                start: new Date().toJSON()
            }
        };

        if (display) {
            pred.outcome.coding[0].display = display;
        }

        return pred;
    }

    /** Sets or Updates the specified Response as basis for the RiskAssessment *
     * @param riskAssessment - the RiskAssessment to set the Basis-Item from
     * @param response - the QuestionnaireResponse to get the values from
     * @param name - the type of the QuestionnaireResponse. Either Assessment or Anamnesis */
    public static SetBasis(riskAssessment: any, response: any, name: "Assessment" | "Anamnesis") {
        if (!riskAssessment.basis) riskAssessment.basis = [];
        let baseIndex = riskAssessment.basis.findIndex(o => o.identifier.value.endsWith(name));
        if (baseIndex > -1) {
            riskAssessment.basis.splice(baseIndex, 1);
        }

        riskAssessment.basis.push(this.generateBasisItem(response, name));
    }

    /** Sets the value of the Prediction in the given RiskAssessment to the provided value. *
     * @param riskAssessment - the RiskAssessment to set the value ie
     * @param system - the system value to find
     * @param display - the display value to set
     * @para createIfNotExists - option to create a new prediction if no Prdiction with the given System is found */
    public static SetPredictionValue(riskAssessment: any, system: string, value: any, display: string = "", createIfNotExists: boolean = false) {
        let predictionRisk = this.GetPredictionCoding(riskAssessment, system);
        if (!predictionRisk && !createIfNotExists) {
            console.warn(`Prediction with system "${system} not found and createIfNotExists is false`);
            return;
        }

        if (!predictionRisk && createIfNotExists) {
            if (!system.indexOf(SystemHeaders.vendorBase)) {
                system = SystemHeaders.vendorBase + system;
            }

            let risk = this.CreateRiskAssessmentPrediction(system, value, display);
            riskAssessment.prediction.push(risk);
        } else {
            predictionRisk.code = String(value);
            try {
                let version = parseInt(predictionRisk.version);
                version++;
                predictionRisk.version = String(version);
            } catch (e) {
                if (ConfigService.Debug) console.warn(e.message || e);
            }

            if (display) predictionRisk.display = String(display);
        }
    }

    /**
     * Searches for a specific Prediction in the given RiskAssessment
     * @param riskAssessment - the RiskAssessment to search for the prediction
     * @param system - the system to use for searching the prediction.outcome
     * @return a any containing the prediction
     * */
    public static GetPredictionCoding(riskAssessment: any, system: string): any {
        if (!riskAssessment) return undefined;
        if (!riskAssessment.prediction) riskAssessment.prediction = [];
        let predictions = riskAssessment.prediction.filter(o => o && o.outcome && o.outcome.coding);

        if (system.indexOf(SystemHeaders.vendorBase) === -1) system = SystemHeaders.vendorBase + system;

        for (let p = 0; p < predictions.length; p++) {
            let pred = predictions[p];
            for (let c = 0; c < pred.outcome.coding.length; c++) {
                let coding = pred.outcome.coding[c];
                if (coding.system.toUpperCase() === system.toUpperCase()) {
                    return coding;
                }
            }
        }

        return undefined;
    }

    /**
     * Compares two RiskAssessments
     * @param ra1 - the first RiskAssessment to use for compare
     * @param ra2 - the second RiskAssessment to use for compare
     * @return a boolean value indicating whether values of the both RiskAssessments are equal
     */
    public static AreEqual(ra1: any, ra2: any): boolean {
        if (ra1 && !ra2 || !ra1 && ra2) return false;
        if ((!ra1 && !ra2) || (!ra1.prediction && !ra2.prediction)) return true;
        if (!ra1.prediction || !ra2.prediction) return false;
        if (ra1.prediction && !ra2.prediction || !ra1.prediction && ra2.prediction) return false;
        if (ra1.prediction.length != ra2.prediction.length) return false;
        if (!ra1 && !ra2) return true;
        if (ra1.status !== ra2.status) return false;

        let result = true;
        ra1.prediction.filter(o => o.outcome && o.outcome.coding).forEach(p => {
            let coding1 = p.outcome.coding[0];
            let coding2 = this.GetPredictionCoding(ra2, coding1.system);
            if (coding2) {
                let areEqual = String(coding1.code) === String(coding2.code);
                if (result && !areEqual) result = false;

                /* if (ConfigService.Debug && !areEqual) {
                    let id = coding1.system.split('/')[coding1.system.split('/').length - 1];
                    console.debug(`[CIRiskAssessment.AreEqual]\nRiskAssessment differs in ${id}, coding1: "${coding1.code}" <-> coding2: "${coding2.code}"`);
                } */
            }
        });


        return result;
    }

    /**
     * Applies Risks from the given Assessment to the given RiskAssessment
     * @param riskAssessment - the RiskAssessment to apply the risks to
     * @param assessment - the Assessment-QuestionnaireResponse to get the risk_* - fields from
     */
    public static ApplyRisksFromAssessment(riskAssessment: any, assessment: any) {
        if (!riskAssessment) return;
        riskAssessment.prediction = [];
        if (!assessment.item) assessment.item = [];
        assessment.item.filter(o => o.linkId.toUpperCase().indexOf("RISK") > -1)
            .forEach((item: any) => {
                let ra = this.CreateRiskAssessmentPrediction(item.linkId, QuestionnaireResponse.GetResponseItemValue(item), item.text);
                if (!ra.whenPeriod) ra.whenPeriod = {};
                ra.whenPeriod.start = assessment.meta && assessment.meta.lastUpdated ? assessment.meta.lastUpdated : assessment.authored;
                riskAssessment.prediction.push(ra);
            });
    }

    public static async annulateExistingRiskAssessments(patient: PatientItem, fhirService: FhirService, assessment: any, anamnesis: any) {
        let url = `RiskAssessment?encounter=${patient.encounterId}`;
        let riskAssessments = <any[]>await fhirService.fetch(url, true);

        let riskBundleItems = [];
        for (const riskAssessment of riskAssessments) {
            if (riskAssessment.status !== "registered") {
                riskAssessment.status = "registered";
                riskBundleItems.push(riskAssessment);
            }
        }

        await fhirService.bundle(riskBundleItems, HTTPVerb.put, BundleType.transaction);

        let newRiskAssessment = this.CreateRiskAssessment(patient.encounter, patient, UserService.Practitioner, assessment, anamnesis);
        newRiskAssessment.prediction = [
            {
                outcome: {
                    text: new Date().toJSON()
                },
                id: SystemHeaders.vendorBase + "created"
            }
        ];

        if (assessment && anamnesis) {
            if (([QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended].indexOf(<QuestionnaireResponseStatus>assessment.status) > -1)
                && ([QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended].indexOf(<QuestionnaireResponseStatus>anamnesis.status) > -1)) {
                newRiskAssessment.status = "final";
            } else {
                newRiskAssessment.status = "preliminary";
            }
        }

        // take over previous values
        if (patient.currentRisks && patient.currentRisks.prediction) {
            patient.currentRisks.prediction.filter(pat => pat.outcome && pat.outcome.id !== SystemHeaders.vendorBase + "created")
                .forEach(p => newRiskAssessment.prediction.push(p));
        }

        patient.currentRisks = <any>await fhirService.create(newRiskAssessment);
        //if (ConfigService.Debug) console.debug("Created new RiskAssessment ", patient.currentRisks);
    }

    /**
     * Creates and Returns a new instance of type any
     * @param encounter - the encounter to create the any for
     * @param patient - the patient to create the any for
     * @param practitioner - the current logged in practitioner
     * @param assessment - the assessment to create the any for
     * @param anamnesis - the anamnesis to create the any for
     * @return a new instance of type any
     */
    public static CreateRiskAssessment(encounter: any, patient: PatientItem, practitioner?: any, assessment?: any, anamnesis?: any): any {
        if (!encounter) {
            console.warn("No encounter given!");
            return undefined;
        }

        let riskAssessment: any = {
            id: NitTools.Uid(),
            resourceType: ResourceType.riskAssessment,
            language: RuntimeInfo.Language,
            status: "preliminary",
            basis: [],
            occurrenceDateTime: new Date().toJSON(),
            identifier: {
                system: SystemHeaders.systemRiskAnalysis,
                value: new Date().toJSON()
            },
            subject: {reference: "Patient/" + patient.id},
            method: {text: SystemHeaders.vendorBase},
            prediction: []
        };

        riskAssessment[`${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}`] = {reference: "Encounter/" + encounter.id};

        if (practitioner) {
            let display: string = undefined;
            let names = practitioner.name && practitioner.name[0] ? practitioner.name : undefined;
            if (names) {
                let name = names.find(o => o.use === "official") || names[0];
                if (name) {
                    display = [name.family, name.given ? name.given?.join(' ') : ''].join(', ').trim();
                }
            }

            riskAssessment.performer = {reference: ResourceType.practitioner + "/" + practitioner.id};
            if (display) {
                riskAssessment.performer.display = display;
            }
        }

        if (assessment) {
            riskAssessment.basis.push(this.generateBasisItem(assessment, "Assessment"));
        }

        if (anamnesis) {
            riskAssessment.basis.push(this.generateBasisItem(anamnesis, "Anamnesis"));
        }

        if (riskAssessment.basis.length === 0) delete riskAssessment.basis;

        return riskAssessment;
    }
}
