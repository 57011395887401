import {FhirService} from "../../services/FhirService";
import {Tools} from "../FhirModules/Tools";
import {ConfigService, IEncounterTypeChoiceItem} from "../../services/ConfigService";
import {LocationService} from "../../services/LocationService";
import {NitTools} from "../NursitTools";
import * as Fhir from "../FhirModules/Fhir";
import {Patient, Questionnaire, QuestionnaireResponse} from "../FhirModules/Fhir";
import {RuntimeInfo} from "../RuntimeInfo";
import {fhirEnums} from "../fhir-enums";
import {I18N} from "aurelia-i18n";
import {QuestionnaireService} from "../../services/QuestionnaireService";
import {ICDCodeItem} from "../IICDCode";
import {IDiagnosis} from "../IDiagnosis";
import * as environment from "../../../../config/environment.json";
import {autoinject} from "aurelia-framework";
import {OrganizationService} from "../../services/OrganizationService";
import {PatientMark} from "./PatientMark";
import {parseInt} from "lodash";
import {translations} from "../translations";
import {IAnswer} from "../IAnswer";
import SystemHeaders from "../SystemHeaders";
import {AnalyzeService} from "resources/services/analyzeService";
import {Allergy, IAllergy} from "../allergy";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import HTTPVerb = fhirEnums.HTTPVerb;
import { HumanName } from "../HumanName";

const moment = require("moment");

/*** SNIPPETS:
 -- CLEAR ALL PATIENTS ON THE FHIR SERVER: --
 for (const loc of patientList.wards) {
 console.log("Loading ward: " + loc.name);
 const pats = await PatientItem.List(patientList.fhirService, patientList.locationService, loc.id);
 for (const pat of pats) {
 console.log(`Cleaning Patient "${pat.display}"`);
 await PatientItem.Clear(pat);
 if (!pat.name) {
 const _pat = await Fhir.Rest.Get("Patient/" + pat.id);
 _pat.name =  [
 { family: 'family' + new Date().valueOf(),
 given: [ 'givem' + new Date().valueOf() ] }
 ];

 await Fhir.Rest.Update(_pat);
 }
 }
 }
 */

@autoinject
export class PatientItem implements fhir3.Patient {
    public static RelationSystem = 'http://nursit-institute.com/fhir/StructureDefinition/PatientRelation';
    public static i18n: I18N;
    public static LastLoadedPatient?: PatientItem;
    public static SelectedPatient: PatientItem;
    private static __patients: PatientItem[] = [];
    private static __lastWardId: string;
    private static lastQueriedReportName = {
        patientId: '',
        wardId: '',
        route: '',
        result: undefined
    }
    /**
     * cache item for GetQuestionnaireName, used for performance
     * @see GetQuestionnaireName()
     */
    private static lastQueriedQuestionnaireName = {
        patientId: '',
        wardId: '',
        route: '',
        result: undefined
    }
    private static __isLoading: boolean = false;
    public caseId?: string = '';
    public patientNumber?: string = '';
    public allergies?: IAllergy[] = undefined; // will be of type AllergyIntolerance
    public contraindicationsCount: number = 0;
    public valuesCount: number = 0;
    public equipmentCount: number = 0;
    public SPI?: number = -1;
    public careLevelText?: string;
    public location?: string;
    public selected?: boolean = false;
    public search?: string = '';
    public offlineInfo?: any;
    public spiSpiderMedia: any;
    mark_1_class: string;
    mark_2_class: string;
    mark_3_class: string;
    mark_4_class: string;
    mark_5_class: string;
    mark_6_class: string;
    mark_7_class: string;
    mark_8_class: string;
    mark_9_class: string;
    mark_10_class: string;
    bed: string;
    birthDateDisplay: string;
    days?: number;
    careLevel: number;
    careLevelColor: string;
    carePlans: any[];
    /** Fachbereich */
    department: string;
    /** Fachbereich Kürzel */
    departmentIdentifier: string;
    dischargeColor: string;
    dischargePercent: number;
    display: string;
    encounterId: string;
    diagnosis?: IDiagnosis[];
    encounters: any[];
    bedId: string;
    roomId: string;
    isMother: boolean = false;
    isBaby: boolean = false;
    familyName: string;
    givenName: string;
    _sortIndex: string;
    marks?: PatientMark[] = [];
    recareUrl: string;
    pkms_relevant: boolean;
    procedureRequests: any[];
    procedures: any[];
    questionnaireResponses: any[];
    currentRisks?: any;
    riskAssessments?: any[];
    flags?: any;
    room?: string;
    roomColor?: string;
    selectedAdditionalInfo?: any;
    toDoList?: any[];
    assessmentName: string = undefined;
    anamnesisName: string = undefined;
    insuranceNumber?: string;
    insuranceName?: string;
    releaseInformation?: IReleaseInformation;
    /**
     * The type of the resource.
     */
    readonly resourceType: 'Patient';
    /**
     * Contains extended information for property 'resourceType'.
     */
    _resourceType?: Element;
    /**
     * Logical id of this artifact
     */
    id?: string;
    /**
     * Contains extended information for property 'id'.
     */
    _id?: Element;
    /**
     * Metadata about the resource
     */
    meta?: any;
    /**
     * A set of rules under which this content was created
     */
    implicitRules?: string | undefined;
    /**
     * Contains extended information for property 'implicitRules'.
     */
    _implicitRules?: Element;
    /**
     * Language of the resource content
     */
    language?: string;
    /**
     * Contains extended information for property 'language'.
     */
    _language?: Element;
    /**
     * Text summary of the resource, for human interpretation
     */
    text?: any;
    /**
     * Contained, inline Resources
     */
    contained?: any[];
    /**
     * Additional Content defined by implementations
     */
    extension?: any[];
    /**
     * Extensions that cannot be ignored
     */
    modifierExtension?: any[];
    /**
     * An identifier for this patient
     */
    identifier?: any[];
    /**
     * Whether this patient's record is in active use
     */
    active?: boolean;
    /**
     * Contains extended information for property 'active'.
     */
    _active?: Element;
    /**
     * A name associated with the patient
     */
    name?: any[];
    /**
     * A contact detail for the individual
     */
    telecom?: any[];
    /**
     * male | female | other | unknown
     */
    gender?: ('male' | 'female' | 'other' | 'unknown') | undefined;
    genderDisplay?: string;
    /**
     * Contains extended information for property 'gender'.
     */
    _gender?: Element;
    /**
     * The date of birth for the individual
     */
    birthDate?: string | undefined;
    /**
     * Contains extended information for property 'birthDate'.
     */
    _birthDate?: Element;
    /**
     * Indicates if the individual is deceased or not
     */
    deceasedBoolean?: boolean;
    /**
     * Contains extended information for property 'deceasedBoolean'.
     */
    _deceasedBoolean?: Element;
    /**
     * Indicates if the individual is deceased or not
     */
    deceasedDateTime?: string | undefined;
    /**
     * Contains extended information for property 'deceasedDateTime'.
     */
    _deceasedDateTime?: Element;
    /**
     * Addresses for the individual
     */
    address?: any[];
    /**
     * Marital (civil) status of a patient
     */
    maritalStatus?: any;
    /**
     * Whether patient is part of a multiple birth
     */
    multipleBirthBoolean?: boolean;
    /**
     * Contains extended information for property 'multipleBirthBoolean'.
     */
    _multipleBirthBoolean?: Element;
    /**
     * Whether patient is part of a multiple birth
     */
    multipleBirthInteger?: number | undefined;
    /**
     * Contains extended information for property 'multipleBirthInteger'.
     */
    _multipleBirthInteger?: Element;
    /**
     * Image of the patient
     */
    photo?: any[];
    /**
     * A contact party (e.g. guardian, partner, friend) for the patient
     */
    contact?: any[];
    /**
     * This patient is known to be an animal (non-human)
     */
    animal?: any;
    /**
     * A list of Languages which may be used to communicate with the patient about his or her health
     */
    communication?: any[];
    /**
     * Patient's nominated primary care provider
     */
    generalPractitioner?: any[];
    /**
     * Organization that is the custodian of the patient record
     */
    managingOrganization?: any;
    /**
     * Link to another patient resource that concerns the same actual person
     */
    link?: any[];
    motherAssigned: boolean;
    childrenAssigned: boolean;
    relatedPersons : IRelatedPersonInfo;
    constructor(data?) {
        if (data) {
            Object.assign(this, data);
        }

        if (!this.marks) {
            this.marks = [];
        }

        if (this.marks.length === 0) {
            for (let i = 1; i <= 10; i++) {
                const checkedRed = (i === 10 && ConfigService.UseAssessmentForSignal10) || (i === 5 && ConfigService.UseBarthelIndexForFlag5);
                this.marks.push(new PatientMark({
                    index: i,
                    red: checkedRed,
                    yellow: false,
                    checked: checkedRed,
                    patient: this
                }));
            }
        }
    }

    /**
     * Returns the system for the identifier
     * @param patient optional: a patient resource or PatientItem resource to read the current system from
     * @constructor
     */
    public static GetPatientIdentifierSystem(patient? : PatientItem|fhir3.Patient|fhir4.Patient) : string {
        let system = `${NitTools.ExcludeTrailingSlash(RuntimeInfo.SystemHeader)}/patientNumbers`;
        const cfgAnalysis = ConfigService.GetFormSettings('analysis');
        if (cfgAnalysis?.settings?.patientIdentifierSystem && cfgAnalysis.settings.patientIdentifierSystem.indexOf("http") === 0) {
            system = cfgAnalysis?.settings?.patientIdentifierSystem;
        }

        if (NitTools.IsArray(patient?.identifier)) {
            const identifier = patient.identifier.find(o=>o.system?.indexOf("/patientNumber") > -1);
            if (identifier?.system.indexOf('http') === 0) {
                system = identifier.system;
            }
        }

        return system;
    }

    public static GetVisitNumberSystem(encounter? : fhir3.Encounter|fhir4.Encounter) : string {
        let system = `${NitTools.ExcludeTrailingSlash(RuntimeInfo.SystemHeader)}/visitNumbers`;
        const cfgAnalysis = ConfigService.GetFormSettings('analysis');
        if (cfgAnalysis?.settings?.caseIdentifierSystem && cfgAnalysis.settings.caseIdentifierSystem.indexOf("http") === 0) {
            system = cfgAnalysis?.settings?.caseIdentifierSystem;
        }

        if (NitTools.IsArray(encounter?.identifier)) {
            const identifier = (<any[]>encounter.identifier).find(o=>o.system?.indexOf("/visitNumber") > -1);
            if (identifier?.system.indexOf('http') === 0) {
                system = identifier.system;
            }
        }

        return system;
    }

    public static get Patients(): PatientItem[] {
        return this.__patients;
    }

    public static set Patients(value: PatientItem[]) { // have to include for testing purposes
        this.__patients = value;
    }

    get careLevelString() {
        return this.careLevelText;
    }

    set careLevelString(value) {
        this.careLevelText = value;
    }

    get isEncounterDuringPkmsPeriod(): boolean {
        if (!this._encounter) return false;
        const period = this._encounter.period;
        if (period && period.start) {
            const maxPkmsDate = new Date("2020-12-13");
            const start = new Date(period.start);

            // when the encounter started before 2020-12-31 then pkms has to be visible
            if (moment(start).isBefore(maxPkmsDate)) return true;
        }

        return false;
    }

    private _encounter: any = undefined;

    get encounter(): any {
        return this._encounter;
    }

    set encounter(value: any) {
        this._encounter = value;
    }

    _latestAssessment: any = undefined;

    get latestAssessment(): any {
        return this._latestAssessment;
    }

    set latestAssessment(value: any) {
        //console.debug("LATEST ASSESSMENT SET!");
        if (value) {
            if (value.subject?.reference.indexOf('/' + this.id) === -1) {
                console.warn('Setting latest assessment for not the current patient? This CAN NOT be correct!');
                return;
            }
        }

        this._latestAssessment = value;
    }

    _isOffline?: boolean = undefined;

    get isOffline(): boolean {
        if (typeof this._isOffline === "undefined" && this.encounter) {
            const info = Fhir.Tools.GetOfflineInfo(this.encounter);
            this._isOffline = info.offline;
        }

        return this._isOffline
    }

    get mark_1(): boolean {
        return this._getChecked(1);
    }

    get mark_1_red(): boolean {
        return this._getRed(1);
    }

    get mark_1_yellow(): boolean {
        return this._getYellow(1);
    }

    get mark_2(): boolean {
        return this._getChecked(2);
    }

    get mark_3(): boolean {
        return this._getChecked(3);
    }

    get mark_4(): boolean {
        return this._getChecked(4);
    }

    get mark_5(): boolean {
        return this._getChecked(5);
    }

    get mark_5_red(): boolean {
        return this._getRed(5);
    }

    get mark_5_yellow(): boolean {
        return this._getYellow(5);
    }

    get mark_6(): boolean {
        return this._getChecked(6);
    }

    get mark_7(): boolean {
        return this._getChecked(7);
    }

    get mark_8(): boolean {
        return this._getChecked(8);
    }

    get mark_9(): boolean {
        return this._getChecked(9);
    }

    get mark_10(): boolean {
        return this._getChecked(10);
    }

    get mark_10_red(): boolean {
        return this._getRed(10);
    }

    get mark_10_yellow(): boolean {
        return this._getYellow(10);
    }

    _ward: string;

    get ward(): string {
        return this._ward;
    }

    set ward(value: string) {
        this._ward = value;
    }

    _wardId: string = undefined;

    get wardId(): string {
        return this._wardId;
    }

    set wardId(value: string) {
        this._wardId = value;
    }

    get years(): number {
        if (!this.birthDate)
            return undefined;

        return moment(new Date()).diff(this.birthDate, "y");
    }

    private _motherPatient: string;

    public get motherPatient(): string {
        return this._motherPatient;
    }

    private _childrenPatients: string[] = [];

    /**
     Holds a list containing the assigned children encounters
     */
    public get childrenPatients(): string[] {
        return this._childrenPatients;
    }

    static GetEncounterChoiceItem(patient: PatientItem): IEncounterTypeChoiceItem {
        if (!ConfigService.EncounterTypeChoice.enabled) {
            return undefined;
        }

        let choice: IEncounterTypeChoiceItem = ConfigService.EncounterTypeChoice.choices.find(o => o.target === "other");

        if (patient.isBaby) {
            choice = ConfigService.EncounterTypeChoice.choices.find(o => o.target === "child");
        } else if (patient.isMother) {
            choice = ConfigService.EncounterTypeChoice.choices.find(o => o.target === "mother");
        }

        return choice;
    }

    static async GetReportName(patient: PatientItem, route: string, defaultName?: string) {
        let result = undefined;

        // take a look if this is the cached item:
        if (this.lastQueriedReportName.patientId === patient.id &&
            this.lastQueriedReportName.wardId === patient.wardId &&
            this.lastQueriedReportName.route === route &&
            this.lastQueriedReportName.result) {

            return this.lastQueriedReportName.result;
        }

        await ConfigService.ReadDefaultConfig();
        let cfg = await ConfigService.LoadConfigOverride(patient.ward, patient);

        if (cfg) {
            // get the default form for the route in the ward
            let form = cfg.forms.find(o => o.route === route);
            if (form && form.report && typeof form.report.name === 'string') {
                result = form.report.name;
            }

            // check for override in GH
            if (cfg.features && cfg.features.encounterTypeChoice && cfg.features.encounterTypeChoice.enabled === true) {
                const choice: IEncounterTypeChoiceItem = PatientItem.GetEncounterChoiceItem(patient);

                if (choice && choice.forms && choice.forms[route] && choice.forms[route].report && typeof choice.forms[route].report.name === 'string') {
                    result = choice.forms[route].report.name;
                }
            }
        }

        // update cache item
        this.lastQueriedReportName.patientId = patient.id;
        this.lastQueriedReportName.route = route;
        this.lastQueriedReportName.wardId = patient.wardId;
        this.lastQueriedReportName.result = result || defaultName;

        return result || defaultName;
    }

    /**
     * Gets the name of the currently configured questionnaire identified by the questionnaire.name
     */
    static GetQuestionnaireName(patient: PatientItem, route: string, defaultName?: string): string {
        let result = undefined;

        // take a look if this is the cached item:
        if (this.lastQueriedQuestionnaireName?.patientId === patient?.id &&
            this.lastQueriedQuestionnaireName?.wardId === patient?.wardId &&
            this.lastQueriedQuestionnaireName?.route === route &&
            this.lastQueriedQuestionnaireName?.result) {

            return this.lastQueriedQuestionnaireName.result;
        }

        let form = ConfigService.GetFormSettings(route, patient);
        if (!form) {
            console.warn(`No route "${route}" found in Config!`);
        }

        if (form?.questionnaireName) {
            result = form.questionnaireName;
        }

        // update cache item
        if (patient?.id) {
            this.lastQueriedQuestionnaireName.patientId = patient.id;
            this.lastQueriedQuestionnaireName.route = route;
            this.lastQueriedQuestionnaireName.wardId = patient.wardId;
            this.lastQueriedQuestionnaireName.result = result || defaultName;
        }

        return result || defaultName;
    }

    public static CalculateMark10(patient: PatientItem, date?: string | Date) {
        if (!ConfigService.UseAssessmentForSignal10)
            return;

        const mark10 = patient.mark(10);
        if (mark10) {
            mark10.checked = true;
            mark10.red = true;
            const formSettingAssessment = ConfigService.GetFormSettings('assessment');

            if (!date) { // try to get the date from the flags.latest<ISO-Name>Date
                const latestAssessmentFlag = Fhir.Tools.GetOrCreateFlag(patient.flags, `latest${formSettingAssessment.questionnaireName}Date`, false);
                if (latestAssessmentFlag)
                    date = latestAssessmentFlag.code;
            }

            if (date) {
                let created = new Date(date);

                const assessmentRedAfter = formSettingAssessment.expiration.durations.redAfter;
                const assessmentYellowAfter = formSettingAssessment.expiration.durations.yellowAfter;
                const assessmentAge = moment(new Date()).diff(created, 'h');

                mark10.yellow = assessmentAge > assessmentYellowAfter;
                mark10.red = assessmentAge > assessmentRedAfter;
                mark10.checked = true;
            }
        }
    }

    /***
     * Calculate Isolation-Marker (mark1)
     */
    public static CalculateMark1(patient: PatientItem, date?: string | Date): PatientMark {
        const mark1 = patient.mark(1);

        if (ConfigService.UseIsolationForFlag1 && mark1) {
            if (mark1.checked === true) {
                const config = ConfigService.GetFormSettings("isolation");

                if (!date) { // try to get the date from the flags.latest<ISO-Name>Date
                    const latestIsoFlag = Fhir.Tools.GetOrCreateFlag(patient.flags, `latest${config.questionnaireName}Date`, false);
                    if (latestIsoFlag)
                        date = latestIsoFlag.code;
                }

                if (!date) {
                    mark1.red = true;
                    return;
                }

                const now = new Date();
                //*********************************************************************************
                //#region get the reset Time from config|default
                let h = 0;
                let m = 0;
                const resetConfigTime =
                    config && config.settings && config.settings["resetTime"]
                        ? config.settings["resetTime"]
                        : "00:00";
                if (resetConfigTime.indexOf(":") > -1) {
                    h = resetConfigTime.split(":")[0];
                    m = resetConfigTime.split(":")[1];
                }
                //#endregion

                // the expiration date of the iso-document.authored is the next day @ the configured time
                // so when the document is created @ 01.01.2020 the expiration should be the 02.01.2020 + resetTime
                const expirationDate = new Date(date);
                expirationDate.setMilliseconds(0);
                expirationDate.setSeconds(0);
                expirationDate.setMinutes(m);
                expirationDate.setHours(h);
                expirationDate.setDate(expirationDate.getDate() + 1);

                mark1.red = moment(now).isAfter(expirationDate);
            } else {
                mark1.red = false;
                mark1.yellow = false;
            }
        }

        return mark1;
    }

    /***
     * Read Isolation-Marker from Flags/Encounter
     * @param patient
     * @constructor
     */
    public static ReadMark1(patient: PatientItem) {
        if (!patient.flags) return;

        const markFlag1 = Tools.GetOrCreateFlag(patient.flags, `mark_1`, false);
        const markItem = patient.mark(1);
        if (markItem && markFlag1 && markFlag1 && markFlag1.code) {
            markItem.checked = NitTools.ParseBool(markFlag1.code);
            this.CalculateMark1(patient);
        }
    }

    /***
     * Removes all patient's and encounter's data so the patient is clean like just created in a perfect result
     */
    public static async Clear(patient: PatientItem): Promise<any[]> {
        if (!ConfigService.Debug && !ConfigService.IsTest) return;
        let all = <any[]>await Fhir.Rest.Fetch('Patient/' + patient.id + '/$everything?_summary=true');

        all = all.filter(o => ['Encounter', 'Patient', 'Location', 'Questionnaire', 'CodeSystem', 'Practitioner', 'Organization', 'Medication'].indexOf(o.resourceType) === -1);
        await Fhir.Rest.Bundle(all, HTTPVerb.delete);

        if (patient.encounter.meta.tag) {
            for (const tag of patient.encounter.meta.tag) {
                try {
                    await FhirService.Tags.delete(patient.encounter, tag, true);
                } catch (e) {
                    console.warn(`Error when removing Tag from Encounter: "${e}"`)
                }
            }
        }

        all = <any[]>await Fhir.Rest.Fetch('Patient/' + patient.id + '/$everything?_summary=true');
        console.debug("Patient left:", all);

        return all;
    }

    /***
     * removes all patient's and encounter's resources and afterwards tries to delete the patient and encounter from fhir
     */
    public static async Delete(patient: PatientItem) {
        if (!ConfigService.Debug && !ConfigService.IsTest) return;
        const leftOvers = await this.Clear(patient);
        const pat = leftOvers.find(o => o.resourceType === "Patient");
        const enc = leftOvers.find(o => o.resourceType === "Encounter");
        const qr = leftOvers.filter(o => o.resourceType === "QuestionnaireResponse");

        const toDelete = [pat, enc, ...qr];

        const result = await Fhir.Rest.Bundle(toDelete, HTTPVerb.delete);

        console.debug("Delete Result:", result);
    }

    /**
     * Read bi/ebi marker from tags
     * @param patientItem
     * @constructor
     */
    public static ReadMark5(patientItem: PatientItem): PatientMark {
        if (!patientItem) return undefined;

        const mark5 = patientItem.mark(5);
        if (!mark5) return undefined;

        const formSettingAssessment = ConfigService.GetFormSettings('assessment');
        const assessmentDateSystem = `latest${formSettingAssessment.questionnaireName}Date`;
        const latestAssessmentFlag = Fhir.Tools.GetOrCreateFlag(patientItem.flags, assessmentDateSystem, false);
        const mark5CheckedFlag = Fhir.Tools.GetOrCreateFlag(patientItem.flags, `mark_5`, false);
        const mark5RedFlag = Fhir.Tools.GetOrCreateFlag(patientItem.flags, `mark_5_red`, false);
        const mark5YellowFlag = Fhir.Tools.GetOrCreateFlag(patientItem.flags, `mark_5_yellow`, false);

        // when using BI flag 5 should always be checked!
        if (ConfigService.UseBarthelIndexForFlag5) {
            mark5.checked = true;

            // no assessment exists, so it has to be red!
            if (!latestAssessmentFlag) {
                mark5.yellow = false;
                mark5.red = true;
            }
        } else {
            mark5.checked = NitTools.ParseBool(mark5CheckedFlag.code);
        }

        if (mark5RedFlag) {
            mark5.red = NitTools.ParseBool(mark5RedFlag.code);
        }

        if (mark5YellowFlag) {
            mark5.yellow = NitTools.ParseBool(mark5YellowFlag.code);
        }

        return mark5;
    }

    public static async Refresh(fhirService: FhirService, locationService: LocationService, wardId: string): Promise<PatientItem[]> {
        this.__lastWardId = '';
        this.__patients = [];
        return await this.List(fhirService, locationService, wardId);
    }

    public static SelectEncounter(encounterId: string) {
        this.SelectedPatient = this.__patients.find(o => o.encounter && o.encounter.id === encounterId);
    }

    static waitForLoading(): Promise<any> {
        return new Promise<any>(async (resolve) => {
            if (!this.__isLoading) {
                resolve(true);
            } else {
                window.setTimeout(async () => {
                    await this.waitForLoading();
                    resolve(false);
                }, 100);
            }
        })
    }

    /***
     * Read the current percentage and color of the Discharge-Progress
     */
    public static UpdatePatientDischargePct(patient: PatientItem, i18n: I18N) {
        if (!patient) return;
        let color = "orange";
        let pct = 0;
        let dischargeIsActive = false;
        let dischargeQuestionnaireResponse = QuestionnaireService.GetLatestResponseOfType(patient, QuestionnaireService.__listResult.QDischargeManagementId);
        if (!dischargeQuestionnaireResponse) {
            dischargeIsActive = false;
        } else {
            let disableClassicValidation = false;
            let LFieldsToCheck: string[] = ['E_5', 'E_6', 'E_28', 'E_8', 'E_9', 'E_10', 'E_13', 'E_15', 'E_17', 'E_20', 'E_21', 'E_23', 'E_26'];

            if (!patient.releaseInformation) patient.releaseInformation = {};
            patient.releaseInformation.redReasons = [];
            patient.dischargeColor = "transparent";
            patient.dischargePercent = undefined; // set to not active per default

            //#region check whether the Discharge Plan is active
            let itemE2 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(dischargeQuestionnaireResponse, "E_2", false);
            if (itemE2) {
                let answerE2: boolean = Fhir.QuestionnaireResponse.GetResponseItemValue(itemE2);
                // Releaseplan active: 1 = False, 2 = True
                if (typeof answerE2 === "undefined" || answerE2 === false) {
                    dischargeIsActive = false;
                } else {
                    patient.dischargePercent = 0;
                    dischargeIsActive = true;
                }
            }
            //#endregion

            //#region Discharge IS Active, so do the caluclations
            if (dischargeIsActive) {
                //#region get the warnings from the config before calling the hardcoded warnings
                const releaseConfig = ConfigService.GetFormSettings('discharge');
                let dischargeSettings;
                if (releaseConfig && releaseConfig.settings && releaseConfig.settings.discharge) {
                    dischargeSettings = releaseConfig.settings.discharge;

                    // check whether to disable the hardcoded validation
                    if (typeof dischargeSettings.disableClassicValidation === "boolean") {
                        disableClassicValidation = releaseConfig.settings.discharge.disableClassicValidation;
                    } else {
                        console.warn('No disableClassicValidation (bool) setting in => (route="discharge").settings.discharge.validation');
                    }

                    // do the validation if given
                    if (dischargeSettings.validation && NitTools.IsArray(dischargeSettings.validation)) {
                        dischargeSettings.validation.forEach(v => {
                            let item = new ICDCodeItem(v, patient, QuestionnaireService.__listResult);
                            if (item.isValid && item.test()) {
                                patient.releaseInformation.redReasons.push(v.text.indexOf(':') > -1 ? v.text : i18n.tr(v.text));
                            }
                        })
                    } else {
                        console.warn('No validation-rules [] in => (route="discharge").settings.discharge.validation');
                    }

                    if (dischargeSettings.answerFields && NitTools.IsArray(dischargeSettings.answerFields)) {
                        LFieldsToCheck = dischargeSettings.answerFields.slice();
                    } else {
                        console.warn('No answerFields-settings [] given in config.json => (route="discharge").settings.discharge.answerFields');
                    }
                }
                //#endregion

                let dischargeAnswers = dischargeQuestionnaireResponse.item;
                if (dischargeAnswers && dischargeAnswers.length > 0) {
                    let numberOfAnswers = 0;
                    let response = dischargeQuestionnaireResponse; // dischargeAnswers[0];
                    let percentageFieldCount = LFieldsToCheck.length;

                    //#region when classic (aka HardCoded) validation is not disabled, run the hardcoded tests
                    if (!disableClassicValidation) {
                        let itemE23 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(response, "E_23", false);
                        let itemE24 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(response, "E_24", false);
                        let itemE25 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(response, "E_25", false);
                        let itemE28 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(response, "E_28", false);
                        let itemE31 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(response, "E_31", false);

                        if (
                            (Fhir.QuestionnaireResponse.GetResponseItemValueInt(itemE28) === 1 && Fhir.QuestionnaireResponse.GetResponseItemValueInt(itemE31) === 0)
                            || (Fhir.QuestionnaireResponse.GetResponseItemValueInt(itemE23) === 1 && ((Fhir.QuestionnaireResponse.GetResponseItemValue(itemE24) || '') === ''))
                            || (Fhir.QuestionnaireResponse.GetResponseItemValueInt(itemE23) === 1 && Fhir.QuestionnaireResponse.GetResponseItemValueInt(itemE25) === 1)
                        ) {
                            if (Fhir.QuestionnaireResponse.GetResponseItemValueInt(itemE28) === 1
                                && Fhir.QuestionnaireResponse.GetResponseItemValueInt(itemE31) === 0) {
                                patient.releaseInformation.redReasons.push("Mit ambulantem Pflegedienst, aber kein Pflegedienst benachrichtigt");
                            }

                            if ((Fhir.QuestionnaireResponse.GetResponseItemValueInt(itemE23) === 1 && ((Fhir.QuestionnaireResponse.GetResponseItemValue(itemE24) || '') === '')))
                                patient.releaseInformation.redReasons.push("Transport erforderlich, aber kein Transport bestellt");

                            if (Fhir.QuestionnaireResponse.GetResponseItemValueInt(itemE23) === 1 && Fhir.QuestionnaireResponse.GetResponseItemValueInt(itemE25) === 1)
                                patient.releaseInformation.redReasons.push("Transport erforderlich, aber kein Transportschein");

                            color = "red";
                        }
                    }
                    //#endregion

                    if (dischargeSettings && dischargeSettings.answerFields && NitTools.IsArray(dischargeSettings.answerFields)) {
                        //#region get the number of answered fields - new Mode with mappings in the field
                        percentageFieldCount = dischargeSettings.answerFields.length;
                        dischargeSettings.answerFields.forEach(af => {
                            if (af.indexOf(':') > -1) {
                                let [field, values] = af.split(':');
                                if (values) {
                                    field = field.trim();
                                    const item = QuestionnaireResponse.GetResponseItemByLinkId(response, field);
                                    if (item) {
                                        const val = QuestionnaireResponse.GetResponseItemValue(item, 'No value has been to be set here');
                                        if (values.indexOf(val) > -1) {
                                            numberOfAnswers++;
                                            console.debug(`NEW COUNT, "${field}" -> ${values}`);
                                        }
                                    }
                                }
                            } else {
                                console.warn('Invalid answerField-settings in config.json => (route="discharge").settings.discharge.answerFields::' + af);
                            }
                        })
                        //#endregion
                    } else {
                        //#region get the number of answered fields - CLASSIC but faulty method, because the IDs in Discharge-Form are unreliable.
                        // There are items where no value set means iE _8, yes and no is interchanging and stuff like that
                        for (let i = 0; i < LFieldsToCheck.length; i++) {
                            let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(response, LFieldsToCheck[i], false);
                            if (item) {
                                let str = Fhir.QuestionnaireResponse.GetResponseItemValue(item, '');
                                if (str.indexOf("|") > -1) {
                                    str = str.split("|")[1];
                                }

                                if (str !== "" && !str.toUpperCase().endsWith("_NIL")) {
                                    numberOfAnswers++;
                                }
                            }
                        }
                        //#endregion
                    }

                    //#region get the percentage of answers
                    if (numberOfAnswers > 0) {
                        pct = (numberOfAnswers / percentageFieldCount) * 100;
                        pct = Math.round(pct);
                    }
                    //#endregion

                    patient.dischargeColor = color;
                    patient.dischargePercent = pct; // "numberOfAnswers: " + numberOfAnswers + " = %:" + pct;

                    if (patient.selectedAdditionalInfo) {
                        let dischargeItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(patient.selectedAdditionalInfo, 'dischargePercent');
                        if (dischargeItem) dischargeItem.answer = [{valueInteger: pct}]; //  Fhir.QuestionnaireResponse.SetResponseItemValue(dischargeItem, patient.dischargePercent);

                        dischargeItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(patient.selectedAdditionalInfo, 'dischargeColor');
                        if (dischargeItem) dischargeItem.answer = [{valueString: patient.dischargeColor}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(dischargeItem, patient.dischargeColor);
                    }
                } else {
                    patient.dischargePercent = undefined;
                    patient.dischargeColor = "transparent";
                }
            }
            //#endregion
        }
    }

    public static async ReadAndCalculateMarksAndVisitNumber(patient: PatientItem): Promise<any> {
        if (!patient)
            return undefined;

        await ConfigService.LoadConfigOverride(patient.ward, patient);
        await QuestionnaireService.Fetch();

        //#region read marks from flags
        let tmpMarks = patient.marks; //
        if (ConfigService.UseIsolationForFlag1)
            tmpMarks = tmpMarks.filter(o => o.index !== 1);

        if (ConfigService.UseBarthelIndexForFlag5)
            tmpMarks = tmpMarks.filter(o => o.index !== 5);

        if (ConfigService.UseAssessmentForSignal10)
            tmpMarks = tmpMarks.filter(o => o.index !== 10);

        for (const mark of tmpMarks) {
            const flag = Fhir.Tools.GetOrCreateFlag(patient.flags, 'mark_' + mark.index, false);
            if (flag) mark.checked = flag.code === 'true';

            const flagRed = Fhir.Tools.GetOrCreateFlag(patient.flags, 'mark_' + mark.index + '_red', false);
            if (flagRed) mark.red = flagRed.code === 'true';

            const flagYellow = Fhir.Tools.GetOrCreateFlag(patient.flags, 'mark_' + mark.index + '_yellow', false);
            if (flagYellow) mark.yellow = flagYellow.code === 'true';
        }
        //#endregion

        //#region search for the case identifier aka visitNumber
        patient.caseId = undefined;
        if (patient?.encounter?.identifier?.length > 0) {
            let identifiers = patient.encounter.identifier.filter(o => o.system?.indexOf('/visitNumber') > -1);
            if (identifiers && identifiers.length > 0) {
                let idElement = identifiers.find(o => o.use === "official");
                if (!idElement) {
                    idElement = identifiers.find(o => o.use === "usual");
                }

                if (!idElement) {
                    idElement = identifiers.find(o => o.use === "secondary");
                }

                if (!idElement) {
                    idElement = identifiers[0];
                }

                if (idElement) {
                    patient.caseId = idElement.value;
                }
            }
        }

        /*if (!patient.caseId || patient.caseId === 'undefined' || typeof patient.caseId === 'undefined') {
            patient.caseId = patient.encounter ? patient.encounter.id : '';
        }*/
        //#endregion

        //#region gather locations, needed at least for config overrides
        this.processContainedLocations(patient.encounter);
        if (patient.encounter?.location) {

            patient.encounter.location
                .filter(o => o.location?.reference &&
                    o.status !== 'completed' // no inactive locations
                )
                .forEach(encLocation => {
                    let locationId = Fhir.Tools.StripId(encLocation.location.reference);

                    let location = LocationService.LocationById(locationId);
                    if (location) {
                        const physicalCoding = location.physicalType && location.physicalType.coding ? location.physicalType.coding.find(o => o.code && ['wa', 'ro', 'bd'].indexOf(o.code) > -1) /*.find(o => o.system && o.system.endsWith('location-physical-type'))*/ : undefined;
                        if (physicalCoding && location.status && physicalCoding) {
                            if (location.status !== 'suspended' && location.status !== 'inactive') {
                                switch (physicalCoding.code) {
                                    case 'wa':
                                        patient.ward = location.name;
                                        patient.wardId = location.id;
                                        break;
                                    case 'ro':
                                        patient.room = location.name;
                                        patient.roomColor = 'transparent'
                                        if (location.extension) {
                                            const colorItem = location.extension.find(o => o.url.endsWith('roomColor'));
                                            if (colorItem)
                                                patient.roomColor = String(colorItem.valueString);
                                        }

                                        patient.roomId = location.id;
                                        break;
                                    case 'bd':
                                        patient.bed = location.name;
                                        patient.bedId = location.id;
                                        break;
                                }
                            }
                        }
                    }
                })

            patient.location = Tools.ShortenLocations(patient); // [patient.ward, patient.room, patient.bed].filter(o => typeof o !== "undefined").join(', ');
        }
        //#endregion

        //#region load config override
        if (patient?.ward) {
            patient.getAssessmentName();
            patient.getAnamnesisName();
        }
        //#endregion

        //#region get the latest assessment
        const questionnaireAssessment = QuestionnaireService.GetQuestionnaireByNameDirect(patient.assessmentName);

        patient.latestAssessment = QuestionnaireService.GetLatestResponseOfType(patient, questionnaireAssessment?.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        patient.encounterId = patient.encounter ? patient.encounter.id : 'no-encounter-(id-)present';
        //#endregion

        //#region create patient name string
        if (patient && patient.name) {
            let official = patient.name.find(o => o.use === 'official');
            if (!official) official = patient.name.find(o => o.use === 'usual');
            if (!official) official = patient.name[0];

            if (official) {
                patient.display = [official.family, official.given?.join(' ')].join(', ');
            }
        }

        //#endregion

        //#region check offline-status and create search-string
        patient.search = ((patient.caseId || '') + (patient.display ?? '').toUpperCase()).trim().replace(/[ ,]/g, '');
        const info = Tools.GetOfflineInfo(patient.encounter);
        patient._isOffline = info ? info.offline : false;
        patient.checkOffline();
        //#endregion

        //#region get SPI and CareLevel
        if (!patient.flags && !this.ReadCareLevelFromFlags(patient)) {
            if (!patient.SPI) {
                patient.SPI = parseInt(FhirService.Tags.value(patient.encounter, 'nursit-institute.com/structureDefinitions/tags/SPI'));
                if (typeof patient.SPI === "undefined" || isNaN(patient.SPI)) {
                    patient.SPI = 0;
                }
            }

            patient.careLevel = Tools.SpiToCareLevel(patient.SPI);
            if (typeof patient.careLevel === 'undefined') patient.careLevel = -1;
            patient.careLevelColor = Tools.CareLevelToColor(patient.careLevel);
            patient.careLevelText = Tools.CareLevelToText(patient.careLevel);
        }
        //#endregion

        //#region get the correct bi/ebi marker values for mark_5
        // if no Assessment is present, then the mark5 has to be red. Don't check it by latestAssessment rather than by existence of latest<AssessmentName>Date
        const assessmentDateSystem = `latest${patient.assessmentName}Date`;
        const latestAssessmentDateCoding = Fhir.Tools.GetOrCreateFlag(patient.flags, assessmentDateSystem, false);
        if (ConfigService.UseBarthelIndexForFlag5) {
            if (!latestAssessmentDateCoding) {
                const mark5 = patient.mark(5);
                if (mark5) {
                    mark5.yellow = false;
                    mark5.red = true;
                    mark5.checked = true;
                }
            } else {
                // this patient has a flag for latestAssessment
                this.ReadMark5(patient);

                if (patient.latestAssessment && patient.questionnaireResponses.length > 4) // assessment + anamnesis + bi + biEx = 4
                    await this.ValidateMark5(patient, false);
            }
        }
        //#endregion

        //#region get the correct marker values for mark_10
        if (ConfigService.UseAssessmentForSignal10) {
            const formSettingAssessment = ConfigService.GetFormSettings('assessment');
            const mark10 = patient.mark(10);
            let flagSystem = `latest${formSettingAssessment.questionnaireName}Date`;
            const flag10 = Fhir.Tools.GetOrCreateFlag(patient.flags, flagSystem, false);
            if (mark10 && flag10) {
                const redAfter = formSettingAssessment.expiration.durations.redAfter || 23;
                const yellowAfter = formSettingAssessment.expiration.durations.yellowAfter || 12;

                const age = Math.abs(new moment(new Date(flag10.code)).diff(new Date(), 'hours'));
                mark10.checked = true;
                mark10.yellow = age > yellowAfter;
                mark10.red = age > redAfter;
            }
        }
        //#endregion

        //#region get the correct isolation marker color for mark_1:
        if (ConfigService.UseIsolationForFlag1) {
            this.ReadMark1(patient);
        }
        //#endregion

        //#region read sub information like department and diagnosis if they are contained in the encounter
        if (patient.encounter) {
            //#region load the department
            if (patient.encounter.serviceProvider && patient.encounter.serviceProvider.reference) {
                let provider = await OrganizationService.Get(patient.encounter.serviceProvider.reference);

                if (provider) {
                    patient.departmentIdentifier = patient.department = provider.name || '';
                    if (provider.identifier) {
                        let providerId = provider.identifier.find(o => o.system.endsWith('organizationDepartments'));
                        if (providerId && providerId.value) {
                            patient.departmentIdentifier = providerId.value;
                        }
                    }
                }
            }
            //#endregion

            //#region process the diagnosis
            if (patient && patient.encounter && patient.encounter.contained && patient.encounter.diagnosis) {
                patient.diagnosis = [];
                for (let i = 0; i < patient.encounter.diagnosis.length; i++) {
                    let diagnosis: any = patient.encounter.diagnosis[i];
                    let condition: any = diagnosis["condition"];
                    if (condition.reference) {
                        let contained: any = patient.encounter.contained.find(o => o.resourceType === fhirEnums.ResourceType.condition
                            && `#${o.id.trim()}` === condition.reference.trim()
                        );

                        let tmpDiag: IDiagnosis = {
                            id: contained.id,
                            rank: diagnosis?.rank,
                            roleText: diagnosis?.role?.text
                        };

                        if (contained?.code?.coding) {
                            let code = contained.code.coding.find(o => o.system?.indexOf('I10-') > -1);
                            if (code) {
                                try {
                                    tmpDiag.code = code.code.trim().replace(/ {2}/g, " ");
                                    tmpDiag.display = (code.display || code.code || code.system).trim();

                                    patient.diagnosis.push(tmpDiag);
                                } catch (e) {
                                    console.warn(e.message || e);
                                }
                            }
                        }
                    }
                }

                patient.diagnosis.sort((a: IDiagnosis, b: IDiagnosis) => {
                    return a.rank - b.rank;
                });
            }
            //#endregion
        }
        //#endregion

        //#region read Insurance and some ISIK Information (see also: https://simplifier.net/guide/implementierungsleitfadenisik-basismodul/ImplementationGuide-markdown-Datenobjekte-Datenobjekte-Patient?version=current )
        // get the INSURANCE itself from extension if exists:
        if (!patient.insuranceName && patient.extension) {
            const insuranceExtension = patient.extension.find(o => o.url && (o.url.endsWith('/insurance') || o.url.endsWith('/kv')));
            if (insuranceExtension) {
                if (insuranceExtension.valueCoding?.display) {
                    patient.insuranceName = insuranceExtension.valueCoding.display;
                } else if (insuranceExtension.valueString) {
                    patient.insuranceName = insuranceExtension.valueString;
                }
            }
        }

        // get the patient insurance NUMBER from the identifier
        if (!patient.insuranceNumber && NitTools.IsArray(patient.identifier)) {
            // find the identifier which either has a code "VN" or "PKV" or "GKV" as given in type property
            let insuranceIdentifier = patient.identifier.find(o => o.type?.coding && o.type.coding.find(c => c.code == "VN" || c.code == "PKV" || c.code == "GKV"));
            if (!insuranceIdentifier) // short version as in ISIK standard. see: https://ig.fhir.de/basisprofile-de/1.2.0/GesetzlicheKrankenversichertennummer10-stelligeKVID-Identifier.html
                insuranceIdentifier = patient.identifier?.find(o => o.system && o.system.endsWith('/kvid-10')); //

            if (insuranceIdentifier?.value) {
                patient.insuranceNumber = insuranceIdentifier.value;
            }

            // if the insurance name has not been set yet, it MAY exist in the assigner property
            if (!patient.insuranceName && insuranceIdentifier?.assigner?.display) {
                patient.insuranceName = insuranceIdentifier.assigner?.display
            }
        }

        //#region get the simple patient number if not existent
        if (!patient.patientNumber && NitTools.IsArray(patient?.identifier)) {
            const identifier = patient.identifier.find(o => o.system && o.system.indexOf('/patientNumber') > -1);
            if (identifier && identifier.value) {
                patient.patientNumber = identifier.value;
            }
        }
        //#endregion

        // the isik patient number
        if (!patient.patientNumber && NitTools.IsArray(patient.identifier)) {
            const numberIdentifier = patient.identifier.find(o => o.type?.coding && o.type.coding.find(c => c.code == "MR"));
            if (numberIdentifier?.value) {
                patient.patientNumber = numberIdentifier.value;
            }
        }

        //#endregion

        //#region generate patient.text.div
        Fhir.Tools.GeneratePatientTextObject(patient);
        //#endregion
    }

    public static async EnsureFlags(patient: PatientItem) {
        const that = this;
        function createCareLevelItem(patient: PatientItem, linkId: string) {
            if (patient.flags) that.FixFlags([patient.flags]);
            if (patient.latestAssessment && patient.flags) {
                let responseItem = QuestionnaireResponse.GetResponseItemByLinkId(patient.latestAssessment, linkId, false);
                if (responseItem) {
                    let flagCodeItem = {
                        system: `${NitTools.ExcludeTrailingSlash(RuntimeInfo.SystemHeader)}/${linkId}`,
                        code: String(QuestionnaireResponse.GetResponseItemValue(responseItem))
                    };

                    patient.flags.code.coding.push(flagCodeItem);
                }
            }
        }

        if (typeof patient.flags === "undefined") {
            patient.flags = (await this.LoadFlags(patient.encounterId))[0];
            const needsUpdate = !patient.flags;
            if (needsUpdate) {
                patient.flags = {
                    encounter: {reference: 'Encounter/' + patient.encounterId},
                    status: 'active',
                    id: NitTools.Uid(),
                    resourceType: "Flag",
                    subject: {reference: `Patient/${patient.id}`},
                    identifier: [{system: `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks`, value: "marks"}],
                    code: {coding: []}
                };
            }

            for (const m of patient.marks) {
                const coding: any = {
                    system: `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/mark_${m.index}`,
                    code: ((m.index === 5 && ConfigService.UseBarthelIndexForFlag5) || (m.index === 10 && ConfigService.UseAssessmentForSignal10) ? 'true' : 'false')
                }

                const codingY: any = {
                    system: `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/mark_${m.index}_yellow`,
                    code: 'false'
                }

                const codingR: any = {
                    system: `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/mark_${m.index}_red`,
                    code: ((m.index === 5 && ConfigService.UseBarthelIndexForFlag5) || (m.index === 10 && ConfigService.UseAssessmentForSignal10)) ? 'true' : 'false'
                }

                patient.flags.code.coding.push(...[coding, codingY, codingR]);
            }

            if (patient.latestAssessment && patient.assessmentName) {
                patient.flags.code.coding.push({
                    system: NitTools.ExcludeTrailingSlash(RuntimeInfo.SystemHeader) + '/latest' + patient.assessmentName + 'Date',
                    code: patient.latestAssessment.authored
                })

                createCareLevelItem(patient, 'CareLevel');
                createCareLevelItem(patient, 'CareLevelColor');
                createCareLevelItem(patient, 'CareLevelString');
            }

            if (needsUpdate) {
                // fire & forget
                Fhir.Rest.Create(patient.flags)
                    .catch(e => console.warn(e));

                if (ConfigService.Debug)
                    console.warn("Ensuring Flags - created new Flag for: " + patient.display, patient.flags);
            }
        }

        this.ReadMark1(patient);
        this.ReadMark5(patient);
        this.CalculateMark10(patient);
    }

    public static async Load(encounterId: string, force: boolean = false): Promise<PatientItem> {
        await FhirService.EnsureFhirVersion();
        await this.waitForLoading();

        if (!force) {
            if (this.SelectedPatient && this.SelectedPatient.encounter && this.SelectedPatient.encounter.id === encounterId) {
                this.__isLoading = false;
                return this.SelectedPatient;
            }

            if (this.LastLoadedPatient && this.LastLoadedPatient.encounter && this.LastLoadedPatient.encounter.id === encounterId) {
                this.__isLoading = false;
                return this.LastLoadedPatient;
            }
        }

        this.__isLoading = true;
        let result: PatientItem = undefined;

        // try {
        if (!encounterId) return result;

        await QuestionnaireService.Fetch();

        encounterId = Fhir.Tools.StripId(encounterId);
        let url = `Encounter?_id=${encounterId}&_include=Encounter:patient&_include=Encounter:location&_revinclude=QuestionnaireResponse:${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}&_revinclude=RiskAssessment:encounter`;

        const fhirService = new FhirService();
        const bundleItems = await fhirService.fetch(url, true);   // gets all Resources in one array
        const encounter: any = <any>bundleItems.find(o => o.resourceType === 'Encounter'); // get Encounter-Array
        const patient: any = <any>bundleItems.find(o => o.resourceType === 'Patient'); // get Patient-Array
        if (!patient) return result;

        const flags = await this.LoadFlags(encounterId);
        patient.flags = flags[0]; // <any[]>bundleItems.filter(o => o.resourceType === 'Flag' && o.identifier); // get Flag-Array

        const locations: any[] = <any[]>bundleItems.filter(o => o.resourceType === 'Location' &&
            o.physicalType && o.physicalType.coding && o.physicalType.coding[0] && o.physicalType.coding[0].code); // get Location-Array
        const responses = <any[]>bundleItems.filter(o => o.resourceType === 'QuestionnaireResponse');
        const riskAssessments = <any[]>bundleItems.filter(o => o.resourceType === 'RiskAssessment');

        if (FhirService.FhirVersion > 3) {
            // get the counts for CAVE entries
            // noinspection ES6MissingAwait
            if (RuntimeInfo.Features?.cave?.enabled === true) {
                const getCaveCount = async function (path) {
                    let baseUrl = `Observation?patient=${patient.id}&category=http://nursit-institute.de/StructureDefinition/cave|${path}&_summary=count`
                    const bundle = await Fhir.Rest.Get(baseUrl);
                    if (path === 'C')
                        result.contraindicationsCount = bundle.total;
                    else if (path === 'V')
                        result.valuesCount = bundle.total;
                    else if (path === 'E')
                        result.equipmentCount = bundle.total;
                }

                getCaveCount('C');
                getCaveCount('V');
                getCaveCount('E');
            }

            // we need to fix the extensions in response.items that have a valueDate but include a timestamp
            const updateResponses = [];

            for (const r of responses) {
                if (r.item) {
                    let needsUpdate = false;
                    const linkIds = Tools.GetAllResponseLinkIds(r);
                    for (const linkId of linkIds) {
                        const item = QuestionnaireResponse.GetResponseItemByLinkId(r, linkId);
                        if (item?.extension) {
                            for (const extention of item.extension) {
                                if (extention.valueDate && extention.valueDate.indexOf('T') > -1) // it is a timestamp
                                {   // move the timestamp from valueDate to valueDateTime and remove valueDate from the extension
                                    extention.valueDateTime = extention.valueDate;
                                    delete extention.valueDate;
                                    needsUpdate = true;
                                }
                            }
                        }
                    }

                    if (needsUpdate) {
                        updateResponses.push(r);
                    }
                }
            }

            if (updateResponses.length > 0) {
                console.debug(`Saving ${updateResponses.length} fixed valueDate->valueDatetime responses`);
                try {
                    await Fhir.Rest.Bundle(updateResponses, HTTPVerb.put);
                } catch (ex) {
                    console.warn(`Saving fixed responses failed with error: ${ex}`);
                }
            }
        }

        // add all locations to locationService
        locations.forEach(l => LocationService.InsertLocation(l));

        result = this.__patients.find(o => o.encounterId === encounterId) || new PatientItem(patient);
        result.questionnaireResponses = responses;
        result.encounter = encounter;

        if (result && result.encounter && result.encounter.location) {
            for (const location of result.encounter.location.filter(o => !o.status)) {
                if (!location.status && ConfigService.cfg && ConfigService.cfg.features && ConfigService.cfg.features.assumeLocationsWithoutStatusAs)
                    location.status = ConfigService.cfg.features.assumeLocationsWithoutStatusAs;
            }

            result.encounter.location = result.encounter.location.filter(o => ['planned', 'reserved', 'completed'].indexOf(o.status) === -1);
        }

        result.encounterId = result.encounter ? result.encounter.id : undefined;
        result.flags = this.SearchForMarksFlag(flags);

        if (!result.flags && result.encounterId) {
            await this.EnsureFlags(result);
        }

        this.FixFlags(flags);

        // let the newest riskAssessment be at position 0 if > 1
        riskAssessments.sort((a, b) => {
            try {
                const startA = a.occurrencePeriod && a.occurrencePeriod.start ? a.occurrencePeriod.start : a.occurrenceDateTime;
                const startB = b.occurrencePeriod && b.occurrencePeriod.start ? b.occurrencePeriod.start : b.occurrenceDateTime;
                const dateA = new Date(startA);
                const dateB = new Date(startB);

                return dateB.valueOf() - dateA.valueOf();
            } catch (e) {
                console.warn(e.message || e);

                return 0;
            }
        })

        result.riskAssessments = riskAssessments;
        result.currentRisks = riskAssessments[0];

        result.genderDisplay = this.i18n.tr(`gender_${result.gender ? result.gender : 'unknown'}`);

        // in there we load the configoverride, so it should be save to call the GetFormSettings methods
        await this.ReadAndCalculateMarksAndVisitNumber(result);
        this.UpdatePatientDischargePct(result, this.i18n);

        PatientItem.AssignLocations(result);
        await ConfigService.LoadConfigOverride(result?.ward, result);
        PatientItem.ReadRelations(result);

        if (patient?.birthDate) {
            const bd = moment(new Date(patient.birthDate));
            result.birthDateDisplay = bd.format(RuntimeInfo.DateFormat);
            result.days = moment(new Date()).diff(bd, 'days', false);
        }

        result.assessmentName = result.getAssessmentName();
        result.anamnesisName = result.getAnamnesisName();

        this.LastLoadedPatient = result;

        PatientItem.HighlightRelatedEncounters(result);

        await Allergy.LoadForPatient(result, fhirService, this.i18n);
        await this.LoadRelatedPersons(result);

        this.__isLoading = false;

        return result;
    }

    static async LoadRelatedPersons(patient : PatientItem) {
        if (!patient?.id) return;        
        const allRelatedPersons = await Fhir.Rest.Fetch(`RelatedPerson?patient=${patient.id}`)
        // assume person to be active when nothing else has been specified
        for (const rp of allRelatedPersons) {
            if (typeof rp.active === "undefined")
                rp.active = true;
        }

        patient.relatedPersons = { };

        const groupCodes : string[] = [];
        const displays : string[] = [];
        for (const rp of allRelatedPersons.filter(o=>o.active === true && NitTools.IsArray(o.name))) {
            const display = HumanName.GetText(rp.name);

            if (display) {
                displays.push(display);

                // the kind of relationShip is a coding, see https://hl7.org/fhir/R4/valueset-relatedperson-relationshiptype.html
                if (!NitTools.IsArray(rp.relationship)) { 
                    // none found, so assign them to be "Unknown" (code: "U")
                    rp.relationship = [
                        { 
                            coding: [
                                { 
                                    code: "U",
                                    display: "Unknown",
                                    system: "http://terminology.hl7.org/CodeSystem/v2-0131"
                                }
                            ]
                        }
                    ]
                }

                for (const rs of rp.relationship.filter(o=>NitTools.IsArray(o.coding) && 
                                                        o.coding?.[0]?.code && 
                                                        o.coding?.[0]?.code !== "I" && // no Insurance Company
                                                        o.coding?.[0]?.code !== "F" && // no Federal Agency
                                                        o.coding?.[0]?.code !== "E" && // no Employer
                                                        o.coding?.[0]?.code !== "S")   // no Stat Agency
                    ) {                    
                    const code = rs.coding[0].code;
                    if (!code) continue;
                    if (groupCodes.indexOf(code) === -1) groupCodes.push(code);

                    if (!NitTools.IsArray(patient.relatedPersons[code]?.items))  {
                        patient.relatedPersons[code] = { items: [ rp ] }
                    } else {
                        patient.relatedPersons[code].items.push(rp);
                    }
                }
            }
        }

        const phones : string[] = [];
        // create a display for each coding group
        for (const code of groupCodes) {
            const currentGroup : IRelatedPersonInfo = patient.relatedPersons[code];            
            if (!NitTools.IsArray(currentGroup?.items)) continue;
            
            const phonesCodeGroup : string[] = []; // holds all phone numbers from all persons in this group            
            const currentDisplays : string[] = [];
            let defaultAddress : string;
            for (const personItem of currentGroup.items) {
                // fetch the phone number (s)
                if (NitTools.IsArray(personItem.telecom)) {
                    const telecoms = personItem.telecom.filter(o=>o.system === "phone" && o.value);
                    const phoneLines : string[] = [];
                    for (const telecom of telecoms) {
                        let phone = telecom.value;
                        if (telecom.use) {
                            phone = `${phone} (${telecom.use})`
                        }

                        phoneLines.push(phone);
                    }

                    personItem.phone = phoneLines.join(', ');
                    if (personItem.phone) {
                        phones.push(personItem.phone);
                        phonesCodeGroup.push(personItem.phone);
                    }
                }

                currentGroup.phone = phonesCodeGroup.join(', '); // all phone numbers from this group
                const display = HumanName.GetText(personItem.name);                
                currentGroup.display = display;
                currentDisplays.push(display);
                if (!currentGroup.default) {
                    currentGroup.default = { display: display, phone: currentGroup.phone, address: '' }
                    if (NitTools.IsArray(personItem.address)) {
                        const adrArray : fhir4.Address[] = personItem.address;
                        const address = adrArray.find(a => a.use === "home") ||
                                        adrArray.find(a => a.use === "temp") || 
                                        adrArray.find(a => a.use === "billing") || 
                                        adrArray.find(a => a.use === "work") || 
                                        adrArray[0];

                        if (address) {
                            if (address.text) {
                                defaultAddress = address.text;
                            } else {
                                defaultAddress = `${address.line.join(', ')}, ${address.postalCode} ${[address.city, address.district, address.state, address.country].filter(o=>o).join(', ')}`;
                            }
                        }
                    }

                    if (defaultAddress) {
                        currentGroup.default.address = defaultAddress?.replace(/,\ ,/g, '');
                    }
                }
            }

            currentGroup.display = currentDisplays.join('; '); // set the group display to be a join of all displays
        }

        delete patient.relatedPersons.display; //  = displays.join('; ');
        delete patient.relatedPersons.phone; // = phones.join(', ');

        patient.relatedPersons.default = { display: '', phone: '', address: '' };
        if (groupCodes.length > 0) {
            // do some sorting of importance:
            const first =  patient.relatedPersons["N"]?.default || // Next-of-Kin
            patient.relatedPersons["C"]?.default ||     // Emergency contact
            patient.relatedPersons["SPS"]?.default ||   // Spouse
            patient.relatedPersons["HUSB"]?.default ||  // Husband
            patient.relatedPersons["WIFE"]?.default ||  // Wife
            patient.relatedPersons["PRN"]?.default ||   // Parent
            patient.relatedPersons["NFTH"]?.default ||  // Natural Father
            patient.relatedPersons["MTH"]?.default ||   // Natural Mother
            patient.relatedPersons["CHILD"]?.default || // Child
            patient.relatedPersons["SON"]?.default ||   // Son
            patient.relatedPersons["DAU"]?.default ||   // Daughter
            patient.relatedPersons[groupCodes[0]]?.default;

            if (first) {
                patient.relatedPersons.default = NitTools.Clone(first)
            }
        }
    }

    static HighlightRelatedEncounters(patient?: PatientItem) {
        window.setTimeout(() => {
            if (!patient)
                patient = PatientItem.SelectedPatient;

            try {
                const secondaryHighlights = document.querySelectorAll(".router-left ul li[data-encounter-id]");
                if (secondaryHighlights) {
                    for (let i = 0; i < secondaryHighlights.length; i++) {
                        const ele = secondaryHighlights[i];
                        if (ele) {
                            ele.classList.remove("secondary-highlight");
                        }
                    }
                }
            } catch (ex) {
                if (ConfigService.Debug)
                    console.debug('Error in removing secondary highlights: ' + ex);
            }

            if (patient && (patient.isMother || patient.isBaby)) {
                let highlight: string[] = NitTools.Clone(patient.childrenPatients);
                if (patient.motherPatient)
                    highlight.push(patient.motherPatient);

                for (const idString of highlight) {
                    const ele = document.querySelector(`.router-left ul li[data-encounter-id="${idString}"`);
                    if (ele) {
                        ele.classList.add("secondary-highlight");
                    }
                }
            }
        }, 250);
    }

    /**
     * Get the Mother-Child relationship
     * @param patient PatientItem to parse
     * @returns 
     */
    public static ReadRelations(patient: PatientItem) {
        patient.isBaby = false;
        patient.isMother = false;
        patient.childrenAssigned = false;
        patient.motherAssigned = false;

        if (!ConfigService.EncounterTypeChoice?.enabled)
            return;

        patient.isBaby = patient.years <= 1;
        patient.isMother = patient.gender === 'female' && !patient.isBaby;

        if (patient.link) {
            // get the link to the mother if exists in the patient.link property
            let linkToMotherOnChildPatient: any = patient.link.find(o => o.type === 'refer' && o.other.extension && o.other.extension.find(m => m.url === PatientItem.RelationSystem && m.valueString === 'mother'));
            if (linkToMotherOnChildPatient && linkToMotherOnChildPatient.other && linkToMotherOnChildPatient.other.reference) {
                patient._motherPatient = Fhir.Tools.StripId(linkToMotherOnChildPatient.other.reference);
                patient.isBaby = true;
            }

            // get the link to the children
            let linkToChildrenOnMotherPatient: any[] = patient.link.filter(o => o.type === 'refer' &&
                o.other && o.other.reference &&
                o.other.extension && o.other.extension.find(m => m.url === PatientItem.RelationSystem && m.valueString === 'child'));

            patient._childrenPatients = [];
            if (linkToChildrenOnMotherPatient && linkToChildrenOnMotherPatient.length > 0) {
                patient.isMother = true;
                for (const link of linkToChildrenOnMotherPatient) {
                    patient._childrenPatients.push(Fhir.Tools.StripId(link.other.reference));
                }
            }
        }

        patient.motherAssigned = !!patient.motherPatient;
        patient.childrenAssigned = patient._childrenPatients && patient._childrenPatients.length > 0;
    }

    /***
     * ensures the existence of a media resource containing the current spi-spider. is called from the patient-spi-spider-web component, updateSpiderSvg and sempa-analyzers.analyze()
     */
    public static async __ensureSpiderSvg(patient: PatientItem, forceResourceUpdate: boolean = false): Promise<SVGImageElement> {
        if (!patient || !patient.latestAssessment) return undefined;

        await this.waitForLoading();
        this.__isLoading = true;

        let svg: SVGImageElement;
        try {
            if ((!patient.spiSpiderMedia || forceResourceUpdate) && patient.latestAssessment && ['completed', 'amended'].indexOf(patient.latestAssessment.status) > -1) {
                let mediaBackup: string;
                if (patient.spiSpiderMedia && patient.spiSpiderMedia.content && patient.spiSpiderMedia.content.data) {
                    mediaBackup = String(patient.spiSpiderMedia.content.data);
                }

                const system = `${NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase)}/fhir/StructureDefinition/spi-spider-svg`;
                const client = Fhir.Rest.GetClient();
                const url = `Media?identifier=${encodeURIComponent(system)}|${patient.encounterId}`;
                let media: any;
                const httpResponse = await client.createRequest(url).asGet().send()
                let mediaBundle: any;

                try {
                    mediaBundle = <any>JSON.parse(httpResponse.response);
                } catch (ex) {
                    console.warn(ex);
                    mediaBundle = undefined;
                }

                if (mediaBundle && mediaBundle.entry) {
                    // remove multiple entries - only one can exist per encounter
                    if (mediaBundle.total && mediaBundle.total > 1) {
                        if (ConfigService.Debug)
                            console.debug('Removing multiple entries of spi-spider');

                        const toDelete = mediaBundle.entry.map(o => o.resource);
                        await Fhir.Rest.Bundle(toDelete, fhirEnums.HTTPVerb.delete, fhirEnums.BundleType.transaction);
                        mediaBundle = undefined;
                        media = undefined;
                    }

                    if (mediaBundle && mediaBundle.entry[0] && mediaBundle.entry[0].resource) {
                        media = <any>mediaBundle.entry[0].resource;
                        patient.spiSpiderMedia = media;
                    }
                }

                if (true) { // (!media || forceResourceUpdate) {
                    const analyzerVersion = await AnalyzeService.GetAnalyzerVersion(patient, patient.latestAssessment)
                    const analyzer = AnalyzeService.GetAnalyzer(analyzerVersion);
                    if (analyzer) {
                        if (ConfigService.Debug)
                            console.debug('Creating a new spi-spider for encounter');

                        svg = await analyzer.GetSPISpider(patient);
                        if (svg) {
                            const rect = svg.getBoundingClientRect();

                            const content: any = {
                                contentType: 'Image/svg+xml',
                                language: 'de-DE',
                                data: btoa(svg.outerHTML),
                                title: `${patient.display}, SPI-Spinnen-Diagramm`,
                                creation: new Date().toJSON()
                            };

                            media = {
                                type: 'photo',
                                identifier: [{
                                    system: system,
                                    value: patient.encounterId
                                },
                                    {
                                        system: `${NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase)}/questionnaire-link/${patient.latestAssessment.id}`,
                                        value: 'Assessment'
                                    }],
                                subject: {reference: `Patient/${patient.id}`},
                                frames: 1,
                                text: {
                                    div: `<div xmlns="http://www.w3.org/1999/xhtml">${patient.display}<br /><b>Pflegeindex</b></div>`,
                                    status: 'generated'
                                },
                                id: (forceResourceUpdate && media && media.id) ? media.id : NitTools.Uid(),
                                resourceType: 'Media',
                                content: content,
                                width: rect.width,
                                height: rect.height
                            }

                            let encounterName = 'context';
                            let subTypeName = 'subtype';
                            if (FhirService.FhirVersion > 3) {
                                encounterName = 'encounter';
                                subTypeName = 'modality';
                            }
                            media[encounterName] = {reference: `Encounter/${patient.encounterId}`};
                            media[subTypeName] = {
                                coding: [{
                                    system: "http://hl7.org/fhir/media-subtype",
                                    code: "diagram"
                                }]
                            };

                            if (mediaBackup !== content.data) {
                                Fhir.Rest.Update(media, false);
                            } else {
                                if (ConfigService.Debug)
                                    console.debug('Spider SVG will be the same. Update skipped')
                            }

                            patient.spiSpiderMedia = media;
                        }
                    }
                }
            }

            if (patient.spiSpiderMedia && patient.spiSpiderMedia.content) {
                const svgString = atob(patient.spiSpiderMedia.content.data);
                const parser = document.createElement('div');
                parser.innerHTML = svgString;
                svg = <SVGImageElement><any>parser.firstChild;
            }
        } catch (ex) {
            console.warn(ex);
        } finally {
            this.__isLoading = false;
        }

        return svg;
    }

    public static async ValidateMark5(patient: PatientItem, updateFlagsResourceOnFhir: boolean = false): Promise<string[]> {
        // we will be setting the patient-properties for mark5, but return the hint-texts to display under the "Signalreiter"
        if (!patient || !patient.flags) {
            console.warn("Patient hat keine Flags!");
            return;
        }

        await ConfigService.LoadConfigOverride(patient.ward, patient);
        const configBI = ConfigService.GetFormSettings('barthelindex');
        const configBiEx = ConfigService.GetFormSettings('barthelindexEx');
        let forceUpdateFlag = false;
        const flagBackup = JSON.stringify(patient.flags.code.coding);
        const mark5 = patient.mark(5);

        if (ConfigService.UseBarthelIndexForFlag5) {
            mark5.checked = true;
        } else {
            mark5.red = false;
            mark5.yellow = false;
            mark5.updateCssClass();
        }

        const textArr = [];

        if (!patient.latestAssessment) {
            const qa = QuestionnaireService.GetQuestionnaireByNameDirect(patient.getAssessmentName());
            patient.latestAssessment = QuestionnaireService.GetLatestResponseOfType(patient, qa.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        }

        if (ConfigService.UseBarthelIndexForFlag5 && !patient.latestAssessment) {
            // return ['no_assessment'];  this hint is not needed because it is added in the signalreiter itself
            mark5.yellow = false;
            mark5.checked = true;
            mark5.red = true;
            return [];
        }

        let bi = await Fhir.QuestionnaireResponse.SeekForAttachedResponse(patient, 'barthelindex', 'BarthelIndex', patient.latestAssessment, false);
        let biEx = await Fhir.QuestionnaireResponse.SeekForAttachedResponse(patient, 'barthelindexEx', 'BarthelIndexEx', patient.latestAssessment, false);

        if (!bi) textArr.push('no_bi');
        if (!biEx) textArr.push('no_biEx');

        if (bi && (['amended', 'completed'].indexOf(bi.status) === -1)) textArr.push('bi_not_validated');
        if (biEx && (['amended', 'completed'].indexOf(biEx.status) === -1)) textArr.push('bi_ex_not_validated');

        let flgFall = Fhir.Tools.GetOrCreateFlag(patient.flags, 'RiskSturz', false);
        let flgVdd = Fhir.Tools.GetOrCreateFlag(patient.flags, 'RiskVdd', false);

        //#region try to get the values from the riskassessment
        if (!flgFall) {
            // let riskFall = patient.currentRisks ? patient.currentRisks.prediction.find(p => p.outcome && p.outcome.coding && p.outcome.coding[0] && p.outcome.coding[0].system.endsWith('/risk_sturz')) : undefined;
            let riskFall = patient.currentRisks?.prediction?.find(p => p.outcome?.coding && p.outcome.coding[0] && p.outcome.coding[0].system?.endsWith('/risk_sturz'));
            if (!riskFall)
                riskFall = patient.currentRisks?.prediction?.find(p => p.outcome?.coding && p.outcome.coding[0] && p.outcome.coding[0].system?.endsWith('/risk_fall'));

            if (riskFall) {
                flgFall = Fhir.Tools.GetOrCreateFlag(patient.flags, 'RiskSturz', true);
                flgFall.code = String(riskFall.outcome.coding[0].code);
            }
        }

        if (!flgVdd) {
            const riskVdd = patient.currentRisks?.prediction?.find(p => p.outcome?.coding && p.outcome.coding[0] && p.outcome.coding[0].system?.endsWith('/risk_vdd'));
            if (riskVdd) {
                flgVdd = Fhir.Tools.GetOrCreateFlag(patient.flags, 'RiskVdd', true);
                flgVdd.code = String(riskVdd.outcome.coding[0].code)
            }
        }
        //#endregion


        //#region workaround for non existent flags - create them from latest Assessment if present
        if (!flgFall && patient.latestAssessment) {
            const itemRiskFall = QuestionnaireResponse.GetResponseItemByLinkId(patient.latestAssessment, 'risk_sturz', false);
            if (itemRiskFall) {
                if (typeof itemRiskFall !== "undefined" && NitTools.IsArray(itemRiskFall.answer) && itemRiskFall.answer[0] && typeof itemRiskFall.answer[0].valueBoolean !== "undefined") {
                    const valueRiskFall = NitTools.ParseBool(itemRiskFall.answer[0].valueBoolean);
                    flgFall = Fhir.Tools.GetOrCreateFlag(patient.flags, 'RiskSturz', true);
                    flgFall.code = String(valueRiskFall);
                    forceUpdateFlag = true;
                }
            }
        }

        if (!flgVdd && patient.latestAssessment) {
            const itemRiskVdd = QuestionnaireResponse.GetResponseItemByLinkId(patient.latestAssessment, 'risk_vdd', false);
            if (itemRiskVdd) {
                if (typeof itemRiskVdd !== "undefined" && NitTools.IsArray(itemRiskVdd.answer) && itemRiskVdd.answer[0] && typeof itemRiskVdd.answer[0].valueBoolean !== "undefined") {
                    const valueRiskVdd = NitTools.ParseBool(itemRiskVdd.answer[0].valueBoolean);
                    flgVdd = Fhir.Tools.GetOrCreateFlag(patient.flags, 'RiskVdd', true);
                    flgVdd.code = String(valueRiskVdd);
                    forceUpdateFlag = true;
                }
            }
        }
        //#endregion

        const questionnaireBI = QuestionnaireService.GetQuestionnaireByNameDirect(configBI?.questionnaireName);
        const questionnaireBiEx = QuestionnaireService.GetQuestionnaireByNameDirect(configBiEx?.questionnaireName);
        const biResponses = QuestionnaireService.GetResponsesOfType(patient, questionnaireBI?.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        const biExResponses = QuestionnaireService.GetResponsesOfType(patient, questionnaireBiEx?.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);

        if (ConfigService.UseBarthelIndexForFlag5) {
            if (biResponses.length === 0 || biExResponses.length === 0) { // when there is not any validated bi/Ex then the mark has to be red
                mark5.red = true;
                mark5.yellow = false;
                mark5.checked = true;
            } else {
                if (
                    // bi and biEx exist and both have been validated: green and exit
                    (bi && (['amended', 'completed'].indexOf(bi.status) > -1)) &&
                    (biEx && (['amended', 'completed'].indexOf(biEx.status) > -1))
                ) {
                    mark5.yellow = false;
                    mark5.red = false;
                    mark5.checked = true;
                } else {
                    if (patient.latestAssessment && bi && biEx) {   // if Barthel-Documents exist, but are NOT validated ..
                        /*  see #1388, https://nursiti.plan.io/issues/1388#note-13
                        6. Gelb - Ein validiertes BI oder eBI liegt vor aber nicht für den aktuelle Assessment, sondern für irgendeins davor
                            - bei BI keine Änderung bei Sturzrisiko vorhanden. (zeig als Info)
                            - bei EBI keine Änderung bei Verwirrtheit-Delir-Demenz vorhanden. (zeig als Info)

                        7. Rot Ein validiertes BI oder eBI liegt vor aber nicht für den aktuelle Assessment, sondern für irgendeins davor
                            - bei BI Änderung bei Sturzrisiko vorhanden. (zeig als Info)
                            - bei EBI Änderung bei Verwirrtheit-Delir-Demenz vorhanden. (zeig als Info)
                         */

                        if (['amended', 'completed'].indexOf(patient.latestAssessment.status) > -1) // should be always on latestAssessment, but don't trust anything
                        {
                            // .. check the state of the bi/biEx responses
                            const afStatus = ['completed', 'amended'];
                            if (afStatus.indexOf(bi.status) > -1 && afStatus.indexOf(biEx.status) > -1) {   // when both are amended or completed then flag5 has to be green
                                mark5.red = false;
                                mark5.yellow = true;
                            } else {
                                // .. get the RiskFall-Flag from patient.flags ..
                                if (flgFall && typeof flgFall.code !== 'undefined') {
                                    const bFlagFallValue = NitTools.ParseBool(flgFall.code);

                                    // .. get the RiskVdd-Flag from patient.flags ..
                                    //flgVdd = Fhir.Tools.GetOrCreateFlag(patient.flags, 'RiskVdd', false);
                                    if (flgVdd && typeof flgVdd.code !== 'undefined') {
                                        const bFlagVddValue = NitTools.ParseBool(flgVdd.code);

                                        // .. if both exist, bi and biEx have been validated for another assessment before ..
                                        if (flgVdd && flgFall) {
                                            // .. get the risk_sturz item from assessment
                                            const qrItemRiskFall = QuestionnaireResponse.GetResponseItemByLinkId(patient.latestAssessment, 'risk_sturz', false);
                                            const bItemRiskFall = NitTools.ParseBool(QuestionnaireResponse.GetResponseItemValue(qrItemRiskFall));

                                            // .. get the risk_vdd item from assessment
                                            const qrItemRiskVDD = QuestionnaireResponse.GetResponseItemByLinkId(patient.latestAssessment, 'risk_vdd', false);
                                            const bItemRiskVdd = NitTools.ParseBool(QuestionnaireResponse.GetResponseItemValue(qrItemRiskVDD));

                                            // .. compare the assessment-items to the flags items ..
                                            if (bItemRiskFall === bFlagFallValue && bItemRiskVdd === bFlagVddValue) {
                                                // .. if they are equal, turn to yellow
                                                // patient.mark_5_class = 'active-yellow';
                                                mark5.red = false;
                                                mark5.yellow = true;
                                                if (bItemRiskFall === bFlagFallValue) textArr.push('risk_sturz_same_values');
                                                if (bItemRiskVdd === bFlagVddValue) textArr.push('risk_vdd_same_value');
                                            } else {
                                                // .. or let the default red remain
                                                if (bItemRiskFall != bFlagFallValue) textArr.push('risk_sturz_changed');
                                                if (bItemRiskVdd != bFlagVddValue) textArr.push('risk_vdd_changed');
                                                mark5.yellow = false;
                                                mark5.red = true;
                                            }
                                        }
                                        /* else {
                                            // .. EXIT (stying red) if not both exist, because that means there have never been validated Barthel-documents
                                        } */
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        let mark5Flag = Fhir.Tools.GetOrCreateFlag(patient.flags, 'mark_5');
        if (ConfigService.UseBarthelIndexForFlag5) {
            mark5Flag.code = 'true';
        } else {
            mark5Flag.code = mark5.checked ? 'true' : 'false';
        }

        let mark5RedFlag = Fhir.Tools.GetOrCreateFlag(patient.flags, 'mark_5_red');
        mark5RedFlag.code = mark5.red ? 'true' : 'false';

        let mark5YellowFlag = Fhir.Tools.GetOrCreateFlag(patient.flags, 'mark_5_yellow');
        mark5YellowFlag.code = mark5.yellow ? 'true' : 'false';

        //#region store in flags, maybe store flags to fhir and return the warnings
        if (patient.flags && patient.flags.code && patient.flags.code.coding) {
            if (textArr.length === 0) {
                Fhir.Tools.DeleteFlag(patient.flags, 'mark_5_text');
            } else {
                let mark5TextFlag = Fhir.Tools.GetOrCreateFlag(patient.flags, 'mark_5_text');
                mark5TextFlag.code = textArr.join(',');
            }

            if (updateFlagsResourceOnFhir) {
                const flagCompare = JSON.stringify(patient.flags.code.coding);
                if (flagCompare !== flagBackup && !forceUpdateFlag) {
                    try {
                        await Fhir.Rest.Update(patient.flags);
                    } catch (e) {
                        // sometimes this fails, but we don't really mind
                        console.warn(e.message || e.response || e);
                    }
                }
            }
        }
        //#endregion

        if (forceUpdateFlag || JSON.stringify(patient.flags.code.coding) !== flagBackup) {
            // console.info('Updating Flag Resource ' + (forceUpdateFlag ? 'with missing Flags for RiskVDD/RiskFall' : ''), patient.flags);
            await Fhir.Rest.Update(patient.flags);
        }

        return textArr;
    }

    public static FixFlags(flags: any[]) {
        if (!flags) return;
        try {
            for (const flag of flags.filter(o => o.code && o.code.coding)) {
                let wrongFlags = flag.code.coding.filter(o => o.system.indexOf('/marks/marks/') > -1);
                for (const flagCoding of wrongFlags) {
                    flagCoding.system = flagCoding.system.replace('/marks/marks/', '/marks/');
                }

                wrongFlags = flag.code.coding.filter(o => o.system.indexOf('hospital.commarks/') > -1);
                for (const flagCoding of wrongFlags) {
                    flagCoding.system = flagCoding.system.replace('hospital.commarks/', 'hospital.com/marks/');
                }
            }

        } catch (e) {
            console.warn(e.message);
        }
    }

    public static async CreateMotherChildLink(motherItem: PatientItem, childItem: PatientItem) {
        // get native patients for the patientItems:
        const motherPatient: any = <any>await Fhir.Rest.Get("Patient/" + motherItem.id);
        const childPatient: any = <any>await Fhir.Rest.Get("Patient/" + childItem.id);

        if (!motherPatient.link) {
            motherPatient.link = [];
        }

        if (!childPatient.link) {
            childPatient.link = [];
        }

        // locate and create or update the link to the mother in the CHILD patient
        let linkToMotherOnChildPatient: any = childPatient.link.find(o => o.type === 'refer' && o.other && o.other.extension?.find(m => m.url === PatientItem.RelationSystem && m.valueString === 'mother'));
        if (!linkToMotherOnChildPatient) {
            linkToMotherOnChildPatient = {
                type: 'refer',
                other: {}
            }

            childPatient.link.push(linkToMotherOnChildPatient);
        }

        linkToMotherOnChildPatient.other = {
            reference: `Patient/${motherPatient.id}`,
            display: motherItem.display,
            extension: [
                {
                    url: PatientItem.RelationSystem,
                    valueString: 'mother'
                }
            ]
        }

        // locate and create or update the link to the CHILD in the MOTHER patient
        let linkToChildOnMotherPatient: any = motherPatient.link.find(o => o.type === 'refer' &&
            o.other && o.other.reference && o.other.reference.endsWith(childItem.id) &&
            o.other?.extension && o.other?.extension.find(m => m.url === PatientItem.RelationSystem && m.valueString === 'child'));

        if (!linkToChildOnMotherPatient) {
            linkToChildOnMotherPatient = {
                type: 'refer',
                other: {}
            }

            motherPatient.link.push(linkToChildOnMotherPatient);
        }

        linkToChildOnMotherPatient.other = {
            reference: `Patient/${childPatient.id}`,
            display: childItem.display,
            extension: [
                {
                    url: PatientItem.RelationSystem,
                    valueString: 'child'
                }
            ]
        }

        await Fhir.Rest.Bundle([motherPatient, childPatient], HTTPVerb.put);
    }

    public static async RemoveChild(motherItem: PatientItem, childPatientId: string) {
        const motherPatient = <any>await Fhir.Rest.Get(`Patient/${motherItem.id}`);
        const childPatient = <any>await Fhir.Rest.Get(`Patient/${childPatientId}`);
        const toUpdate = [];

        if (motherPatient && motherPatient.link) {
            const existing = motherPatient.link.find(o => o.type === 'refer' && o.other?.extension && o.other?.extension.find(m => m.url === PatientItem.RelationSystem && m.valueString === 'child')
                && o.other?.reference.endsWith(`/${childPatientId}`));

            if (existing) {
                motherPatient.link.splice(motherPatient.link.indexOf(existing), 1);
                toUpdate.push(motherPatient);

                const existingMother = PatientItem.Patients.find(o => o.id === motherPatient.id);
                if (existingMother) {
                    const childrenLinks = motherPatient.link.filter(o => o.type === 'refer' && o.other?.extension && o.other?.extension.find(m => m.url === PatientItem.RelationSystem && m.valueString === 'child') && o.other?.reference);
                    existingMother.childrenAssigned = childrenLinks.length > 0;
                    PatientItem.ReadRelations(existingMother);
                }
            }
        }

        if (childPatient && childPatient.link) {
            const existing = childPatient.link.find(o => o.type === 'refer' && o.other?.extension && o.other?.extension.find(m => m.url === PatientItem.RelationSystem && m.valueString === 'mother'));
            if (existing) {
                childPatient.link.splice(childPatient.link.indexOf(existing), 1);
                toUpdate.push(childPatient);
                const existingChild = PatientItem.Patients.find(o => o.id === childPatient.id);
                if (existingChild) {
                    existingChild.link = childPatient.link;
                    existingChild.motherAssigned = false;
                    PatientItem.ReadRelations(existingChild);
                }
            }
        }

        await Fhir.Rest.Bundle(toUpdate, HTTPVerb.put);
    }

    /**
     * Loads the flags for the specified encounters
     * @param encounterIds the encounterIds to load<br />Could be called via LoadFlags("abc") or LoadFlags("abc", "def", "ghi")
     */
    public static async LoadFlags(...encounterIds) {
        if (!encounterIds) return [];

        encounterIds = encounterIds.filter(o=>o && o !== "undefined");
        const encounterString = encounterIds.join(',');
        if (!encounterString || encounterString.length === 0) return [];

        const url = `Flag?encounter=${encounterString}&identifier=marks&_sort=-date`;
        const flagsResult = await Fhir.Rest.Fetch(url);
        return flagsResult.filter(o => o.status === 'active' && o.identifier);
    }

    public static async List(fhirService: FhirService, locationService: LocationService, wardId: string, force: boolean = false): Promise<PatientItem[]> {
        if (!force && this.__lastWardId === wardId && this.__patients && this.__patients.length > 0) {
            return this.__patients;
        }

        await this.waitForLoading();
        this.__patients = [];

        await locationService.fetch();
        const wardLocation = locationService.getLocationById(wardId);
        await ConfigService.LoadConfigOverride(wardLocation.name);
        await QuestionnaireService.Fetch();

        // encounterAdditionalFilters
        // let url = `Encounter?_include=Encounter:patient&location=${wardId}&_format=json&_revinclude=Flag:encounter&_include=Encounter:location&_include=Encounter:service-provider`;
        let url = `Encounter?_include=Encounter:patient&location=${wardId}&_format=json&_include=Encounter:location&_include=Encounter:service-provider`;
        if (FhirService.EncounterAdditionalFilters) {
            let s = FhirService.EncounterAdditionalFilters.trim();
            if (s.indexOf('&') === 0) s = s.substr(1);
            url += '&' + s;
        }

        const result = await fhirService.fetch(url, true);   // gets all Resources in one array
        const encounters: any[] = <any[]>result.filter(o => o.resourceType === 'Encounter'); // get Encounter-Array
        const patients: any[] = <any[]>result.filter(o => o.resourceType === 'Patient'); // get Patient-Array
        const flags: any[] = [];

        // load 25 flags at once
        const chunks = NitTools.Chunk(encounters.map(o => o.id), 25);
        for (const chunk of chunks) {
            const flagsResult = await this.LoadFlags(...chunk);
            flags.push(...flagsResult);
        }

        // const flags: any[] = <any[]>result.filter(o => o.resourceType === 'Flag' && o.meta && o.meta.lastUpdated); // get Flag-Array
        const locations: any[] = <any[]>result.filter(o => o.resourceType === 'Location'); // get Locations
        const providers: any[] = <any[]>result.filter(o => o.resourceType === 'Organization'); // store orgas
        if (providers) for (const prov of providers) OrganizationService.Add(prov);  // store the loaded encounters

        this.FixFlags(flags);

        // add all locations to locationService
        locations.forEach(l => LocationService.InsertLocation(l));

        // sort the flags by last update date descending. That way the last updated flag should be assigned to the encounter
        flags.sort((a: any, b: any) => new Date(b.meta?.lastUpdated).valueOf() - new Date(a.meta?.lastUpdated).valueOf());

        // remove finished locations from the encounter
        let items: PatientItem[] = [];

        for (const encounter of encounters.filter(o => o.subject && o.subject.reference)) {
            let patientId = encounter.subject.reference;
            if (patientId.indexOf('/') > -1) {
                patientId = patientId.split('/')[1];
            }

            let patient = patients.find(o => o.id === patientId);
            if (!patient && encounter.subject && encounter.subject.reference) {
                if (ConfigService.Debug) console.debug("Patient not found in Bundle, getting explicitely from " + encounter.subject.reference);
                try {
                    patient = <any>await fhirService.get(encounter.subject.reference);
                } catch (e) {
                    console.warn(`Error when getting "${encounter.subject.reference}" for encounter ${encounter.id}`);

                    continue;
                }
            }

            const flagArray = flags.filter(o => o.encounter && o.encounter.reference && o.encounter.reference.endsWith('/' + encounter.id) && o.status === 'active');
            const flag = this.SearchForMarksFlag(flagArray);

            let item = new PatientItem(patient);
            item.encounterId = encounter.id ?? '-';
            item.encounter = encounter;
            item.flags = flag;
            item.gender = patient ? patient.gender : 'unknown';

            await this.ReadAndCalculateMarksAndVisitNumber(item);

            const encounterWard = item.encounter.location.find(o => o.location && o.location.reference && o.location.reference.endsWith(wardId));
            if (encounterWard && encounterWard.status !== 'completed' && encounterWard.status !== 'reserved') {
                items.push(item);
            }

            PatientItem.AssignLocations(item);

            if (!item.familyName && item.name) {
                let name = item.name.find(o => o.family);
                if (name) {
                    item.familyName = name.family;
                }
            }

            item.getAssessmentName();
            item.getAnamnesisName();

            this.ReadCareLevelFromFlags(item);
        }

        const newFlags = items.filter(o => !o.flags);
        for (const patient of newFlags) {
            await this.EnsureFlags(patient);
        }

        // ensure that when a mother-child has been assigned the mother is displayed first and beneath that one the child.
        if (ConfigService.EncounterTypeChoice) {
            for (const patient of items) {
                PatientItem.ReadRelations(patient);

                if (!patient._sortIndex)
                    patient._sortIndex = patient.display || '?';

                if (patient.isMother && patient.childrenAssigned) {
                    patient._sortIndex = patient.display + 'aaaaaaaa';
                    // now search all the children and add the mother display prior to the display
                    for (const childPatientId of patient.childrenPatients) {
                        const childPatient = items.find(o => o.id === childPatientId);
                        if (childPatient) {
                            childPatient._sortIndex = patient._sortIndex + ' ' + childPatient.display;
                            // childPatient.display = "   " + childPatient.display;
                        }
                    }
                }
            }
        }

        this.__patients = items.sort((a, b) => a._sortIndex.localeCompare(b._sortIndex));
        this.__lastWardId = wardId;
        RuntimeInfo.CurrentWardId = wardId;

        return this.__patients;
    }

    public static async UpdateCareLevel(patient: PatientItem, newLevel: number, target?: any, careLevelText?: string, careLevelColor?: string, spiSum?: number) {
        if (!newLevel) {
            newLevel = 0;
        }

        if (!patient || typeof newLevel === "undefined" || patient.isOffline /* || patient.careLevel === newLevel */) return;

        patient.careLevel = newLevel;
        if (careLevelText) patient.careLevelText = careLevelText;
        if (careLevelColor) patient.careLevelColor = careLevelColor;

        if (typeof spiSum === 'number') {
            patient.SPI = spiSum;
            const existing = FhirService.Tags.value(patient.encounter, '/tags/SPI');
            if (!existing || existing !== String(spiSum)) {
                FhirService.Tags.update(patient.encounter, {
                    code: String(spiSum),
                    system: 'http://nursit-institute.com/structureDefinitions/tags/SPI'
                })
                    .catch(e => {
                        console.warn('Setting $META in encounter failed. Fix Database!. Original Exception was:', e.message || e);
                    })
            }
        }

        let careLevelTextDisplay = careLevelText || translations.translate(`carelevel${newLevel}`);

        if (careLevelTextDisplay.indexOf("undefined") > -1) careLevelTextDisplay = translations.translate("carelevel0");
        let a: IAnswer = {
            valueInteger: newLevel,
            text: careLevelTextDisplay
        };

        if (!patient.selectedAdditionalInfo && patient.questionnaireResponses) {
            patient.selectedAdditionalInfo = QuestionnaireService.GetLatestResponseOfType(patient, QuestionnaireService.__listResult.QAdditionalInfoId, [QuestionnaireResponseStatus.inProgress, QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        }

        if (!patient.latestAssessment) {
            const qa = QuestionnaireService.GetQuestionnaireByNameDirect(patient.getAssessmentName());
            patient.latestAssessment = QuestionnaireService.GetLatestResponseOfType(patient, qa.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        }

        patient.careLevelColor = careLevelColor || Tools.CareLevelToColor(newLevel);

        if (!target) target = patient.latestAssessment;

        if (target) {
            let q = QuestionnaireService.GetQuestionnaireDirect(target.questionnaire);
            Questionnaire.EnsureStructuredResponse(q, target);
            let assessmentCareLevelItem = QuestionnaireResponse.GetResponseItemByLinkId(target, "CareLevel", true);
            if (assessmentCareLevelItem) assessmentCareLevelItem.answer = [{valueInteger: a.valueInteger}]; // QuestionnaireResponse.SetResponseItemValue(assessmentCareLevelItem, newLevel);

            let assessmentCareLevelStringItem = QuestionnaireResponse.GetResponseItemByLinkId(target, "CareLevel_String", true);
            if (assessmentCareLevelStringItem) assessmentCareLevelStringItem.answer = [{valueString: a.text}]
        }

        if (patient.selectedAdditionalInfo) {
            let qa = QuestionnaireService.GetQuestionnaireDirect(patient.selectedAdditionalInfo.questionnaire);
            Questionnaire.EnsureStructuredResponse(qa, patient.selectedAdditionalInfo);
            if (!patient.selectedAdditionalInfo.item) patient.selectedAdditionalInfo.item = [];
            let infoCareLevelItem = QuestionnaireResponse.GetResponseItemByLinkId(patient.selectedAdditionalInfo, "CareLevel", true);
            let careLevelColorItem = QuestionnaireResponse.GetResponseItemByLinkId(patient.selectedAdditionalInfo, "CareLevel_Color", true);
            let careLevelStringItem = QuestionnaireResponse.GetResponseItemByLinkId(patient.selectedAdditionalInfo, "CareLevel_String", true);

            if (careLevelColorItem) careLevelColorItem.answer = [{valueString: patient.careLevelColor}];
            if (infoCareLevelItem) infoCareLevelItem.answer = [{valueInteger: patient.careLevel}];
            if (careLevelStringItem) careLevelStringItem.answer = [{valueString: a.text}];
        }

        if (patient.flags) {
            let flag = <any>patient.flags;
            if (flag && flag.code && flag.code.coding) {

                flag.code.coding = flag.code.coding.filter(o =>
                    !o.system.endsWith('/CareLevel') &&
                    !o.system.endsWith('/CareLevelColor') &&
                    !o.system.endsWith('/CareLevelString') &&
                    !o.system.endsWith('/SPI')
                );

                let flagCL = Tools.GetOrCreateFlag(patient.flags, NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase) + '/CareLevel', true);
                if (flagCL) {
                    flagCL.code = String(patient.careLevel);
                }

                let flagClC = Tools.GetOrCreateFlag(patient.flags, NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase) + '/CareLevelColor', true);
                if (flagClC) {
                    flagClC.code = careLevelColor || String(patient.careLevelColor);
                }

                let flagClT = Tools.GetOrCreateFlag(patient.flags, NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase) + '/CareLevelString', true);
                if (flagClT) {
                    flagClT.code = careLevelText || careLevelTextDisplay || patient.careLevelText;
                }

                if (typeof spiSum === 'number') {
                    let flagSPI = Tools.GetOrCreateFlag(patient.flags, NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase) + '/SPI', true);
                    if (flagSPI) {
                        flagSPI.code = String(spiSum);
                        flagSPI.display = careLevelText || careLevelTextDisplay || patient.careLevelText
                    }
                }
            }
        }

        // PatientChangeNotifier.Notify(patient, patient.flags);
    }

    public static ClearListResult() {
        this.__patients = [];
    }

    /***
     * search for the Flag containing the mark_[n][_red|_yellow] values
     * @param flags the array if flags to search for the marks-flag
     */
    private static SearchForMarksFlag(flags: any[]): any {
        let flag: any = undefined;

        // iterate through all flag resources..
        for (let f = 0; f < flags.length; f++) {
            const flg = flags[f];
            // .. and look for the one which contains an identifier ending with '/marks' - which should be our flag
            for (let i = 0; i < flg.identifier.length; i++) {
                const ident = flg.identifier[i];
                if (ident.system.endsWith('/marks')) {
                    flag = flg;
                    break;
                }
            }

            if (typeof flag !== "undefined") break;
        }

        // for compatibility, write the flags => mark_[x]
        if (flag && flag.code && flag.code.coding) {
            flag.code.coding
                .filter(o => o.system.indexOf('mark_') > -1)
                .forEach(coding => {
                    const sa = coding.system.split('/');
                    const markId = sa[sa.length - 1];
                    this[markId] = NitTools.ParseBool(coding.code);
                });
        }

        return flag;
    }

    /***
     * Read the locations that _may_ be contained in the encounter and add them to the LocationService
     */
    private static processContainedLocations(encounter: any): any {
        if (!encounter || !encounter.contained) return;
        let resultingWard: any;

        const createLocationId = (referenceOdId: string) => {
            if (referenceOdId.indexOf('#') === 0)
                referenceOdId = referenceOdId.substr(1);

            referenceOdId = referenceOdId.split('/_history')[0];

            if (referenceOdId.indexOf('/') > -1)
                referenceOdId = referenceOdId.split('/')[1];

            referenceOdId = referenceOdId.replace('urn:uuid:', '');

            if (referenceOdId.indexOf('_contained_') === -1)
                referenceOdId = `${encounter.id}_contained_${referenceOdId}`;

            return referenceOdId;
        }

        if (encounter.location && encounter.location.length > 0) {
            encounter.location.forEach(loc => {
                if (loc.location.reference) {
                    if (loc.location.reference.indexOf('#') === 0) {
                        /* let ref = "Location/" + (encounter.id + "_contained_" + loc.location.reference.substr(1));
                        ref = ref.replace('urn:uuid:', ''); */
                        loc.location.reference = 'Location/' + createLocationId(loc.location.reference);
                    }
                }
            })
        }

        if (encounter.contained) {
            for (let i = 0; i < encounter.contained.length; i++) {
                const contained = encounter.contained[i];
                if (contained.resourceType === fhirEnums.ResourceType.location) {
                    let loc: any = <any>contained;
                    if (loc && loc.id && loc.physicalType && loc.physicalType.coding && loc.physicalType.coding.length > 0) {
                        let oldid = loc.id;
                        loc.id = createLocationId(loc.id);
                        if (loc.status === "active") {
                            encounter.contained.filter((o: any) => o.partOf && o.partOf.reference && o.partOf.reference.endsWith(oldid))
                                .forEach((l: any) => {
                                    l.partOf.reference = "Location/" + loc.id;
                                });

                            LocationService.InsertLocation(loc);

                            if (loc.physicalType.coding[0] && loc.physicalType.coding[0].code === "wa") {
                                resultingWard = loc;
                            }
                        }
                    }
                }
            }
        }


        return resultingWard;
    }

    private static ReadCareLevelFromFlags(patient: PatientItem): boolean {
        if (patient && patient.flags) {
            const flgCL = Tools.GetOrCreateFlag(patient.flags, 'nursit-institute.com/CareLevel', false);
            const flgCLC = Tools.GetOrCreateFlag(patient.flags, 'nursit-institute.com/CareLevelColor', false);
            const flgCLS = Tools.GetOrCreateFlag(patient.flags, 'nursit-institute.com/CareLevelString', false);
            const flgSPI = Tools.GetOrCreateFlag(patient.flags, 'nursit-institute.com/SPI', false);

            if (flgCL && flgCLC && flgCLS && flgSPI) {
                patient.careLevel = parseInt(flgCL.code);
                patient.careLevelColor = flgCLC.code;
                patient.careLevelText = patient.careLevelString = flgCLS.code;
                patient.SPI = parseInt(flgSPI.code)

                return true;
            }
        }

        return false;
    }

    private static AssignLocations(patient: PatientItem) {
        let containedWard = this.processContainedLocations(patient.encounter);
        const encounter = patient.encounter;
        if (encounter && encounter.location) {
            for (const location of encounter.location.filter(o => o.status && o.status === "active")) {
                if (location.location && location.location.reference) {
                    const loc = LocationService.LocationById(location.location.reference);
                    if (loc) {
                        if (loc.status === "active" && loc.physicalType && loc.physicalType.coding && loc.physicalType.coding[0] && loc.physicalType.coding[0].code) {
                            switch (loc.physicalType.coding[0].code) {
                                case 'wa':
                                    patient.ward = loc.name;
                                    patient.wardId = loc.id;
                                    break;
                                case 'ro':
                                    patient.room = loc.name;
                                    patient.roomId = loc.name;
                                    break;
                                case 'bd':
                                    patient.bed = loc.name;
                                    patient.bedId = loc.id;
                                    break;
                            }
                        }

                        if (loc.extension) {
                            const colorExtenstion = loc.extension.find(o => o.url.endsWith('roomColor'));
                            if (colorExtenstion && colorExtenstion.valueString)
                                patient.roomColor = colorExtenstion.valueString;
                        }
                    } // if loc
                }
            }

            // clean room - remove ward name
            try {
                if (patient.room && patient.ward && patient.room.indexOf(patient.ward) === 0) {
                    patient.room = patient.room.substr(patient.ward.length).trim();
                }

                // clean bed - remove ward and room name
                if (patient.bed && patient.ward && patient.bed.indexOf(patient.ward) === 0) {
                    patient.bed = patient.bed.substr(patient.ward.length).trim();
                }

                if (patient.bed && patient.room && patient.bed.indexOf(patient.room) === 0) {
                    patient.bed = patient.bed.substr(patient.room.length).trim();
                }
            } catch (ex) {
                console.warn(ex);
            }

            patient.location = Tools.ShortenLocations(patient); // [patient.ward, patient.room, patient.bed].filter(o => typeof o !== "undefined").join(', ');
        }

        if (!patient.wardId && containedWard) {
            patient.wardId = containedWard.id;
            patient.ward = containedWard.name;
        }
    }

    public mark(index: number) {
        return this.marks.find(o => o.index === index);
    }

    checkOffline(): any {
        if (!this.encounter) return undefined;
        const info = Fhir.Tools.GetOfflineInfo(this.encounter);
        this._isOffline = info.offline;

        return info;
    }

    async getReportName(route: string, defaultName?: string) {
        return PatientItem.GetReportName(this, route, defaultName);
    }

    /**
     * Gets the name of the currently configured anamnesis questionnaire
     */
    getAnamnesisName(): string {
        this.anamnesisName = PatientItem.GetQuestionnaireName(this, 'anamnesis', 'CareITAnamnesis');

        // update the QuestionnaireService.__listResult.QAssessmentId with the current value:
        const q = QuestionnaireService.GetQuestionnaireByNameDirect(this.anamnesisName);
        if (q) {
            QuestionnaireService.__listResult.QAnamnesisId = q.id;
        }

        return this.anamnesisName;
    }

    /**
     * Gets the name of the currently configured assessment questionnaire
     */
    getAssessmentName(): string {
        this.assessmentName = PatientItem.GetQuestionnaireName(this, 'assessment', 'CareITAssessment');

        // update the QuestionnaireService.__listResult.QAssessmentId with the current value:
        const q = QuestionnaireService.GetQuestionnaireByNameDirect(this.assessmentName);
        if (q) {
            QuestionnaireService.__listResult.QAssessmentId = q.id;
        }

        return this.assessmentName;
    }

    getMarkClass(index: number): string {
        if (!this.marks) return '';
        const mark = this.mark(index);
        if (!mark) return '';

        if (index === 1 && ConfigService.UseIsolationForFlag1) {
            const formSettingIsolation = ConfigService.GetFormSettings('isolation'); // needed to create the correct "latest<Iso-Questionnaire-Name>Date"-System string
            let flagSystem1 = `latest${formSettingIsolation.questionnaireName}Date`;        // this is the resulting system string
            const flagDate1 = Fhir.Tools.GetOrCreateFlag(this.flags, flagSystem1, false);   // this is the found flag for the resulting system
            if (flagDate1 && flagDate1.code) {
                const mark1 = PatientItem.CalculateMark1(this, flagDate1.code);
                if (mark1) {
                    if (ConfigService.Debug)
                        console.debug(`${this.display}: isolation, system: "${flagSystem1}", datum: ${flagDate1.code} => ${mark1.cssClass}`);

                    return mark1.cssClass;
                }
            } else {    // when the flag does not exist, mark1/iso has been set to true, but no iso has ever been completed/amended
                if (mark.checked) {
                    return 'active-red';
                } else return '';
            }
        }

        ////////////////
        if (index === 5 && ConfigService.UseBarthelIndexForFlag5) {
            const m5 = PatientItem.ReadMark5(this);
            return m5 ? m5.cssClass : '';
        }

        if (index === 10 && ConfigService.UseAssessmentForSignal10) {
            const formSettingAssessment = ConfigService.GetFormSettings('assessment');
            let flagSystem = `latest${formSettingAssessment.questionnaireName}Date`;
            const flag10 = Fhir.Tools.GetOrCreateFlag(this.flags, flagSystem, false);
            if (mark && flag10) {
                const redAfter = formSettingAssessment.expiration.durations.redAfter || 23;
                const yellowAfter = formSettingAssessment.expiration.durations.yellowAfter || 12;

                const age = Math.abs(new moment(new Date(flag10.code)).diff(new Date(), 'hours'));
                mark.checked = true;
                mark.yellow = age > yellowAfter;
                mark.red = age > redAfter;

                return mark.cssClass;
            }
        } else {
            return mark.cssClass;
        }
    }

    public toString() {
        return 'name:' + this.display + ' gender:' + this.gender + ' born:' + this.birthDate + ' where:' + this.location;
    }

    public clearChildrenEncounter() {
        this._childrenPatients = [];
        this.childrenAssigned = false;
    }

    private _getMark(index: number): PatientMark {
        return this.marks.find(o => o.index === index);
    }

    private _getChecked(index: number): boolean {
        return this._getMark(index)?.checked || false;
    }

    private _getRed(index: number): boolean {
        return this._getMark(index)?.red || false;
    }

    private _getYellow(index: number): boolean {
        return this._getMark(index)?.yellow || false;
    }
}

export interface IReleaseInformation {
    redReasons?: string[];
}

export interface IRelatedPersonInfo {
    display?: string;
    relationCode? : string;
    relationDisplay? : string;
    relationSystem? : string;
    items?: any[];
    default? : any;
    phone? : string;
}